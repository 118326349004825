"use client";

import * as React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Mail,
  Phone,
  Star,
  MapPin,
  Sparkles,
  Globe,
  Target,
  ExternalLink,
  Clock,
  Calendar,
  HelpCircle,
  Building,
  ThumbsUp,
  ThumbsDown,
  User,
} from "lucide-react";
import { EmailPopup } from "./email-popup";
import { LeadGenContext } from "../../../contexts/LeadGenContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { RadioGroup, Radio } from "../../../components/catalyst/radio";
import { DialogFooter } from "../../../components/ui/dialog";
import { X } from "lucide-react";

// Import source icons
import thumbtackIcon from "./icons/thumbtack.png";
import gafIcon from "./icons/gaf.png";
import srsIcon from "./icons/srs.png";
import { AppContext } from "contexts/AppContext";

const certificationColors = {
  "President's Club Award": "bg-purple-100 text-purple-800 border-purple-200",
  "President's Award": "bg-purple-100 text-purple-800 border-purple-200",
  "Master Elite®": "bg-blue-100 text-blue-800 border-blue-200",
  "Master Elite": "bg-blue-100 text-blue-800 border-blue-200",
  "Certified Installer": "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  "Green Roofing Specialist":
    "bg-emerald-100 text-emerald-800 border-emerald-200",
  "Safety Certified": "bg-red-100 text-red-800 border-red-200",
  "Innovative Design Award": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Energy Efficiency Expert": "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Community Choice Award": "bg-pink-100 text-pink-800 border-pink-200",
  "Reliability Certified": "bg-cyan-100 text-cyan-800 border-cyan-200",
  "Sustainability Award": "bg-teal-100 text-teal-800 border-teal-200",
  "Emergency Response Certified":
    "bg-orange-100 text-orange-800 border-orange-200",
  "Customer Service Excellence": "bg-rose-100 text-rose-800 border-rose-200",
  "Historical Preservation Expert":
    "bg-amber-100 text-amber-800 border-amber-200",
  Certified: "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  "Craftsmanship Award": "bg-lime-100 text-lime-800 border-lime-200",
  "Solar Roofing Specialist": "bg-violet-100 text-violet-800 border-violet-200",
  "Energy Star Partner": "bg-sky-100 text-sky-800 border-sky-200",
  "Commercial Expert": "bg-blue-100 text-blue-800 border-blue-200",
};

const groupColors = {
  "Reroof - Local/Retail Restoration":
    "bg-orange-100 text-orange-800 border-orange-200",
  "General Contractor": "bg-sky-100 text-sky-800 border-sky-200",
  "Other Exteriors": "bg-teal-100 text-teal-800 border-teal-200",
  "New Construction - Builder": "bg-lime-100 text-lime-800 border-lime-200",
  "Latino Owned": "bg-amber-100 text-amber-800 border-amber-200",
  "Low Slope - Tier 2": "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Reroof - Storm/Insurance Restoration":
    "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Multifamily - Other": "bg-violet-100 text-violet-800 border-violet-200",
  "Top Cash Rewards": "bg-emerald-100 text-emerald-800 border-emerald-200",
  National: "bg-blue-100 text-blue-800 border-blue-200",
  "New Construction - Turnkey": "bg-green-100 text-green-800 border-green-200",
  "Low Slope - Tier 1": "bg-cyan-100 text-cyan-800 border-cyan-200",
  "Two-Step": "bg-rose-100 text-rose-800 border-rose-200",
  "Multifamily - Steep Slope":
    "bg-purple-100 text-purple-800 border-purple-200",
  "PRVT EQUITY": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Other Interiors": "bg-pink-100 text-pink-800 border-pink-200",
  "Latino Rewards": "bg-red-100 text-red-800 border-red-200",
  "ECA-NA": "bg-amber-100 text-amber-800 border-amber-200",
  "LMTLS-NA": "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  FRANCHISE: "bg-blue-100 text-blue-800 border-blue-200",
};

const statusColors = {
  // Assigned Leads - Purple
  Prospective: "bg-purple-50 text-purple-700 border-purple-300",
  Churned: "bg-purple-50 text-purple-700 border-purple-300",

  // Actioned Leads - Orange/Red
  "Acknowledging Lead": "bg-orange-50 text-orange-700 border-orange-300",
  "First Attempt": "bg-orange-50 text-orange-700 border-orange-300",
  "Second Attempt": "bg-orange-50 text-orange-700 border-orange-300",
  "Third Attempt": "bg-orange-50 text-orange-700 border-orange-300",

  // Qualified Leads - Blue/Cyan
  "Engaged Prospect": "bg-blue-50 text-blue-700 border-blue-300",
  "Needs Assessment": "bg-blue-50 text-blue-700 border-blue-300",
  "Proposal or Quote": "bg-blue-50 text-blue-700 border-blue-300",
  "Advancing the Sale": "bg-blue-50 text-blue-700 border-blue-300",

  // Converted Leads - Green
  "Credit Application": "bg-green-50 text-green-700 border-green-300",
  Converted: "bg-green-50 text-green-700 border-green-300",
  "Reactivated Customer": "bg-green-50 text-green-700 border-green-300",

  // Other - Gray
  "Not a Good Lead": "bg-gray-50 text-gray-700 border-gray-400",
  "Already a Customer": "bg-gray-50 text-gray-700 border-gray-400",

  // Lost Lead - Red
  "Lost Lead": "bg-red-50 text-red-700 border-red-300",
};

const getStatusGroupColor = (status) => {
  if (
    [
      "Acknowledging Lead",
      "First Attempt",
      "Second Attempt",
      "Third Attempt",
    ].includes(status)
  ) {
    return "text-orange-600";
  } else if (
    [
      "Engaged Prospect",
      "Needs Assessment",
      "Proposal or Quote",
      "Advancing the Sale",
    ].includes(status)
  ) {
    return "text-blue-600";
  } else if (
    ["Credit Application", "Converted", "Reactivated Customer"].includes(status)
  ) {
    return "text-green-600";
  } else if (["Lost Lead"].includes(status)) {
    return "text-red-600";
  } else if (["Not a Good Lead", "Already a Customer"].includes(status)) {
    return "text-gray-600";
  } else {
    return "text-gray-600";
  }
};

const statusDescriptions = {
  "Acknowledging Lead": "Following up on the assigned lead.",
  "First Attempt": "Initial contact attempt made, but no response.",
  "Second Attempt": "Second contact attempt made, but no response.",
  "Third Attempt": "Third contact attempt made, but no response.",
  "Engaged Prospect": "Successful contact made, and a meeting is scheduled.",
  "Needs Assessment": "Customer's needs, challenges, and goals identified.",
  "Proposal or Quote": "Pricing, quote, or job bid provided to the prospect.",
  "Advancing the Sale": "Finalizing customer-specific requirements.",
  "Credit Application": "Customer has submitted a Bectran credit application.",
  Converted: "Account established, and prospect is now a customer.",
  "Reactivated Customer": "Former customer has been reactivated.",
  "Not a Good Lead": "Did not engage due to preliminary research.",
  "Already a Customer": "Already a customer with SRS.",
  "Lost Lead":
    "Unresponsive, not interested, out of business, or is already with a competitor.",
};

const statusNoteRequirements = {
  "Acknowledging Lead": "What research has been done to prepare for contact?",
  "First Attempt": "Date, time, and method of contact.",
  "Second Attempt": "Date, time, and method of contact.",
  "Third Attempt": "Date, time, and method of contact.",
  "Engaged Prospect": "Contact details, date, and time of the meeting.",
  "Needs Assessment":
    "Meeting date/time, key customer concerns, and proposed solutions.",
  "Proposal or Quote":
    "Follow-up actions taken after presenting pricing or quotes.",
  "Advancing the Sale": "What are the specific needs to move the sale forward?",
  "Credit Application": "Date and time the application was sent.",
  Converted: "Customer account details and initial order information.",
  "Reactivated Customer": "Notes on how the customer was reactivated.",
  "Not a Good Lead": "Explain preliminary research findings.",
  "Already a Customer": "Existing account details and current status.",
  "Lost Lead":
    "Specific reason for loss (competitor, pricing, unresponsive etc.).",
};

// SourceIcon component that uses image assets
const SourceIcon = ({ source }) => {
  // Map sources to their respective image assets
  let iconSrc = null;
  let tooltipText = source || "Unknown Source";

  switch (source) {
    case "Thumbtack":
      iconSrc = thumbtackIcon;
      tooltipText = "Thumbtack";
      break;
    case "GAF":
      iconSrc = gafIcon;
      tooltipText = "GAF";
      break;
    case "Churned Customer":
    case "SRS-CLIENT":
      iconSrc = srsIcon;
      tooltipText = "SRS Distribution";
      break;
    default:
      // Return default icon for unknown sources
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center shadow-sm border border-gray-100">
                <Building className="w-5 h-5 text-gray-500" />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p className="text-xs">{tooltipText}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
  }

  // For sources with image assets
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center shadow-sm border border-gray-100 overflow-hidden">
            <img
              src={iconSrc}
              alt={`${tooltipText} icon`}
              className="w-6 h-6 object-contain"
            />
          </div>
        </TooltipTrigger>
        <TooltipContent side="top">
          <p className="text-xs">{tooltipText}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

// Helper function to safely parse numeric values
const safeParseFloat = (value, defaultValue = 0) => {
  if (value === null || value === undefined || value === "")
    return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

// Updated function to check if website/link is valid
const isValidLink = (link) => {
  // Return false for empty objects, empty strings, or "No website"
  if (
    !link ||
    link === "No website" ||
    link === "" ||
    link === "{}" ||
    link === "{}"
  ) {
    return false;
  }

  // Additional check for object notation (sometimes returned as string)
  if (
    typeof link === "string" &&
    (link.trim() === "{}" || link.includes('"{}"'))
  ) {
    return false;
  }

  return true;
};

export function ContractorModal({
  isOpen,
  onClose,
  contractorId,
  contractors,
}) {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const { setLeadMapping } = useContext(LeadGenContext);
  const { isMobile } = useContext(AppContext);
  const [notes, setNotes] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [localRating, setLocalRating] = useState(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDismissPopoverOpen, setIsDismissPopoverOpen] = useState(false);
  const [dismissReason, setDismissReason] = useState("");
  const [dismissNotes, setDismissNotes] = useState("");

  // Find the contractor using numeric ID
  const contractor = contractors.find((c) => c.id === contractorId);

  // Set initial values when contractor changes
  useEffect(() => {
    if (contractor) {
      setNotes(""); // Reset notes input when contractor changes
      setSelectedStatus("");

      // Convert string feedback values to boolean for the UI
      if (contractor.feedback === "true") {
        setLocalRating(true);
      } else if (contractor.feedback === "false") {
        setLocalRating(false);
      } else {
        setLocalRating(null);
      }

      setFeedbackText(contractor.feedback_notes || "");
    }
  }, [contractor]);

  if (!contractor) return null;

  // Check if this is a churned customer
  const isChurnedCustomer =
    contractor.source === "Churned Customer" ||
    contractor.source === "SRS-CLIENT";
  // Customer ID for churned customers
  const customerId = contractor.custcd || contractor.row_uuid;

  const handleAddNote = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const timestamp = new Date().toISOString();
    const newNote = notes.trim();
    if (!newNote) return;

    try {
      setIsSubmitting(true);

      if (contractor.row_uuid) {
        // Prepare notes
        const existingNotes = parseNotes(contractor.notes);
        const updatedNotes = [
          { text: newNote, timestamp, status: selectedStatus },
          ...existingNotes,
        ];
        const notesString = JSON.stringify(updatedNotes);

        // Convert boolean to string or use 'null' as a string if no value is provided
        const feedbackValue =
          localRating === true
            ? "true"
            : localRating === false
            ? "false"
            : "null"; // Use 'null' as a string

        // Call setLeadMapping with string values for feedback
        await setLeadMapping(
          contractor.row_uuid,
          selectedStatus,
          notesString,
          feedbackValue,
          feedbackText?.trim() || ""
        );

        setNotes("");
      } else {
        console.warn("Missing row_uuid");
      }
    } catch (error) {
      console.error("Failed to update lead:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

  // Safe parsing function that handles both old string format and new JSON format
  const parseNotes = (notes) => {
    if (!notes) return [];

    try {
      // Try to parse as JSON first
      const parsed = JSON.parse(notes);
      // Check if it's an array
      if (Array.isArray(parsed)) {
        return parsed;
      }
      // If it's not an array but parsing succeeded, it might be the old format
      return [
        {
          text: notes,
          timestamp: new Date().toISOString(),
          status: contractor.status || "Prospective",
        },
      ];
    } catch (e) {
      // If parsing fails, treat it as an old format string
      return [
        {
          text: notes,
          timestamp: new Date().toISOString(),
          status: contractor.status || "Prospective",
        },
      ];
    }
  };

  // Handler for rating the lead with thumbs up/down
  const handleRating = (rating) => {
    // Just set the local state, don't send to backend yet
    setLocalRating(rating);
    setIsFeedbackOpen(true);
  };

  // Handler for submitting feedback text
  const handleTextFeedback = async () => {
    try {
      setIsSubmitting(true);

      if (contractor.row_uuid) {
        const notesValue = contractor.notes || "";

        // Convert boolean to string
        const feedbackValue =
          localRating === true ? "true" : localRating === false ? "false" : "";

        // Submit using string values for feedback
        await setLeadMapping(
          contractor.row_uuid,
          selectedStatus,
          notesValue,
          feedbackValue,
          feedbackText.trim() || ""
        );

        setIsFeedbackOpen(false);
      } else {
        console.warn("Cannot submit feedback - missing lead ID");
      }
    } catch (error) {
      console.error("Failed to update feedback:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const noteHistory = parseNotes(contractor.notes);

  // Define base statuses that are always available
  const baseStatuses = [
    // "Not a Good Lead",
    // "Already a Customer",
    "Acknowledging Lead",
    "First Attempt",
    "Second Attempt",
    "Third Attempt",
    "Engaged Prospect",
    "Needs Assessment",
    "Proposal or Quote",
    "Advancing the Sale",
  ];

  // Create the status list based on the lead type
  let statuses = [...baseStatuses];

  // For churned customers, add the option to reactivate
  if (isChurnedCustomer) {
    statuses.push("Reactivated Customer");
  } else {
    // For non-churned customers, add the option to convert
    statuses.push("Credit Application", "Converted");
  }

  // Add Lost Lead at the end (after the converted statuses)
  // statuses.push("Lost Lead");

  // If the current status is "Churned" but we're not showing it in the dropdown,
  // still ensure the UI reflects the current status
  const displayStatus =
    selectedStatus === "Churned" ? "Prospective" : selectedStatus;

  // Use certifications directly or default to empty array
  const certificationList = contractor.certifications || [];

  const dismissReasons = ["Not a Good Lead", "Already a Customer", "Lost Lead"];

  // Add a new handler for dismissing leads
  const handleDismiss = async () => {
    // Validation
    if (!dismissReason) {
      // Could add error handling/toast here
      return;
    }
    
    if (!dismissNotes.trim()) {
      // Could add error handling/toast here
      return;
    }
  
    try {
      setIsSubmitting(true);
  
      if (contractor.row_uuid) {
        // Create a note with the selected dismiss reason and notes
        const timestamp = new Date().toISOString();
        const existingNotes = parseNotes(contractor.notes);
        const updatedNotes = [
          {
            text: dismissNotes.trim(),
            timestamp,
            status: dismissReason,
          },
          ...existingNotes,
        ];
        const notesString = JSON.stringify(updatedNotes);
  
        // Convert boolean to string or use 'null' as a string if no value is provided
        const feedbackValue =
          localRating === true
            ? "true"
            : localRating === false
            ? "false"
            : "null";
  
        // Call setLeadMapping with the dismiss reason as status
        await setLeadMapping(
          contractor.row_uuid,
          dismissReason,
          notesString,
          feedbackValue,
          feedbackText?.trim() || ""
        );
  
        setIsDismissPopoverOpen(false);
        onClose(); // Close the modal after successful dismissal
      } else {
        console.warn("Missing row_uuid");
      }
    } catch (error) {
      console.error("Failed to dismiss lead:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className={isMobile ? "flex flex-col space-y-6" : "grid grid-cols-3 gap-6"}>
          {/* Left Column - Contractor Details */}
          <div className={isMobile ? "space-y-6" : "col-span-2 space-y-6"}>
            {/* Header */}
            <div>
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h2 className="text-xl font-bold text-gray-800">
                    {contractor.name}
                  </h2>
                </div>
                <SourceIcon source={contractor.source} />
              </div>

              {/* Certifications */}
              {certificationList.length > 0 ? (
                <div className="flex flex-wrap gap-2 mb-4">
                  {certificationList.map((cert) => (
                    <Badge
                      key={cert}
                      variant="secondary"
                      className={`text-xs px-2 py-0.5 ${
                        certificationColors[cert] ||
                        groupColors[cert] ||
                        "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {cert}
                    </Badge>
                  ))}
                </div>
              ) : (
                <div className="mb-4">
                  <span className="text-xs text-gray-400">
                    {isChurnedCustomer
                      ? "No groups listed"
                      : "No certifications listed"}
                  </span>
                </div>
              )}

              {/* Contact Info */}
              <div className="space-y-3">
                <div className="text-sm text-gray-600 flex items-center">
                  <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
                  <span>{contractor.address || "-"}</span>
                </div>
                <div className="text-sm text-gray-600 flex items-center">
                  <Phone className="w-4 h-4 mr-2 flex-shrink-0" />
                  <span>{contractor.phone || "-"}</span>
                </div>
                <div className="text-sm text-gray-600 flex items-center">
                  <Mail className="w-4 h-4 mr-2 flex-shrink-0" />
                  <span>{contractor.email || "-"}</span>
                </div>

                {/* Display different fields based on customer type */}
                {isChurnedCustomer ? (
                  <>
                    <div className="text-sm text-gray-600 flex items-center">
                      <User className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span>
                        Customer ID:{" "}
                        <span className="font-medium">{customerId}</span>
                      </span>
                    </div>
                    <div className="text-sm text-gray-600 flex items-center">
                      <Globe className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span
                        className={`${
                          isValidLink(contractor.website)
                            ? "text-blue-600 hover:underline cursor-pointer"
                            : "text-gray-400 cursor-default"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isValidLink(contractor.website)) {
                            window.open(
                              contractor.website,
                              "_blank",
                              "noopener noreferrer"
                            );
                          }
                        }}
                      >
                        Visit Website
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-sm text-gray-600 flex items-center">
                      <Globe className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span
                        className={`${
                          isValidLink(contractor.website)
                            ? "text-blue-600 hover:underline cursor-pointer"
                            : "text-gray-400 cursor-default"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isValidLink(contractor.website)) {
                            window.open(
                              contractor.website,
                              "_blank",
                              "noopener noreferrer"
                            );
                          }
                        }}
                      >
                        Visit Website
                      </span>
                    </div>
                    <div className="text-sm text-gray-600 flex items-center">
                      <ExternalLink className="w-4 h-4 mr-2 flex-shrink-0" />
                      <span
                        className={`${
                          isValidLink(contractor.post_link)
                            ? "text-blue-600 hover:underline cursor-pointer"
                            : "text-gray-400 cursor-default"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isValidLink(contractor.post_link)) {
                            window.open(
                              contractor.post_link,
                              "_blank",
                              "noopener noreferrer"
                            );
                          }
                        }}
                      >
                        Visit Listing
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* AI Insight */}
            <div className="bg-white p-3 rounded-md border border-gray-200 shadow-sm">
              <div className="flex items-center mb-2">
                <Sparkles className="w-5 h-5 mr-2 text-blue-600" />
                <h4 className="text-lg font-semibold text-gray-800">
                  AI Insight
                </h4>
              </div>
              <p className="text-sm leading-relaxed text-gray-700 font-medium whitespace-pre-wrap">
                {contractor.analysis?.trim() || "No AI insights available"}
              </p>
            </div>

            {/* Notes History */}
            <div className="bg-white p-3 rounded-md border border-gray-200 shadow-sm">
              <div className="flex items-center mb-4">
                <Clock className="w-5 h-5 mr-2 text-blue-600" />
                <h4 className="text-lg font-semibold text-gray-800">
                  Notes History
                </h4>
              </div>
              <div className="space-y-4">
                {noteHistory.length > 0 ? (
                  noteHistory.map((note, index) => (
                    <div
                      key={index}
                      className="border-b border-gray-100 last:border-0 pb-3 last:pb-0"
                    >
                      <div className="flex justify-between items-start mb-1">
                        <span className="text-xs text-gray-500">
                          {formatDate(note.timestamp)}
                        </span>
                        <Badge
                          variant="secondary"
                          className={`text-xs ${
                            statusColors[note.status] || ""
                          }`}
                        >
                          {note.status}
                        </Badge>
                      </div>
                      <p className="text-sm text-gray-700 whitespace-pre-wrap">
                        {note.text}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-sm text-gray-500 italic">No notes yet</p>
                )}
              </div>
            </div>
          </div>

          {/* Right Column - Contact Actions & Status Management */}
          <div
            className={
              isMobile
                ? "pt-8 border-t mt-8 space-y-6"
                : "border-l pl-6 space-y-6"
            }
          >
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact Actions</h3>
              <div>
                {contractor.phone ? (
                  <Button
                    size="lg"
                    className="w-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center"
                    onClick={() =>
                      (window.location.href = `tel:${contractor.phone}`)
                    }
                  >
                    <Phone className="w-4 h-4 mr-2" />
                    Call Now
                  </Button>
                ) : (
                  <div className="text-center p-3 border border-gray-200 rounded-md bg-gray-50">
                    <p className="text-sm text-gray-600">
                      To contact this lead, please visit the{" "}
                      <span
                        className={`${
                          isValidLink(contractor.post_link)
                            ? "text-blue-600 hover:underline cursor-pointer"
                            : "text-gray-400 cursor-default"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isValidLink(contractor.post_link)) {
                            window.open(
                              contractor.post_link,
                              "_blank",
                              "noopener noreferrer"
                            );
                          }
                        }}
                      >
                        post link
                      </span>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Lead Rating Section in its own div */}
            <div className="border-t border-b py-4">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">
                  Was this a helpful lead?
                </span>
                <div className="flex gap-2">
                  <Popover
                    open={isFeedbackOpen}
                    onOpenChange={setIsFeedbackOpen}
                  >
                    <PopoverTrigger asChild>
                      <div className="flex gap-2">
                        <button
                          aria-label="Thumb Up"
                          className="text-gray-500 hover:text-gray-700 active:text-gray-700 focus:outline-none"
                          onClick={() => handleRating(true)}
                        >
                          <ThumbsUp
                            className="w-5 h-5 mr-1 transition-colors duration-200"
                            fill={
                              localRating === true ? "currentColor" : "none"
                            }
                          />
                        </button>

                        <button
                          aria-label="Thumb Down"
                          className="text-gray-500 hover:text-gray-700 active:text-gray-700 focus:outline-none"
                          onClick={() => handleRating(false)}
                        >
                          <ThumbsDown
                            className="w-5 h-5 mr-1 transition-colors duration-200"
                            fill={
                              localRating === false ? "currentColor" : "none"
                            }
                          />
                        </button>
                      </div>
                    </PopoverTrigger>
                    <PopoverContent className="w-80">
                      <div className="space-y-4">
                        <div>
                          <Label className="text-sm font-medium">
                            Additional feedback?
                          </Label>
                          <Textarea
                            placeholder="Your feedback helps us improve lead quality."
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                            className="mt-2"
                          />
                        </div>
                        <div className="flex justify-end gap-2">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              setIsFeedbackOpen(false);
                            }}
                          >
                            Close
                          </Button>
                          {feedbackText.trim() && (
                            <Button
                              size="sm"
                              onClick={handleTextFeedback}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Lead Status and Notes</h3>

              <div className="space-y-4">
                <Select
                  value={selectedStatus}
                  onValueChange={setSelectedStatus}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    {statuses.map((status) => (
                      <SelectItem key={status} value={status}>
                        <div className="flex items-center justify-between w-full">
                          <span className={getStatusGroupColor(status)}>
                            {status}
                          </span>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                {/* Show status description and required notes */}
                <div className="text-sm bg-gray-50 p-3 rounded-md space-y-2">
                  <div>
                    <p className="font-semibold text-gray-700">
                      Status Description:
                    </p>
                    <p className="text-gray-600">
                      {statusDescriptions[selectedStatus] || "–"}
                    </p>
                  </div>
                  <div>
                    <p className="font-semibold text-gray-700">
                      Required Notes:
                    </p>
                    <p className="text-gray-600">
                      {statusNoteRequirements[selectedStatus] || "–"}
                    </p>
                  </div>
                </div>

                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder={`Add notes here...`}
                  className="w-full min-h-[100px] p-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                <div className="grid grid-cols-2 gap-2 w-full">
                  <Popover
                    open={isDismissPopoverOpen}
                    onOpenChange={setIsDismissPopoverOpen}
                  >
                    <PopoverTrigger asChild>
                      <Button variant="outline" className="text-rose-500">
                        Dismiss
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-80">
                      <div className="space-y-4">
                        <div>
                          <Label className="text-sm font-medium">
                            Why are you dismissing this lead?{" "}
                          </Label>
                          <RadioGroup
                            value={dismissReason}
                            onValueChange={setDismissReason}
                            className="mt-3 space-y-2"
                          >
                            {dismissReasons.map((reason) => (
                              <div
                                key={reason}
                                className="flex items-center space-x-2"
                              >
                                <Radio
                                  value={reason}
                                  id={reason}
                                  onClick={() => setDismissReason(reason)}
                                />
                                <Label
                                  htmlFor={reason}
                                  className="font-normal cursor-pointer"
                                  onClick={() => setDismissReason(reason)}
                                >
                                  {reason}
                                </Label>
                              </div>
                            ))}
                          </RadioGroup>

                          {dismissReason && (
                            <div className="mt-2 text-xs text-gray-500">
                              {statusDescriptions[dismissReason]}
                            </div>
                          )}
                        </div>

                        <div>
                          <Label className="text-sm font-medium">
                            Additional notes{" "}
                          </Label>
                          <Textarea
                            placeholder="Please provide specific reason for dismissal..."
                            value={dismissNotes}
                            onChange={(e) => setDismissNotes(e.target.value)}
                            className="mt-2"
                          />
                        </div>

                        <div className="bg-amber-50 border border-amber-200 rounded p-2 text-xs text-amber-800">
                          <strong>Note:</strong> Dismissing this lead will
                          remove it from your account.
                        </div>

                        <div className="flex justify-end gap-2">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              setIsDismissPopoverOpen(false);
                              setDismissReason("");
                              setDismissNotes("");
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            onClick={handleDismiss}
                            disabled={
                              !dismissReason ||
                              !dismissNotes.trim() ||
                              isSubmitting
                            }
                            className="bg-rose-600 hover:bg-rose-700 text-white"
                          >
                            {isSubmitting ? "Dismissing..." : "Dismiss Lead"}
                          </Button>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                  <Button
                    className="flex-1 bg-purple-700 hover:bg-purple-800 text-white"
                    onClick={handleAddNote}
                    disabled={!notes.trim() || !selectedStatus || isSubmitting}
                  >
                    {isSubmitting ? "Updating..." : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <EmailPopup
        isOpen={isEmailPopupOpen}
        onClose={() => setIsEmailPopupOpen(false)}
        contractorName={contractor.name || ""}
        contractorEmail={
          contractor.email ||
          `${contractor.name?.replace(/\s+/g, "")}@example.com`
        }
      />
    </Dialog>
  );
}
