import React, { useState, useContext } from "react";
import { Check, CheckCircle2, X, XCircle } from "lucide-react";
import { DataContext } from 'contexts/DataContext';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { toast } from 'sonner';

export function ActionItems({ item }) {
  const { completeInsight, logInsightFeedback, rejectInsight } = useContext(DataContext);
  const [feedback, setFeedback] = useState("");
  const [isXPopoverOpen, setIsXPopoverOpen] = useState(false);
  const [isCheckPopoverOpen, setIsCheckPopoverOpen] = useState(false);

  const handleDismiss = (e) => {
    e.stopPropagation();
    rejectInsight(item.task_id);
    if (feedback.trim()) {
      logInsightFeedback(item.task_id, false, feedback);
    }
    setIsXPopoverOpen(false);
    setFeedback("");
    setFeedback("");
    toast("Insight has been dismissed", {
      action: {
        label: "Close",
        onClick: () => console.log("Close"),
      },
    });
  };

  const handleComplete = (e) => {
    e.stopPropagation();
    completeInsight(item.task_id);
    if (feedback.trim()) {
      logInsightFeedback(item.task_id, true, feedback);
    }
    setIsCheckPopoverOpen(false);
    setFeedback("");
    toast("Insight has been marked as completed", {
      action: {
        label: "Close",
        onClick: () => console.log("Close"),
      },
    });
  };

  if (item.status === "accepted") {
    return (
      <div className="flex-1 flex items-center justify-center mt-4">
        <div className="flex items-center justify-center space-x-4">
          <div className="h-12 w-12 rounded-full bg-green-50 flex items-center justify-center flex-shrink-0">
            <Check className="h-8 w-8 text-green-600" />
          </div>
          <span className="text-xl font-medium text-gray-900">
            Completed
          </span>
        </div>
      </div>
    );
  }

  if (item.status === "rejected") {
    return (
      <div className="flex-1 flex items-center justify-center mt-4">
        <div className="flex items-center justify-center space-x-4">
          <div className="h-12 w-12 rounded-full bg-rose-50 flex items-center justify-center flex-shrink-0">
            <X className="h-8 w-8 text-rose-600" />
          </div>
          <span className="text-xl font-medium text-gray-900">
            Dismissed
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center gap-3 mt-4">
      <Popover open={isXPopoverOpen} onOpenChange={setIsXPopoverOpen}>
        <PopoverTrigger asChild>
          <Button 
            variant="outline"
            size="sm"
            className="text-rose-500"
            onClick={(e) => {
              e.stopPropagation();
              setIsXPopoverOpen(true);
            }}
          >
            <XCircle className="h-4 w-4 mr-2" />
            Dismiss Insight
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80" onClick={(e) => e.stopPropagation()}>
          <div className="space-y-4">
            <div>
              <Label className="text-sm font-medium">Why isn't this insight useful?</Label>
              <Textarea
                placeholder="Your feedback helps us improve."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="mt-2"
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button 
                size="sm"
                variant="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsXPopoverOpen(false);
                  setFeedback("");
                }}
              >
                Cancel
              </Button>
              <Button 
                size="sm"
                onClick={handleDismiss}
              >
                Dismiss
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>

      <Popover open={isCheckPopoverOpen} onOpenChange={setIsCheckPopoverOpen}>
        <PopoverTrigger asChild>
          <Button 
            type="submit"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              setIsCheckPopoverOpen(true);
            }}
          >
            <CheckCircle2 className="h-4 w-4 mr-2" />
            Mark Completed
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80" onClick={(e) => e.stopPropagation()}>
          <div className="space-y-4">
            <div>
              <Label className="text-sm font-medium">How did it go?</Label>
              <Textarea
                placeholder="Your feedback helps us improve."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="mt-2"
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button 
                size="sm"
                variant="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCheckPopoverOpen(false);
                  setFeedback("");
                }}
              >
                Cancel
              </Button>
              <Button 
                size="sm"
                onClick={handleComplete}
              >
                Complete
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}