"use client"

import { useState, useContext, useEffect } from 'react'
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Card, CardContent } from "components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Badge } from "components/ui/badge"
import { ArrowUpCircle, MessageSquare, MoreHorizontal, Bookmark, ChevronLeft, ChevronRight, Search } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { Link } from 'react-router-dom'
import { WaterCoolerContext } from 'contexts/WaterCoolerContext'

const POSTS_PER_PAGE = 5

// Helper to format date
const formatDate = (isoDate) => {
  if (!isoDate) return '';
  
  const date = new Date(isoDate);
  const now = new Date();
  const diffMs = now - date;
  const diffSecs = Math.floor(diffMs / 1000);
  const diffMins = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);
  
  if (diffDays > 0) {
    return `${diffDays}d ago`;
  } else if (diffHours > 0) {
    return `${diffHours}h ago`;
  } else if (diffMins > 0) {
    return `${diffMins}m ago`;
  } else {
    return 'Just now';
  }
};

export default function SavedPostsPage() {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  
  const { userData, fetchUserData, toggleBookmark, loading, error } = useContext(WaterCoolerContext);

  // // Fetch user data when component mounts if not already loaded
  // useEffect(() => {
  //   if (!userData) {
  //     fetchUserData();
  //   }
  // }, [userData, fetchUserData]);

  // Handle unbookmarking a post
  const handleUnsavePost = async (dataId) => {
    try {
      await toggleBookmark(dataId);
      // No need to refresh data, the toggleBookmark function already calls fetchUserData
    } catch (err) {
      console.error("Error removing bookmark:", err);
      alert("Failed to remove bookmark. Please try again.");
    }
  };

  // If data is still loading, show loading state
  if (loading || !userData) {
    return (
      <div className="container max-w-2xl mx-auto px-4 py-8 text-center">
        <p>Loading saved posts...</p>
      </div>
    );
  }

  // If there was an error fetching data
  if (error) {
    return (
      <div className="container max-w-2xl mx-auto px-4 py-8 text-center">
        <p className="text-red-500">{error}</p>
        <Button 
          onClick={fetchUserData} 
          className="mt-4"
        >
          Try Again
        </Button>
      </div>
    );
  }

  // Get bookmarks from userData
  const bookmarks = userData.bookmarks || [];

  // Filter bookmarks based on search term
  const filteredBookmarks = bookmarks.filter(bookmark =>
    bookmark.content.toLowerCase().includes(searchTerm.toLowerCase()) || 
    (bookmark.title && bookmark.title.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredBookmarks.length / POSTS_PER_PAGE);
  const paginatedBookmarks = filteredBookmarks.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  return (
    <div className="container max-w-2xl mx-auto px-4 py-8">
      <Link to="/water-cooler" className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6">
        <ChevronLeft className="mr-2 h-4 w-4" />
        Back to Water Cooler
      </Link>

      <h1 className="text-2xl font-bold mb-6">Saved Posts</h1>

      <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
          <Input
            placeholder="Search saved posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-8"
          />
        </div>
      </div>

      {filteredBookmarks.length === 0 ? (
        <Card>
          <CardContent className="p-4 text-center">
            <p className="text-gray-500">No saved posts found.</p>
            {searchTerm && (
              <p className="text-gray-500 mt-2">Try a different search term or clear the search.</p>
            )}
          </CardContent>
        </Card>
      ) : (
        <div className="space-y-4">
          {paginatedBookmarks.map((bookmark) => (
            <Card key={bookmark.data_id}>
              <CardContent className="p-4">
                <p className="text-sm mb-2">{bookmark.title ? bookmark.title : bookmark.content}</p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <span>{formatDate(bookmark.saved_date)}</span>
                  <div className="flex items-center space-x-2">
                    <div className="flex items-center px-2">
                      <ArrowUpCircle className="h-4 w-4 mr-1" />
                      <span>0</span> {/* We don't have vote data in bookmarks */}
                    </div>
                    <div className="flex items-center px-2">
                      <MessageSquare className="h-4 w-4 mr-1" />
                      <span>0</span> {/* We don't have comment count in bookmarks */}
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="sm">
                          <MoreHorizontal className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => handleUnsavePost(bookmark.data_id)}>
                          <Bookmark className="mr-2 h-4 w-4" />
                          <span>Unsave Post</span>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>Share</DropdownMenuItem>
                        <DropdownMenuItem>Report</DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                <Link to={`/post/${bookmark.data_id}`} className="text-xs text-blue-500 hover:underline mt-2 inline-block">
                  View {bookmark.content_type === 'response' ? 'parent post' : 'post'}
                </Link>
              </CardContent>
            </Card>
          ))}
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center items-center space-x-2 mt-6">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4 mr-1" />
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? "default" : "outline"}
              size="sm"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
            <ChevronRight className="h-4 w-4 ml-1" />
          </Button>
        </div>
      )}
    </div>
  )
}