"use client";

import React, { useState, useContext } from "react";
import { Badge } from "components/catalyst/badge";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
  createPaginationItems,
} from "components/ui/pagination";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { Link } from "react-router-dom";
import { TouchpointContext } from "contexts/TouchpointContext";
import moment from "moment";

const colorMapBackground = {
  "Order Inactivity": "orange",
  "Net New Customer": "amber",
  "New Customer Promo": "cyan",
  "First invoice anniversary": "purple",
  "First Invoice Anniversary": "purple",
  "Open AR Payments": "pink",
  "YoY Sales Drop": "red",
  "Reduced Invoice Activity": "orange",
  "Customer DRP dropped": "rose",
  "Customer DRP Dropped": "rose",
  "Eligible for new customer promo": "sky",
  "Eligible For New Customer Promo": "sky",
  "Customer DRP below expected": "yellow",
  "Customer DRP Below Expected": "yellow",
  "First SO Invoiced": "teal",
  "First Digital SO Invoiced": "indigo",
  "Limit Increased": "green",
  "Residential": "blue",
  "No Orders Found": "fuchsia",
  "No Repeat Purchases": "orange",
  "60 Days Inactivity": "red",
  "Low Sales": "amber",
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || "#FFFFFF";
};

const pillOrder = [
  "YoY Sales Drop",
  "Reduced Invoice Activity",
  "Open AR Payments",
  "Customer DRP Dropped",
  "Customer DRP dropped",
  "Customer DRP Below Expected",
  "Customer DRP below expected",
  "Limit Increased",
  "Commercial",
  "Residential",
  "60 Days Inactivity",
  "No Orders Found",
  "No Repeat Purchases",
  "Low Sales",
  "Eligible For New Customer Promo",
  "Eligible for new customer promo",
  "First Digital SO Invoiced",
  "First SO Invoiced",
  "First Invoice Anniversary",
  "First invoice anniversary",
  "Order Inactivity",
  "Net New Customer",
  "New Customer Promo",
];

const sortPills = (pills) => {
  if (!pills || !Array.isArray(pills)) return [];

  return [...pills].sort((a, b) => {
    const indexA = pillOrder.indexOf(a);
    const indexB = pillOrder.indexOf(b);

    // If both pills are found in the order array, sort by their position
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one pill is found, prioritize the one in the order array
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither is found, maintain original order
    return 0;
  });
};

export function TabsTable({ tabledata }) {
  const { touchpoints } = useContext(TouchpointContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = tabledata.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(tabledata.length / itemsPerPage);
  const isMobile = useMediaQuery("(max-width: 640px)");

  // Get most recent touchpoint for a customer
  const getRecentTouchpoint = (custcd) => {
    const customerTouchpoints = touchpoints.filter(
      (tp) => tp.custcd === custcd
    );
    if (customerTouchpoints.length === 0) return null;

    return customerTouchpoints.reduce((latest, current) => {
      return moment(current.touchpoint_date).isAfter(
        moment(latest.touchpoint_date)
      )
        ? current
        : latest;
    });
  };

  return (
    <>
      <div
        className={`overflow-x-hidden overflow-y-hidden w-full ${
          isMobile ? "flex flex-col" : ""
        }`}
      >
        {isMobile ? (
          currentUsers?.map((user) => (
            <Link to={`/customer/${user.custcd}`} key={user.custcd}>
              <div className="p-4 border rounded-lg mb-4">
                <div className="font-semibold text-zinc-900 text-sm truncate w-full mb-2 dark:text-zinc-100">
                  {user.custname}
                  {getRecentTouchpoint(user.custcd) && (
                    <div>
                      <Badge color="gray" className="w-full text-sm">
                        {moment(
                          getRecentTouchpoint(user.custcd).touchpoint_date
                        ).format("MM/DD/YY")}{" "}
                        - {getRecentTouchpoint(user.custcd).summary}
                      </Badge>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-1 mb-2">
                  {user.tags?.map((badge, index) => (
                    <Badge key={index} className="bg-zinc-600 text-gray-50">
                      {badge}
                    </Badge>
                  ))}
                </div>
                <div className="flex flex-col gap-1">
                  {sortPills(user.insight_pills)?.map((badge, index) => (
                    <Badge key={index} color={getBadgeColor(badge)}>
                      {badge}
                    </Badge>
                  ))}
                </div>
              </div>
            </Link>
          ))
        ) : (
          <Table className="min-w-full mt-4">
            <TableCaption></TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3">Customer</TableHead>
                <TableHead>Customer Details</TableHead>
                <TableHead>Insights</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {currentUsers?.map((user) => (
                <TableRow key={user.custcd}>
                  <TableCell className="w-1/3">
                    <div className="flex flex-col gap-2">
                      <Link
                        to={`/customer/${user.custcd}`}
                        className="font-semibold text-zinc-900 dark:text-zinc-100 hover:underline transition-all duration-200"
                      >
                        {user.custname}
                      </Link>
                      {getRecentTouchpoint(user.custcd) && (
                        <Badge color="gray" className="w-fit">
                          {moment(
                            getRecentTouchpoint(user.custcd).touchpoint_date
                          ).format("MM/DD/YY")}{" "}
                          - {getRecentTouchpoint(user.custcd).summary}
                        </Badge>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="w-1/3 text-zinc-500">
                    <div className="flex flex-col gap-1">
                      {user.tags?.map((badge, index) => (
                        <Badge
                          key={index}
                          className="min-w-[200px] max-w-[400px] bg-zinc-600 text-gray-50"
                        >
                          {badge}
                        </Badge>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell className="w-1/3 text-zinc-500">
                    <div className="flex flex-col gap-1">
                      {sortPills(user.insight_pills)?.map((badge, index) => (
                        <Badge key={index} color={getBadgeColor(badge)}>
                          {badge}
                        </Badge>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        )}
      </div>
      <Pagination className="mt-6 w-full flex justify-center">
        <PaginationPrevious
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          disabled={currentPage === 1}
        />
        <PaginationContent className="flex justify-center w-full">
          {createPaginationItems(totalPages, currentPage, setCurrentPage)}
        </PaginationContent>
        <PaginationNext
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </>
  );
}

// useMediaQuery hook
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  React.useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}
