"use client";

import React, { useContext, useEffect, useMemo } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

import { Badge } from "components/catalyst/badge";
import { TouchpointContext } from "contexts/TouchpointContext";
import moment from "moment";

import DataTablePagination from "./data-table-pagination";
import DataTableToolbar from "./data-table-toolbar";
import { AppContext } from "contexts/AppContext";

function DataTable({ columns, data }) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [showNonSaleable, setShowNonSaleable] = React.useState(false);
  const { touchpoints } = useContext(TouchpointContext);
  const { salesAgentCD } = useContext(AppContext)

  // Reset all filters when salesagentcd changes
  useEffect(() => {
      // Reset all filters and states
      setColumnFilters([]);
      setGlobalFilter("");
      setShowNonSaleable(false);
      setRowSelection({});
  }, [salesAgentCD]);

  // Check if there are any Non-Saleable customers
  const hasNonSaleableCustomers = useMemo(() => {
    return data.some(customer => customer.tags && customer.tags.includes("Non-Saleable"));
  }, [data]);

  // Get most recent touchpoint for a customer
  const getRecentTouchpoint = (custcd) => {
    const customerTouchpoints = touchpoints.filter((tp) => tp.custcd === custcd);
    if (customerTouchpoints.length === 0) return null;

    return customerTouchpoints.reduce((latest, current) => {
      return moment(current.touchpoint_date).isAfter(moment(latest.touchpoint_date))
        ? current
        : latest;
    });
  };

  // Filter out Non-Saleable customers unless toggle is on
  const filteredData = useMemo(() => {
    if (showNonSaleable) return data;
    return data.filter(customer => !customer.tags || !customer.tags.includes("Non-Saleable"));
  }, [data, showNonSaleable]);

  // Custom toggle handler that also updates filters
  const handleToggleNonSaleable = (value) => {
    setShowNonSaleable(value);
    
    // If turning off, remove Non-Saleable from filters
    if (!value) {
      const tagsColumn = table.getColumn("tags");
      if (tagsColumn) {
        const filterValue = tagsColumn.getFilterValue();
        if (filterValue && Array.isArray(filterValue) && filterValue.includes("Non-Saleable")) {
          const newFilterValue = filterValue.filter(tag => tag !== "Non-Saleable");
          tagsColumn.setFilterValue(newFilterValue.length ? newFilterValue : undefined);
        }
      }
    }
  };

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="space-y-4">
      <DataTableToolbar 
        table={table} 
        showNonSaleable={showNonSaleable} 
        setShowNonSaleable={handleToggleNonSaleable}
        hasNonSaleableCustomers={hasNonSaleableCustomers}
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      {cell.column.id === "custname" && getRecentTouchpoint(row.original.custcd) && (
                        <div className="mt-2">
                          <Badge color="gray" className="w-fit">
                            {moment(getRecentTouchpoint(row.original.custcd).touchpoint_date).format("MM/DD/YY")}{" "}
                            - {getRecentTouchpoint(row.original.custcd).summary}
                          </Badge>
                        </div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

export default DataTable;