import React, { useState, useContext } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { CreateVoice } from './components/create-voice';
import { CreateText } from './components/create-text';
import { NotesContext } from "contexts/NotesContext";
import { Button } from "components/ui/button";
import { Switch } from "components/ui/switch";
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from 'contexts/AppContext';
import { toast } from 'sonner'; // Add this import
import { DialogClose } from 'components/ui/dialog';

export function NoteCreate({ selectedNote, customerId, handleClose = () => {} }) {
  const { createNote, updateNote, draftNote } = useContext(NotesContext);
  const { salesAgentCD } = useContext(AppContext);
  const [selectedAccount, setSelectedAccount] = useState(selectedNote?.custcd || customerId || '');

  const [errorMessage, setErrorMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);

  // State for note fields
  const [noteId, setNoteId] = useState(() => selectedNote?.notesid || uuidv4());
  const [title, setTitle] = useState(selectedNote?.header || '');
  const [content, setContent] = useState(selectedNote?.content || '');
  const [isPriority, setIsPriority] = useState(selectedNote?.is_priority || false);
  const [isBookmark, setIsBookmark] = useState(selectedNote?.is_bookmark || false);
  const [reminderDate, setReminderDate] = useState(selectedNote?.reminder_date || null);
  const [selectedLabels, setSelectedLabels] = useState(selectedNote?.tags || []);

  const [transcript, setTranscript] = useState(selectedNote?.transcript || '');
  const [type, setType] = useState(selectedNote?.type || 'text');

  const handleSave = async (e, isDraft) => {
    e.preventDefault();
    console.log("handleSave called", { isDraft });

    if (isRecording){
      setErrorMessage(`Please stop the recording to proccess the text.`);
      return;
    }

    const missingFields = [];
    if (!title) missingFields.push('title');
    if (!content) missingFields.push('content');
    if (!selectedAccount) missingFields.push('account');

    if (missingFields.length > 0) {
      setErrorMessage(`Please add ${missingFields.join(', ')}`);
      return;
    }

    setErrorMessage('');

    const note = {
      notesid: String(noteId), 
      salesagentcd: salesAgentCD,
      custcd: selectedAccount,
      header: title,
      content: content,
      type: type,
      transcript: transcript,
      reminder_date: reminderDate,
      tags: selectedLabels,
      is_priority: isPriority,
      is_bookmark: isBookmark,
      reminder: false
    };

    console.log(note)

    const updatedDate = new Date().toISOString()

    try {
      if (selectedNote) { // Update Current note
        console.log("UPDATE");
        await updateNote({ ...note, updated_date: updatedDate });
        toast("Note has been updated", {
          description: "Your changes have been saved",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        });
      } else {
        if (isDraft) {
          await createNote({ ...note, creation_date: updatedDate });
          await draftNote({ ...note, updated_date: updatedDate });
          toast("Draft has been saved", {
            description: "Your draft has been saved",
            action: {
              label: "Close",
              onClick: () => console.log("Close"),
            },
          });
        } else {
          await createNote({ ...note, creation_date: updatedDate });
          toast("Note has been created", {
            description: "Your note has been created",
            action: {
              label: "Close",
              onClick: () => console.log("Close"),
            },
          });
        }
      }

      // Reset the form after save
      resetForm();

      // Close the dialog
      if (typeof handleClose === 'function') {
        handleClose();
      }
    } catch (error) {
      console.error("Error saving note:", error);
      toast("Error saving note", {
        description: "An error occurred while saving the note",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
    }
  };

  const resetForm = () => {
    setNoteId('');
    setTitle('');
    setContent('');
    setIsBookmark(false);
    setIsPriority(false);
    setReminderDate(null);
    setSelectedAccount('');
    setSelectedLabels([]);

    // Close the modal
    if (typeof handleClose === 'function') {
      handleClose();
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="p-4 sm:p-0">
        <form>
          <Tabs defaultValue="voice" className="w-full">
            <TabsList>
              <TabsTrigger value="voice">Add Voice Note</TabsTrigger>
              <TabsTrigger value="note">Add Note</TabsTrigger>
            </TabsList>
            <TabsContent value="note">
              <CreateText
                title={title}
                setTitle={setTitle}
                content={content}
                setContent={setContent}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            </TabsContent>
            <TabsContent value="voice">
              <CreateVoice
                title={title}
                setTitle={setTitle}
                content={content}
                setContent={setContent}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                setType={setType}
                setTranscript={setTranscript}
                setIsRecording={setIsRecording}
                setErrorMessage={setErrorMessage}
              />
            </TabsContent>
          </Tabs>
          <div className="flex items-center flex-start mt-4">
            {/* <NoteCreateAddLabel
              selectedLabel={selectedLabels}
              setSelectedLabel={setSelectedLabels}
            /> */}
            <p className="text-sm text-muted-foreground w-[130px]">Bookmark Note</p>
            <div className="flex space-x-2">
              <Switch
                id="bookmark"
                checked={isBookmark}
                onCheckedChange={setIsBookmark}
                aria-label="Bookmark Note"
              />
            </div>
          </div>
          {/* <SetDate/> */}
          {errorMessage && (
            <div className="text-red-600 text-sm mt-4">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-end mt-4">
            {!selectedNote &&
            <Button
              onClick={(e) => handleSave(e, true)}
              size="sm"
              className="ml-auto"
              variant="outline"
            >
              Save Draft
            </Button>
            }
            <DialogClose asChild>
              <Button
                onClick={(e) => handleSave(e, false)}
                size="sm"
                className="ml-2"
              >
                {selectedNote ? 'Update Note' : 'Create Note'}
              </Button>
            </DialogClose>
          </div>
        </form>
      </div>
    </div>
  );
}