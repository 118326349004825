"use client";

import {
  useContext,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { toast } from "sonner";
import {
  Dialog,
  DialogClose,
  DialogTitle,
  DialogDescription,
} from "components/ui/dialog";
import { AppContext } from "contexts/AppContext";
import { TouchpointContext } from "contexts/TouchpointContext";
import { ContactsContext } from "contexts/ContactsContext";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const CalendarEvent = forwardRef(
  ({ customerName, customerId, onClose, action_id }, ref) => {
    const { salesAgentCD, oktaId, logEvent, BASE_URL, token, custcd } =
      useContext(AppContext);
    const { addTouchpoint } = useContext(TouchpointContext);
    const { fetchContacts } = useContext(ContactsContext);

    const [contacts, setContacts] = useState([]);
    const [rawAttendees, setRawAttendees] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [startTime, setStartTime] = useState("12:00");
    const [endTime, setEndTime] = useState("12:30");
    const [title, setTitle] = useState("");
    const [meetingDetails, setMeetingDetails] = useState("");

    useEffect(() => {
      const fetchCustomerContacts = async () => {
        try {
          let fetchedContacts = [];

          // If action_id exists, fetch contacts using customerId
          if (action_id) {
            fetchedContacts = await fetchContacts(customerId);
          } else {
            // If no action_id, fetch contacts using custcd from AppContext
            if (custcd) {
              fetchedContacts = await fetchContacts(custcd);
            }
          }

          // Set the fetched contacts to the contacts state
          setContacts(fetchedContacts);
        } catch (error) {
          console.error("Error fetching contacts:", error);
        }
      };

      fetchCustomerContacts();
    }, [action_id, customerId, custcd, fetchContacts]);

    useEffect(() => {
      try {
        if (Array.isArray(contacts) && contacts.length > 0) {
          const primaryContact = contacts[0];
          if (primaryContact?.primary_contact_info) {
            const contactInfo = JSON.parse(primaryContact.primary_contact_info);
            if (contactInfo?.contact_email) {
              setRawAttendees(contactInfo.contact_email);
            }
          }
        }
      } catch (error) {
        console.error("Error setting initial contact:", error);
      }
    }, [contacts]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest(".contacts-dropdown")) {
          setShowSuggestions(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const parseEmails = (input) => {
      const emailString = input.replace(/;/g, ',');
      const regex = /([^,\s]+@[^,\s]+\.[^,\s]+)/g;
      return Array.from(emailString.matchAll(regex), (match) => match[1]);
    };

    const handleAttendeesChange = (e) => {
      setRawAttendees(e.target.value);
      setShowSuggestions(true);
    };

    const handleContactSelect = (contact) => {
      try {
        if (contact?.primary_contact_info) {
          const contactInfo = JSON.parse(contact.primary_contact_info);
          if (contactInfo?.contact_email) {
            setRawAttendees((prevAttendees) =>
              prevAttendees
                ? `${prevAttendees}, ${contactInfo.contact_email}`
                : contactInfo.contact_email
            );
            setShowSuggestions(false);
          }
        }
      } catch (error) {
        console.error("Error selecting contact:", error);
      }
    };

    const handleSubmit = async () => {
      const attendees = parseEmails(rawAttendees);

      // if (attendees.length === 0) {
      //   toast.error("Please add at least one valid email address");
      //   return;
      // }

      const startDateTime = selectedDate
        ? new Date(
            selectedDate.setHours(
              startTime.split(":")[0],
              startTime.split(":")[1]
            )
          )
        : null;
      const endDateTime = selectedDate
        ? new Date(
            selectedDate.setHours(endTime.split(":")[0], endTime.split(":")[1])
          )
        : null;

      const eventPayload = {
        oktaid: oktaId,
        salesagentcd: salesAgentCD,
        subject: title,
        content: meetingDetails,
        attendees_emails: attendees,
        start_time: startDateTime
          ? format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS")
          : null,
        end_time: endDateTime
          ? format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS")
          : null,
        timezone: "America/New_York",
      };

      console.log("Event payload being sent to API:", eventPayload);

      try {
        const response = await axios.post(
          `${BASE_URL}/create-event`,
          eventPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("API response received:", response);

        if (response.status === 200) {
          // Persistent toast for event creation
          toast.success("Calendar Event Created", {
            description: `Date: ${format(selectedDate, "MMMM d, yyyy")}; Time: ${startTime} - ${endTime}; Attendees: ${attendees.join(', ')}`,
            duration: Infinity,
            closeButton: true,
            // action: {
            //   label: 'Dismiss',
            //   onClick: () => toast.dismiss()
            // }
          });

          // Create touchpoint only if no action_id exists
          if (!action_id) {
            const touchpointDate = selectedDate
              ? new Date(
                  selectedDate.setHours(
                    startTime.split(":")[0],
                    startTime.split(":")[1]
                  )
                ).toISOString()
              : new Date().toISOString();

            const formattedTouchpointDate =
              touchpointDate.slice(0, 19) +
              "." +
              touchpointDate.split(".")[1].padStart(3, "0") +
              "000";

            const currentDate = new Date().toISOString();
            const formattedDate =
              currentDate.slice(0, 19) +
              "." +
              currentDate.split(".")[1].padStart(3, "0") +
              "000";

            let eventContent = "";
            if (title) {
              eventContent += `Title: ${title}\n\n`;
            }
            if (meetingDetails) {
              eventContent += meetingDetails;
            }

            const metadata = {
              tp_type: "in-person",
              duration: null,
              num_participants: attendees.length,
              participants: attendees,
              location: null,
              content: eventContent.trim() || null,
              disposition: null,
              notes: null,
              followup: null,
            };

            const touchpointData = {
              touchpointsid: uuidv4(),
              custcd: customerId,
              custname: customerName,
              tp_type: "in-person",
              summary: "Meeting Scheduled",
              touchpoint_date: formattedTouchpointDate,
              system_generated: true,
              visible: true,
              bookmarked: false,
              status: "scheduled",
              impact: 0,
              response_status: false,
              created_date: formattedDate,
              metadata: metadata,
            };

            await addTouchpoint(touchpointData);
          }

          if (typeof onClose === "function") {
            onClose();
          }
          return true;
        }

        console.error("Error creating event:", response);
        toast.error("Error creating calendar event");
        return false;
      } catch (error) {
        console.error("Error creating event:", error);
        toast.error("Error creating calendar event");
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      title,
      selectedDate,
      meetingDetails,
      handleSubmit,
      getAttendees: () => parseEmails(rawAttendees),
    }));

    return (
      <div className="w-full max-w-5xl mx-auto relative">
        {!action_id && (
          <>
            <DialogTitle className="text-lg sm:text-xl font-semibold mb-1">
              Add Calendar Event
            </DialogTitle>
            <DialogDescription className="text-base sm:text-md text-slate-500 dark:text-slate-500 font-light mb-6">
              {customerName || customerId}
            </DialogDescription>
          </>
        )}

        <div className="space-y-4 sm:space-y-6">
          {/* Title Input */}
          <div className="space-y-2">
            <Input
              placeholder="Add title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="text-base sm:text-lg font-semibold w-full"
            />
          </div>

          {/* Attendees Input with Suggestions */}
          <div className="space-y-2 relative contacts-dropdown">
            <Label>To</Label>
            <Input
              placeholder="Enter email address"
              value={rawAttendees}
              onChange={handleAttendeesChange}
              onFocus={() => setShowSuggestions(true)}
              className="w-full"
            />
            {showSuggestions &&
              Array.isArray(contacts) &&
              contacts.length > 0 && (
                <div className="absolute z-10 w-full mt-1 rounded-md border shadow-md overflow-hidden bg-white dark:bg-gray-800">
                  <div className="py-1 px-1 bg-white dark:bg-gray-800">
                    {contacts.map((contact) => {
                      try {
                        if (
                          !contact?.contact_name ||
                          !contact?.primary_contact_info
                        ) {
                          return null;
                        }
                        const contactName = JSON.parse(contact.contact_name);
                        const contactInfo = JSON.parse(
                          contact.primary_contact_info
                        );
                        if (!contactName || !contactInfo?.contact_email) {
                          return null;
                        }

                        return (
                          <div
                            key={contact.contact_id}
                            onClick={() => handleContactSelect(contact)}
                            className={`flex flex-col gap-1 px-2 py-1.5 rounded-sm cursor-pointer text-sm hover:bg-accent hover:text-accent-foreground`}
                          >
                            <div className="font-medium flex items-center justify-between">
                              {`${contactName.contact_first_name} ${contactName.contact_last_name}`}
                              {contact === contacts[0] && (
                                <span className="text-xs text-muted-foreground ml-2">
                                  (Primary)
                                </span>
                              )}
                            </div>
                            <div className="text-xs text-muted-foreground">
                              {contactInfo.contact_email}
                            </div>
                          </div>
                        );
                      } catch (error) {
                        console.error("Error rendering contact:", error);
                        return null;
                      }
                    })}
                  </div>
                </div>
              )}
          </div>

          {/* Date and Time Selection */}
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="w-full sm:w-1/2">
                <Label className="mb-2">Date</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className="w-full justify-start text-left font-normal"
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {selectedDate
                        ? format(selectedDate, "PPP")
                        : "Select date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={selectedDate}
                      onSelect={setSelectedDate}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>

              <div className="w-full sm:w-1/2">
                <Label className="mb-2">Time</Label>
                <div className="flex gap-2 items-center">
                  <Select value={startTime} onValueChange={setStartTime}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Start time" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 24 }).map((_, i) => (
                        <SelectItem
                          key={i}
                          value={`${i.toString().padStart(2, "0")}:00`}
                        >
                          {`${i.toString().padStart(2, "0")}:00`}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <span className="text-sm text-gray-500">to</span>
                  <Select value={endTime} onValueChange={setEndTime}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="End time" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 24 }).map((_, i) => (
                        <SelectItem
                          key={i}
                          value={`${i.toString().padStart(2, "0")}:30`}
                        >
                          {`${i.toString().padStart(2, "0")}:30`}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Meeting Details */}
          <div className="space-y-2">
            <Label htmlFor="meeting-details">Meeting details</Label>
            <Textarea
              id="meeting-details"
              placeholder="Type details for this new meeting"
              className={`w-full ${
                action_id ? "min-h-[42px] max-h-[42px]" : ""
              }`}
              value={meetingDetails}
              onChange={(e) => setMeetingDetails(e.target.value)}
            />
          </div>

          {/* Submit Button */}
          {!action_id && (
            <DialogClose asChild>
              <Button
                className="w-full"
                onClick={handleSubmit}
                disabled={!title.trim() || !selectedDate}
              >
                Create Event
              </Button>
            </DialogClose>
          )}
        </div>
      </div>
    );
  }
);

export default CalendarEvent;
