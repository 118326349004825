import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "contexts/AppContext";

export const WaterCoolerContext = createContext();

// Define the water cooler BASE_URL directly
const WATER_COOLER_BASE_URL =
  " https://cosailor-water-cooler-main-586731320826.us-central1.run.app";

export const WaterCoolerProvider = ({ children }) => {
  const { oktaId, token } = useContext(AppContext);

  // State variables
  const [subcoolers, setSubcoolers] = useState([]);
  const [currentSubcooler, setCurrentSubcooler] = useState(null);
  const [currentPost, setCurrentPost] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Helper function to get the numeric user ID
  const getUserId = () => userData?.profile?.user_id;

  // Fetch user data
  const fetchUserData = async () => {
    if (!oktaId) {
      console.log("fetchUserData: No oktaId available");
      return;
    }

    setLoading(true);
    setError(null);

    console.log(`Fetching user data for oktaId: ${oktaId}`);

    try {
      const response = await axios.get(
        `${WATER_COOLER_BASE_URL}/user/${oktaId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("User data received:", response.data);
      setUserData(response.data);
      return response.data;
    } catch (err) {
      console.error("Problem with fetchUserData:", err);
      console.log("Error response:", err.response?.data);
      setError("Failed to fetch user data");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Fetch subcooler data
  const fetchSubcooler = async (dataId, sortPostsBy = "date") => {
    if (!dataId) {
      console.log("fetchSubcooler: No dataId provided");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(
      `Fetching subcooler from ${WATER_COOLER_BASE_URL} with ID ${dataId}, sortPostsBy: ${sortPostsBy}, oktaId: ${oktaId}`
    );

    try {
      const response = await axios.get(
        `${WATER_COOLER_BASE_URL}/subcooler/${dataId}`,
        {
          params: { sort_posts_by: sortPostsBy, okta_id: oktaId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Subcooler data received:", response.data);
      setCurrentSubcooler(response.data);
      return response.data;
    } catch (err) {
      console.error(`Problem with fetchSubcooler for ID ${dataId}:`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to fetch subcooler data");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Fetch post data
  const fetchPost = async (dataId, sortCommentsBy = "date") => {
    if (!dataId) return;
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${WATER_COOLER_BASE_URL}/post/${dataId}`,
        {
          params: { sort_comments_by: sortCommentsBy, okta_id: oktaId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCurrentPost(response.data);
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(`Problem with fetchPost for ID ${dataId}`, err);
      setError("Failed to fetch post data");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Add post
  const addPost = async (parentId, title, content, topic) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("addPost: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);
  
    console.log(`Adding post with parent_id: ${parentId}, user_id: ${userId}, topic: ${topic}`);
  
    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/add-post`,
        {
          parent_id: parentId,
          user_id: userId,
          title,
          content,
          topic
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Post added successfully:", response.data);
  
      // Refresh the subcooler data to include the new post
      await fetchSubcooler(parentId);
      return response.data;
    } catch (err) {
      console.log("Problem with addPost", err);
      console.log("Error response:", err.response?.data);
      setError("Failed to add post");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Add response
  const addResponse = async (parentId, content) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("addResponse: No user ID available");
      return;
    }

    // Save current scroll position
    const scrollPosition = window.scrollY;

    setLoading(true);
    setError(null);

    console.log(
      `Adding response with parent_id: ${parentId}, user_id: ${userId}`
    );

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/add-response`,
        {
          parent_id: parentId,
          user_id: userId,
          content,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Response added successfully:", response.data);

      // If we're responding to the main post, refresh that post
      if (currentPost && parentId === currentPost.data_id) {
        await fetchPost(parentId);
      }
      // If we're responding to a comment, refresh the main post that contains it
      else if (currentPost) {
        await fetchPost(currentPost.data_id);
        await fetchUserData();
      }

      // Restore scroll position after state updates
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 100);

      return response.data;
    } catch (err) {
      console.log("Problem with addResponse", err);
      console.log("Error response:", err.response?.data);
      setError("Failed to add response");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Cast vote
  const castVote = async (dataId, voteType) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("castVote: No user ID available");
      return;
    }
    setError(null);

    console.log(
      `Casting vote for dataId: ${dataId}, voteType: ${voteType}, caster_id: ${userId}`
    );

    try {
      // Make sure dataId is a number
      const numericDataId = parseInt(dataId, 10);

      const payload = {
        data_id: numericDataId,
        vote_type: voteType,
        user_id: userId, // Using user_id as expected by the API endpoint
      };

      console.log("Sending payload:", payload);

      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/cast-vote`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Vote cast successfully:", response.data);

      return response.data;
    } catch (err) {
      console.log(`Problem with castVote for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to cast vote");
      return null;
    }
  };

  // Update post
  const updatePost = async (dataId, title, content) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("updatePost: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(`Updating post with data_id: ${dataId}, user_id: ${userId}`);

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/update-post`,
        {
          data_id: dataId,
          title,
          content,
          user_id: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Post updated successfully:", response.data);

      // Refresh post data
      if (currentPost && currentPost.data_id === dataId) {
        await fetchPost(dataId);
      } else if (currentSubcooler) {
        await fetchSubcooler(currentSubcooler.data_id);
      }
      return response.data;
    } catch (err) {
      console.log(`Problem with updatePost for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to update post");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Update response
  const updateResponse = async (dataId, content) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("updateResponse: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(
      `Updating response with data_id: ${dataId}, user_id: ${userId}`
    );

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/update-response`,
        {
          data_id: dataId,
          content,
          user_id: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Response updated successfully:", response.data);

      // Refresh post data containing this response
      if (currentPost) {
        await fetchPost(currentPost.data_id);
      }
      return response.data;
    } catch (err) {
      console.log(`Problem with updateResponse for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to update response");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Delete post
  const deletePost = async (dataId) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("deletePost: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(`Deleting post with data_id: ${dataId}, user_id: ${userId}`);

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/delete-post`,
        {
          data_id: dataId,
          user_id: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Post deleted successfully:", response.data);

      // Refresh subcooler after deletion
      if (
        currentPost &&
        currentPost.data_id === dataId &&
        currentPost.parent_id
      ) {
        await fetchSubcooler(currentPost.parent_id);
        setCurrentPost(null);
      } else if (currentSubcooler) {
        await fetchSubcooler(currentSubcooler.data_id);
      }
      return response.data;
    } catch (err) {
      console.log(`Problem with deletePost for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to delete post");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Delete response
  const deleteResponse = async (dataId) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("deleteResponse: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(
      `Deleting response with data_id: ${dataId}, user_id: ${userId}`
    );

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/delete-response`,
        {
          data_id: dataId,
          user_id: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Response deleted successfully:", response.data);

      // Refresh post data after deletion
      if (currentPost) {
        await fetchPost(currentPost.data_id);
      }
      return response.data;
    } catch (err) {
      console.log(`Problem with deleteResponse for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to delete response");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Set best response
  const setBestResponse = async (postId, responseId) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("setBestResponse: No user ID available");
      return;
    }

    // Save current scroll position
    const scrollPosition = window.scrollY;

    setLoading(true);
    setError(null);

    console.log(
      `Setting best response for post_id: ${postId}, response_id: ${responseId}, user_id: ${userId}`
    );

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/set-best-response`,
        {
          post_id: postId,
          response_id: responseId,
          user_id: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Best response set successfully:", response.data);

      // Refresh post data to reflect the best response
      await fetchPost(postId);

      // Restore scroll position after state updates
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 100);

      return response.data;
    } catch (err) {
      console.log(`Problem with setBestResponse for post ID ${postId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to set best response");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Add subcooler
  const addSubcooler = async (title, content) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("addSubcooler: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(`Adding subcooler with title: ${title}, user_id: ${userId}`);

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/add-subcooler`,
        {
          user_id: userId,
          title,
          content,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Subcooler added successfully:", response.data);

      // Update subcoolers list
      fetchUserData();
      return response.data;
    } catch (err) {
      console.log("Problem with addSubcooler", err);
      console.log("Error response:", err.response?.data);
      setError("Failed to add subcooler");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Toggle subscription to subcooler
  const toggleSubscription = async (dataId) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("toggleSubscription: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(
      `Toggling subscription for data_id: ${dataId}, user_id: ${userId}`
    );

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/toggle-subscription`,
        {
          user_id: userId,
          data_id: dataId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Subscription toggled successfully:", response.data);

      // Refresh user data and current subcooler
      await fetchUserData();
      if (currentSubcooler && currentSubcooler.data_id === dataId) {
        await fetchSubcooler(dataId);
      }
      return response.data;
    } catch (err) {
      console.log(`Problem with toggleSubscription for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to toggle subscription");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Toggle bookmark
  const toggleBookmark = async (dataId) => {
    const userId = getUserId();
    if (!oktaId || !userId) {
      console.log("toggleBookmark: No user ID available");
      return;
    }
    setLoading(true);
    setError(null);

    console.log(`Toggling bookmark for data_id: ${dataId}, user_id: ${userId}`);

    try {
      const response = await axios.post(
        `${WATER_COOLER_BASE_URL}/toggle-bookmark`,
        {
          user_id: userId,
          data_id: dataId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Bookmark toggled successfully:", response.data);

      // Refresh user data and current view
      await fetchUserData();
      if (currentPost && currentPost.data_id === dataId) {
        await fetchPost(dataId);
      } else if (currentSubcooler) {
        // Check if the bookmarked item is in the current subcooler
        const isInCurrentSubcooler = currentSubcooler.posts.some(
          (post) => post.data_id === dataId
        );
        if (isInCurrentSubcooler) {
          await fetchSubcooler(currentSubcooler.data_id);
        }
      }
      return response.data;
    } catch (err) {
      console.log(`Problem with toggleBookmark for data ID ${dataId}`, err);
      console.log("Error response:", err.response?.data);
      setError("Failed to toggle bookmark");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Clear current post and subcooler
  const clearCurrentView = () => {
    setCurrentPost(null);
    setCurrentSubcooler(null);
  };

  // Initial data fetch
  useEffect(() => {
    if (oktaId) {
      console.log("WaterCoolerContext initialized with oktaId:", oktaId);
      fetchUserData();
      fetchSubcooler(1);
    } else {
      console.log("WaterCoolerContext initialized without oktaId");
    }
  }, [oktaId]);

  return (
    <WaterCoolerContext.Provider
      value={{
        // State
        subcoolers,
        currentSubcooler,
        currentPost,
        userData,
        loading,
        error,

        // Actions
        fetchUserData,
        fetchSubcooler,
        fetchPost,
        addPost,
        addResponse,
        castVote,
        updatePost,
        updateResponse,
        deletePost,
        deleteResponse,
        setBestResponse,
        addSubcooler,
        toggleSubscription,
        toggleBookmark,
        clearCurrentView,
      }}
    >
      {children}
    </WaterCoolerContext.Provider>
  );
};
