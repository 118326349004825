import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Send,
  MessageSquare,
  MoreHorizontal,
  ChevronLeft,
  ChevronRight,
  Clock,
  TrendingUp,
  ThumbsUp,
  Filter,
  Tag,
} from "lucide-react";
import {
  Text,
  Title,
  Grid,
  Col,
  Button,
  Badge,
  TextInput,
  Flex,
  Tab,
  TabList,
  TabGroup,
  TabPanel,
  TabPanels,
  Select,
  SelectItem,
  List,
  ListItem,
  Textarea,
} from "@tremor/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Card, CardHeader, CardContent } from "components/ui/card";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid";
import { WaterCoolerContext } from "contexts/WaterCoolerContext";

const POSTS_PER_PAGE = 5;
const SUBCOOLER_ID = 1;

export default function WaterCoolerPage() {
  const {
    currentSubcooler,
    userData,
    loading,
    error,
    fetchSubcooler,
    addPost,
    castVote,
  } = useContext(WaterCoolerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSubcooler(1);
  }, []);

  const [newPostTitle, setNewPostTitle] = useState("");
  const [newPostContent, setNewPostContent] = useState("");
  const [newPostTopic, setNewPostTopic] = useState(""); // Empty default for "Select topic"
  const [currentPage, setCurrentPage] = useState(1);
  const [sortByRecency, setSortByRecency] = useState("most");
  const [sortByActivity, setSortByActivity] = useState("none");
  const [sortByVotes, setSortByVotes] = useState("none");
  const [votedPosts, setVotedPosts] = useState({});
  const [flashVotes, setFlashVotes] = useState({});
  const [sortedPosts, setSortedPosts] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");

  const topics = [
    "Sales",
    "Product",
    "Customer Service",
    "Technical",
    "Market Trends",
    "Supply Chain",
  ];

  // Add color mapping for topics
  const topicColors = {
    Sales: "emerald",
    Product: "blue",
    "Customer Service": "amber",
    Technical: "violet",
    "Market Trends": "rose",
    "Supply Chain": "indigo",
  };

  // Initialize votedPosts when currentSubcooler changes
  useEffect(() => {
    if (currentSubcooler?.posts) {
      setSortedPosts(currentSubcooler.posts);
      const initialVotedPosts = {};
      currentSubcooler.posts.forEach((post) => {
        if (post.user_vote === 1) {
          initialVotedPosts[post.data_id] = "up";
        } else if (post.user_vote === -1) {
          initialVotedPosts[post.data_id] = "down";
        }
      });
      setVotedPosts(initialVotedPosts);
    }
  }, [currentSubcooler]);

  // Function to handle voting on posts
  const handleVote = async (postId, direction) => {
    try {
      // Determine the current voting state
      const currentVote = votedPosts[postId];
      let voteType = direction === "up" ? "upvote" : "downvote";

      // If already voted same way, send novote to remove vote
      if (votedPosts[postId] === direction) {
        voteType = "novote";
      }

      // Find the post and update vote counts
      const postToUpdate = currentSubcooler.posts.find(
        (post) => post.data_id === postId
      );

      if (postToUpdate) {
        // Remove previous vote
        if (currentVote === "up") {
          postToUpdate.upvotes -= 1;
        } else if (currentVote === "down") {
          postToUpdate.downvotes -= 1;
        }

        // Apply new vote
        if (voteType === "upvote") {
          postToUpdate.upvotes += 1;
        } else if (voteType === "downvote") {
          postToUpdate.downvotes += 1;
        }
      }

      // Optimistically update UI
      setVotedPosts((prev) => {
        if (prev[postId] === direction) {
          // Removing vote
          const newState = { ...prev };
          delete newState[postId];
          return newState;
        } else {
          return { ...prev, [postId]: direction };
        }
      });

      // Add flash effect
      setFlashVotes((prev) => ({ ...prev, [postId]: direction }));
      setTimeout(() => {
        setFlashVotes((prev) => ({ ...prev, [postId]: null }));
      }, 1000);

      console.log(
        `Calling castVote with postId: ${postId}, voteType: ${voteType}`
      );

      // Call the castVote function from context
      const result = await castVote(postId, voteType);
      console.log("Vote result:", result);
    } catch (err) {
      console.error("Error casting vote:", err);
      alert("Failed to cast vote. Please try again.");
    }
  };

  // Function to handle new post submission
  const handleNewPostSubmit = async (e) => {
    e.preventDefault();

    if (newPostTitle.trim() === "") {
      alert("Please enter a title for your post");
      return;
    }

    if (newPostContent.trim() === "") {
      alert("Please enter content for your post");
      return;
    }

    if (!newPostTopic) {
      alert("Please select a topic for your post");
      return;
    }

    if (!userData?.profile?.user_id) {
      alert("You must be logged in to create a post");
      return;
    }

    try {
      const userId = userData.profile.user_id;

      console.log("Creating post with:", {
        parent_id: SUBCOOLER_ID,
        user_id: userId,
        title: newPostTitle,
        content: newPostContent,
        topic: newPostTopic,
      });

      // Call the addPost function from context with the correct user_id from userData
      const result = await addPost(
        SUBCOOLER_ID,
        newPostTitle,
        newPostContent,
        newPostTopic
      );
      console.log("Result from addPost:", result);

      // Clear input fields
      setNewPostTitle("");
      setNewPostContent("");
      setNewPostTopic(""); // Reset to empty to show "Select topic" placeholder

      // Force a refresh of the subcooler to show the new post
      await fetchSubcooler(SUBCOOLER_ID, "date");
    } catch (err) {
      console.error("Error creating post:", err);
      alert("Failed to create post. Please try again.");
    }
  };

  const handleSortChange = (type, value) => {
    // Reset all sort states
    setSortByRecency(type === "recency" ? value : "none");
    setSortByActivity(type === "activity" ? value : "none");
    setSortByVotes(type === "votes" ? value : "none");

    // Make a copy of the current posts to avoid mutation issues
    let postsToSort = [...currentSubcooler.posts];

    if (type === "recency") {
      // Sort by date
      postsToSort.sort((a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return value === "most" ? dateB - dateA : dateA - dateB;
      });
    }

    if (type === "activity") {
      // Sort by total number of comments
      postsToSort.sort((a, b) => {
        const commentsA = a.comment_count || 0;
        const commentsB = b.comment_count || 0;
        return value === "most" ? commentsB - commentsA : commentsA - commentsB;
      });
    } else if (type === "votes") {
      // Sort by net votes (upvotes - downvotes)
      postsToSort.sort((a, b) => {
        const netVotesA = (a.upvotes || 0) - (a.downvotes || 0);
        const netVotesB = (b.upvotes || 0) - (b.downvotes || 0);
        return value === "most" ? netVotesB - netVotesA : netVotesA - netVotesB;
      });
    }

    // Update our local sorted posts state
    setSortedPosts(postsToSort);

    // Reset to the first page
    setCurrentPage(1);
  };

  // Process posts for display
  const allPosts =
    sortedPosts.length > 0 ? sortedPosts : currentSubcooler?.posts || [];

  // Filter posts by selected topic if needed
  const posts = selectedTopic
    ? allPosts.filter((post) => post.topic === selectedTopic)
    : allPosts;

  // Calculate pagination
  const totalPages = Math.ceil(posts.length / POSTS_PER_PAGE);
  const paginatedPosts = posts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  // Calculate metrics
  const totalPosts = posts.length;
  const totalComments = posts.reduce(
    (sum, post) => sum + (post.comment_count || 0),
    0
  );
  const totalVotes = posts.reduce((sum, post) => sum + (post.upvotes || 0), 0);
  const avgCommentsPerPost =
    totalPosts > 0 ? (totalComments / totalPosts).toFixed(1) : "0.0";

  // Helper to format date
  const formatDate = (isoDate) => {
    if (!isoDate) return "";

    const date = new Date(isoDate);
    const now = new Date();
    const diffMs = now - date;
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return `${diffDays}d ago`;
    } else if (diffHours > 0) {
      return `${diffHours}h ago`;
    } else if (diffMins > 0) {
      return `${diffMins}m ago`;
    } else {
      return "Just now";
    }
  };

  return (
    <div className="p-2 sm:p-4 md:p-6">
      <Grid numItems={1} numItemsSm={1} numItemsMd={12} className="gap-6">
        {/* Left Sidebar - Full width on mobile, side column on desktop */}
        <Col numColSpan={1} numColSpanMd={3} className="space-y-6">
          {/* Subcooler Card */}
          <Card className="relative">
            <CardContent className="p-4">
              <Flex
                justifyContent="between"
                alignItems="center"
                className="mb-3"
              >
                <Title>{"SRS Distribution Forum"}</Title>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="light" icon={MoreHorizontal} size="sm" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Menu</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>
                      <Link to="/wc-profile">Profile</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Link to="/wc-saved">Saved Posts</Link>
                    </DropdownMenuItem>
                    {/* <DropdownMenuItem asChild>
                      <Link to="/wc-settings">Settings</Link>
                    </DropdownMenuItem> */}
                  </DropdownMenuContent>
                </DropdownMenu>
              </Flex>

              <div className="space-y-1.5">
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  className="border-b pb-1"
                >
                  <Text className="text-gray-500 text-sm">Posts</Text>
                  <Text className="font-medium text-gray-700">
                    {totalPosts}
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  className="border-b pb-1"
                >
                  <Text className="text-gray-500 text-sm">Comments</Text>
                  <Text className="font-medium text-gray-700">
                    {totalComments}
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  className="border-b pb-1"
                >
                  <Text className="text-gray-500 text-sm">Votes</Text>
                  <Text className="font-medium text-gray-700">
                    {totalVotes}
                  </Text>
                </Flex>
                <Flex alignItems="center" justifyContent="between">
                  <Text className="text-gray-500 text-sm">Avg Comments</Text>
                  <Text className="font-medium text-gray-700">
                    {avgCommentsPerPost}
                  </Text>
                </Flex>
              </div>
            </CardContent>
          </Card>

          {/* Topics Filter Card */}
          <Card className="relative">
            <CardContent className="p-4">
              <Flex alignItems="center" className="gap-2 mb-4">
                <Filter className="h-5 w-5 text-gray-500" />
                <Title>Topics</Title>
              </Flex>
              <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
                <Button
                  variant={selectedTopic === "" ? "primary" : "secondary"}
                  className="justify-start text-sm md:text-base"
                  onClick={() => setSelectedTopic("")}
                >
                  All Topics
                </Button>
                {topics.map((topic) => (
                  <Button
                    key={topic}
                    variant={selectedTopic === topic ? "primary" : "secondary"}
                    className="justify-start text-sm md:text-base"
                    onClick={() => setSelectedTopic(topic)}
                    color={topicColors[topic]}
                  >
                    {topic}
                  </Button>
                ))}
              </div>
            </CardContent>
          </Card>
        </Col>

        {/* Main Content - Full width on mobile, 9 columns on desktop */}
        <Col numColSpan={1} numColSpanMd={9}>
          {/* New Post Input */}
          <Card className="relative mb-4 md:mb-6">
            <CardContent className="p-4">
              <form onSubmit={handleNewPostSubmit}>
                <div className="space-y-4">
                  <TextInput
                    placeholder="Post title..."
                    value={newPostTitle}
                    onChange={(e) => setNewPostTitle(e.target.value)}
                    required={true}
                    className="w-full"
                  />
                  <Textarea
                    placeholder="Share an insight or ask a question..."
                    value={newPostContent}
                    onChange={(e) => setNewPostContent(e.target.value)}
                    required={true}
                    className="w-full resize-y"
                    rows={2}
                  />
                  <Flex justifyContent="between" alignItems="center">
                    <div className="w-48">
                      <Select
                        value={newPostTopic}
                        onValueChange={setNewPostTopic}
                        icon={Tag}
                        placeholder="Select a topic"
                        required={true}
                      >
                        {topics.map((topic) => (
                          <SelectItem key={topic} value={topic}>
                            {topic}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                    <Button type="submit" icon={Send} className="shrink-0">
                      Share Post
                    </Button>
                  </Flex>
                </div>
              </form>
            </CardContent>
          </Card>

          {/* Sort Tabs - Scrollable on mobile */}
          <div className="overflow-x-auto mb-2">
            <TabGroup>
              <TabList variant="solid" className="w-full min-w-max p-2">
                <Tab
                  icon={Clock}
                  onClick={() => handleSortChange("recency", "most")}
                >
                  Recent
                </Tab>
                <Tab
                  icon={TrendingUp}
                  onClick={() => handleSortChange("activity", "most")}
                >
                  Active
                </Tab>
                <Tab
                  icon={ThumbsUp}
                  onClick={() => handleSortChange("votes", "most")}
                >
                  Top
                </Tab>
              </TabList>
            </TabGroup>
          </div>

          {/* Loading State */}
          {loading && (
            <Card className="relative">
              <CardContent className="p-4 text-center">
                <Title>Loading posts...</Title>
              </CardContent>
            </Card>
          )}

          {/* Error State */}
          {error && (
            <Card className="relative">
              <CardContent className="p-4 text-center">
                <Title className="text-red-500">{error}</Title>
                <Button
                  variant="light"
                  className="mt-2"
                  onClick={() => fetchSubcooler(SUBCOOLER_ID)}
                >
                  Try Again
                </Button>
              </CardContent>
            </Card>
          )}

          {/* Posts List */}
          {!loading && !error && paginatedPosts.length === 0 && (
            <Card className="relative">
              <CardContent className="p-4 text-center">
                <Title>No posts found</Title>
              </CardContent>
            </Card>
          )}

          {/* Posts List */}
          {!loading && !error && (
            <div className="space-y-4">
              {paginatedPosts.map((post) => (
                <div key={post.data_id}>
                  <Card className="relative hover:bg-gray-50 transition-colors">
                    <CardContent className="p-4">
                      {/* Responsive Layout - Mobile stack, desktop side-by-side */}
                      <div className="flex flex-col md:flex-row gap-4">
                        {/* Vote Column - Horizontal and left-justified on mobile, side column on desktop */}
                        <div className="flex justify-start gap-1 md:w-auto w-full md:self-start md:mt-1">
                          <Button
                            variant="light"
                            size="xs"
                            icon={ArrowUpIcon}
                            color={
                              votedPosts[post.data_id] === "up"
                                ? "green"
                                : "gray"
                            }
                            className={`transition-transform ${
                              flashVotes[post.data_id] === "up"
                                ? "scale-125"
                                : ""
                            }`}
                            onClick={() => handleVote(post.data_id, "up")}
                          />
                          <Text
                            className="font-bold min-w-[1.5rem] text-center"
                            color={
                              votedPosts[post.data_id] === "up"
                                ? "green"
                                : votedPosts[post.data_id] === "down"
                                ? "red"
                                : "gray"
                            }
                          >
                            {post.upvotes - post.downvotes}
                          </Text>
                          <Button
                            variant="light"
                            size="xs"
                            icon={ArrowDownIcon}
                            color={
                              votedPosts[post.data_id] === "down"
                                ? "red"
                                : "gray"
                            }
                            className={`transition-transform ${
                              flashVotes[post.data_id] === "down"
                                ? "scale-125"
                                : ""
                            }`}
                            onClick={() => handleVote(post.data_id, "down")}
                          />
                        </div>

                        {/* Content Column - Takes remaining width */}
                        <div className="flex-1">
                          <Link
                            to={`/post/${post.data_id}`}
                            className="block group"
                          >
                            <Title className="text-base md:text-lg mb-2 group-hover:text-blue-600 transition-colors">
                              {post.title}
                            </Title>
                          </Link>

                          {/* Topic Badge */}
                          <div className="overflow-x-auto">
                            <Flex className="gap-1 mb-3 min-w-max justify-start">
                              {post.topic && (
                                <Badge
                                  color={topicColors[post.topic] || "gray"}
                                  size="sm"
                                  className="cursor-pointer hover:opacity-80 transition-colors whitespace-nowrap"
                                  onClick={() => setSelectedTopic(post.topic)}
                                >
                                  {post.topic}
                                </Badge>
                              )}
                            </Flex>
                          </div>

                          <Flex
                            justifyContent="between"
                            alignItems="center"
                            className="flex-col md:flex-row gap-2 md:gap-0"
                          >
                            <Flex alignItems="center" className="gap-2">
                              <Text color="gray" className="text-sm">
                                {userData?.profile?.user_id === post.user_id
                                  ? "You"
                                  : `${post.first_name} ${post.last_name}`}
                              </Text>
                              <Text color="gray">·</Text>
                              <Text color="gray" className="text-sm mr-6">
                                {formatDate(post.created_date)}
                              </Text>
                            </Flex>
                            <Button
                              variant="light"
                              size="xs"
                              icon={MessageSquare}
                              className="gap-2 w-full md:w-auto"
                              onClick={() => {
                                window.location.href = `/post/${post.data_id}`;
                              }}
                            >
                              {post.comment_count || 0} Comments
                            </Button>
                          </Flex>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div>
          )}

          {/* Pagination - Scrollable on mobile */}
          {!loading && !error && totalPages > 1 && (
            <Card className="relative mt-4 md:mt-6 overflow-x-auto">
              <CardContent className="p-4">
                <Flex justifyContent="center" className="gap-2 min-w-max">
                  <Button
                    variant="secondary"
                    icon={ChevronLeft}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    size="sm"
                    className="md:size-md"
                  >
                    Previous
                  </Button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <Button
                        key={page}
                        variant={currentPage === page ? "primary" : "secondary"}
                        onClick={() => handlePageChange(page)}
                        size="sm"
                        className="md:size-md"
                      >
                        {page}
                      </Button>
                    )
                  )}
                  <Button
                    variant="secondary"
                    icon={ChevronRight}
                    iconPosition="right"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    size="sm"
                    className="md:size-md"
                  >
                    Next
                  </Button>
                </Flex>
              </CardContent>
            </Card>
          )}
        </Col>
      </Grid>
    </div>
  );
}
