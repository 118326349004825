import React, { useState, useContext, useEffect } from "react";
import { Card, CardHeader } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Edit, Plus, Trash2, X } from "lucide-react";
import { Switch } from "components/ui/switch";
import { Label } from "components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { ContactsContext } from "contexts/ContactsContext";
import { AppContext } from "contexts/AppContext";

const ContactForm = ({
  onSave,
  onDelete,
  initialData = null,
  existingContacts = [],
  isLoading = false 
}) => {
  const [formData, setFormData] = useState(() => {
    if (initialData) {
      return {
        ...initialData,
        contact_name:
          typeof initialData.contact_name === "string"
            ? JSON.parse(initialData.contact_name)
            : initialData.contact_name,
        contact_info:
          typeof initialData.contact_info === "string"
            ? JSON.parse(initialData.contact_info)
            : initialData.contact_info,
        primary_contact_info:
          typeof initialData.primary_contact_info === "string"
            ? JSON.parse(initialData.primary_contact_info)
            : initialData.primary_contact_info,
        contact_notes: initialData.contact_notes || "",
      };
    }
    return {
      is_primary: existingContacts.length === 0,
      digital_champion: false,
      contact_name: {
        contact_title: "",
        contact_first_name: "",
        contact_last_name: "",
        contact_suffix: "",
      },
      contact_info: {
        contact_email: [""],
        contact_phone: [""],
      },
      primary_contact_info: {
        contact_email: "",
        contact_phone: "",
      },
      contact_role: "",
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const addField = (type) => {
    setFormData({
      ...formData,
      contact_info: {
        ...formData.contact_info,
        [type]: [...formData.contact_info[type], ""],
      },
    });
  };

  const updateField = (type, index, value) => {
    const newInfo = { ...formData.contact_info };
    newInfo[type][index] = value;

    setFormData({
      ...formData,
      contact_info: newInfo,
      primary_contact_info: {
        ...formData.primary_contact_info,
        [type]:
          newInfo[type].length === 1
            ? value
            : formData.primary_contact_info[type],
      },
    });
  };

  const setPrimaryInfo = (type, value) => {
    setFormData({
      ...formData,
      primary_contact_info: {
        ...formData.primary_contact_info,
        [type]: value,
      },
    });
  };

  const removeField = (type, index) => {
    const newInfo = { ...formData.contact_info };
    newInfo[type] = newInfo[type].filter((_, i) => i !== index);

    setFormData({
      ...formData,
      contact_info: newInfo,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <Input
          placeholder="First Name"
          value={formData.contact_name.contact_first_name}
          onChange={(e) =>
            setFormData({
              ...formData,
              contact_name: {
                ...formData.contact_name,
                contact_first_name: e.target.value,
              },
            })
          }
        />
        <Input
          placeholder="Last Name"
          value={formData.contact_name.contact_last_name}
          onChange={(e) =>
            setFormData({
              ...formData,
              contact_name: {
                ...formData.contact_name,
                contact_last_name: e.target.value,
              },
            })
          }
        />
      </div>

      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-4">
          <Input
            placeholder="Role"
            value={formData.contact_role}
            onChange={(e) =>
              setFormData({ ...formData, contact_role: e.target.value })
            }
          />
        </div>
        <div className="col-span-1">
          <Select
            value={formData.contact_name.contact_title || ""}
            onValueChange={(value) =>
              setFormData({
                ...formData,
                contact_name: {
                  ...formData.contact_name,
                  contact_title: value,
                },
              })
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Title" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Mr">Mr</SelectItem>
              <SelectItem value="Ms">Ms</SelectItem>
              <SelectItem value="Mrs">Mrs</SelectItem>
              <SelectItem value="Dr">Dr</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="col-span-1">
          <Select
            value={formData.contact_name.contact_suffix || ""}
            onValueChange={(value) =>
              setFormData({
                ...formData,
                contact_name: {
                  ...formData.contact_name,
                  contact_suffix: value,
                },
              })
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Suffix" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Jr.">Jr.</SelectItem>
              <SelectItem value="Sr.">Sr.</SelectItem>
              <SelectItem value="III">III</SelectItem>
              <SelectItem value="IV">IV</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium">Email Addresses</span>
          <Button
            size="sm"
            variant="ghost"
            type="button"
            onClick={() => addField("contact_email")}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
        {formData.contact_info.contact_email.map((email, index) => (
          <div key={index} className="flex gap-2 items-center">
            <Input
              value={email}
              onChange={(e) =>
                updateField("contact_email", index, e.target.value)
              }
              placeholder="Email"
              className="flex-1"
            />
            {formData.contact_info.contact_email.length > 1 && (
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  name="primary_email"
                  checked={
                    formData.primary_contact_info.contact_email === email
                  }
                  onChange={() => setPrimaryInfo("contact_email", email)}
                  className="w-4 h-4"
                />
                <span className="text-sm">Primary</span>
              </div>
            )}
            <Button
              size="sm"
              variant="ghost"
              type="button"
              onClick={() => removeField("contact_email", index)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium">Phone Numbers</span>
          <Button
            size="sm"
            variant="ghost"
            type="button"
            onClick={() => addField("contact_phone")}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
        {formData.contact_info.contact_phone.map((phone, index) => (
          <div key={index} className="flex gap-2 items-center">
            <Input
              value={phone}
              onChange={(e) =>
                updateField("contact_phone", index, e.target.value)
              }
              placeholder="Phone"
              className="flex-1"
            />
            {formData.contact_info.contact_phone.length > 1 && (
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  name="primary_phone"
                  checked={
                    formData.primary_contact_info.contact_phone === phone
                  }
                  onChange={() => setPrimaryInfo("contact_phone", phone)}
                  className="w-4 h-4"
                />
                <span className="text-sm">Primary</span>
              </div>
            )}
            <Button
              size="sm"
              variant="ghost"
              type="button"
              onClick={() => removeField("contact_phone", index)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>

      <div className="space-y-2">
        <Label htmlFor="contact_notes" className="text-sm font-medium">
          Notes
        </Label>
        <textarea
          id="contact_notes"
          value={formData.contact_notes}
          onChange={(e) =>
            setFormData({ ...formData, contact_notes: e.target.value })
          }
          placeholder="Add any notes about this contact..."
          className="w-full min-h-[80px] p-2 border rounded-md text-sm placeholder:text-sm"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center space-x-2">
          <Switch
            id="is-primary"
            checked={formData.is_primary}
            onCheckedChange={(checked) =>
              setFormData({ ...formData, is_primary: checked })
            }
            disabled={existingContacts.length === 0}
            className="data-[state=checked]:bg-black data-[state=unchecked]:bg-gray-200 disabled:opacity-100"
          />
          <Label htmlFor="is-primary">Primary Contact</Label>
        </div>

        <div className="flex items-center space-x-2">
          <Switch
            id="digital-champion"
            checked={formData.digital_champion}
            onCheckedChange={(checked) =>
              setFormData({ ...formData, digital_champion: checked })
            }
          />
          <Label htmlFor="digital-champion">Digital Champion</Label>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {initialData && (
          <Button
            type="button"
            variant="outline"
            onClick={() => onDelete(initialData.contact_id)}
            className="text-red-500 hover:text-red-700"
            disabled={isLoading}
          >
            Delete Contact
          </Button>
        )}
        <Button type="submit" className={initialData ? "" : "col-span-2"} disabled={isLoading}>
          {initialData ? "Update Contact" : "Create Contact"}
        </Button>
      </div>
    </form>
  );
};

export function ContactDetails() {
  const { fetchContacts, createContact, updateContact, changeContactStatus } =
    useContext(ContactsContext);
  const { custcd } = useContext(AppContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const loadContacts = async () => {
      const fetchedContacts = await fetchContacts(custcd);
      setContacts(fetchedContacts);
    };

    loadContacts();
  }, [fetchContacts]);

  const handleSave = async (formData) => {
    setIsLoading(true); 
    try {
      if (editingContact) {
        await updateContact({
          ...formData,
          contact_id: editingContact.contact_id,
        });
      } else {
        await createContact(formData);
      }
      setIsDialogOpen(false);
      setEditingContact(null);
      // Fetch the contacts again after save operation
      const fetchedContacts = await fetchContacts(custcd);
      setContacts(fetchedContacts);
    } catch (error) {
      console.error("Error saving contact:", error);
    } finally {
      setIsLoading(false); // Reset loading state whether operation succeeds or fails
    }
  };

  const handleEdit = (contact) => {
    setEditingContact(contact);
    setIsDialogOpen(true);
  };

  const handleAdd = () => {
    setEditingContact(null);
    setIsDialogOpen(true);
  };

  const handleDelete = async (contactId) => {
    const contactToDelete = contacts.find(
      (contact) => contact.contact_id === contactId
    );

    if (contactToDelete?.is_primary) {
      window.confirm(
        "This is a primary contact. Please set another contact as primary before deleting this one."
      );
      return;
    }

    setIsLoading(true); 
    try {
      await changeContactStatus(contactId, "inactive");
      setIsDialogOpen(false);
      setEditingContact(null);
      // Fetch the contacts again after delete operation
      const fetchedContacts = await fetchContacts(custcd);
      setContacts(fetchedContacts);
    } catch (error) {
      console.error("Error deleting contact:", error);
    } finally {
      setIsLoading(false); // Reset loading state whether operation succeeds or fails
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Contacts</h2>
          <Button onClick={handleAdd} disabled={isLoading}>Add Contact</Button>
        </div>

        <div className="space-y-4 mt-4">
          {contacts && contacts.length > 0 ? (
            contacts.map((contact) => {
              const contactName =
                typeof contact.contact_name === "string"
                  ? JSON.parse(contact.contact_name)
                  : contact.contact_name;
              const primaryInfo =
                typeof contact.primary_contact_info === "string"
                  ? JSON.parse(contact.primary_contact_info)
                  : contact.primary_contact_info;

              return (
                <div
                  key={contact.contact_id}
                  className="p-4 border rounded-lg flex justify-between items-center"
                >
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">
                        {contactName.contact_first_name}{" "}
                        {contactName.contact_last_name}
                      </span>
                      {contact.is_primary && (
                        <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                          Primary
                        </span>
                      )}
                    </div>
                    <div className="text-sm text-gray-600">
                      {contact.contact_role}
                    </div>
                    <div className="text-sm">
                      {primaryInfo.contact_email && (
                        <div>Email: {primaryInfo.contact_email}</div>
                      )}
                      {primaryInfo.contact_phone && (
                        <div>Phone: {primaryInfo.contact_phone}</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Button variant="ghost" onClick={() => handleEdit(contact)}>
                      <Edit className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center p-4 text-gray-500">
              No contacts found. Add your first contact.
            </div>
          )}
        </div>

        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
            <DialogHeader>
              <DialogTitle>
                {editingContact ? "Edit Contact" : "Add New Contact"}
              </DialogTitle>
            </DialogHeader>
            <ContactForm
              onSave={handleSave}
              onDelete={handleDelete}
              initialData={editingContact}
              existingContacts={contacts || []}
              isLoading={isLoading}
            />
          </DialogContent>
        </Dialog>
      </CardHeader>
    </Card>
  );
}

export default ContactDetails;
