import { useState, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../components/ui/alert-dialog";
import { LeadGenContext } from "contexts/LeadGenContext";

export function AddLeadModal({ isOpen, onClose }) {
  const { addManualLead } = useContext(LeadGenContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [matchDialogOpen, setMatchDialogOpen] = useState(false);

  const [leadData, setLeadData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    website: "",
    source: "Manual Add", // Hardcoded source
    description: "",
    city: "",
    statecd: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Extract city and state from address if not provided separately
      let city = leadData.city;
      let statecd = leadData.statecd;

      if ((!city || !statecd) && leadData.address) {
        const addressParts = leadData.address.split(",");
        if (addressParts.length >= 2) {
          // Try to extract city and state from address
          const cityStatePart = addressParts[addressParts.length - 1].trim();
          const cityStateMatch = cityStatePart.match(/([^,]+)\s+([A-Z]{2})/);

          if (cityStateMatch) {
            if (!city) city = cityStateMatch[1].trim();
            if (!statecd) statecd = cityStateMatch[2].trim();
          }
        }
      }

      // Format website if provided to ensure it has http:// prefix
      let website = leadData.website;
      if (website && !website.match(/^https?:\/\//)) {
        website = `https://${website}`;
      }

      // Add the lead
      await addManualLead({
        ...leadData,
        website,
        city,
        statecd,
      });

      // Reset form and close modal
      setLeadData({
        name: "",
        email: "",
        phone: "",
        address: "",
        website: "",
        source: "Manual Add",
        description: "",
        city: "",
        statecd: "",
      });

      onClose();
    } catch (err) {
      console.error("Error adding lead:", err);
      // Handle error - could show an error message
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeadData((prev) => ({ ...prev, [name]: value }));
  };

  const handleQualify = () => {
    // Show the dialog with hard-coded message
    setMatchDialogOpen(true);
  };

  // Check if all required fields are filled
  // const canQualify = leadData.name && leadData.phone && leadData.address;
  const canQualify = leadData.name;

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[525px]">
          <DialogHeader>
            <DialogTitle>Add New Lead</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  value={leadData.name}
                  onChange={handleChange}
                  className="col-span-3"
                  required
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="email" className="text-right">
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={leadData.email}
                  onChange={handleChange}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="phone" className="text-right">
                  Phone
                </Label>
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={leadData.phone}
                  onChange={handleChange}
                  className="col-span-3"
                  // required
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="address" className="text-right">
                  Address
                </Label>
                <Input
                  id="address"
                  name="address"
                  value={leadData.address}
                  onChange={handleChange}
                  className="col-span-3"
                  // required
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="website" className="text-right">
                  Website
                </Label>
                <Input
                  id="website"
                  name="website"
                  value={leadData.website}
                  onChange={handleChange}
                  className="col-span-3"
                  // placeholder="example.com (optional)"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="description" className="text-right">
                  Description
                </Label>
                <Textarea
                  id="description"
                  name="description"
                  value={leadData.description}
                  onChange={handleChange}
                  className="col-span-3"
                  rows={3}
                />
              </div>
            </div>
            <DialogFooter className="flex justify-between">
              <Button
                type="button"
                variant="outline"
                onClick={handleQualify}
                disabled={!canQualify}
              >
                Qualify Lead
              </Button>
              <Button type="submit" disabled={isSubmitting || !canQualify}>
                {isSubmitting ? "Adding..." : "Add Lead"}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Match Found Dialog */}
      <AlertDialog open={matchDialogOpen} onOpenChange={setMatchDialogOpen}>
        <AlertDialogContent className="max-w-md">
          <AlertDialogHeader>
            <AlertDialogTitle>Qualification Results</AlertDialogTitle>
          </AlertDialogHeader>
          <div className="py-4">
            <p className="mb-4">
              Based on the name, phone, and address provided, we found the
              following matches:
            </p>

            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-base mb-2">SRS Customers</h3>
                <div className="bg-gray-50 p-3 rounded border border-gray-200">
                  <p className="font-medium">Green Roofers</p>
                  <p className="text-gray-600 text-sm">
                    123 Oak Street, Springfield, IL 62704
                  </p>
                </div>
              </div>

              <div>
                <h3 className="font-semibold text-base mb-2">Cosailor Leads</h3>
                <div className="bg-gray-50 p-3 rounded border border-gray-200 text-gray-500 italic">
                  <p>None found</p>
                </div>
              </div>
            </div>
          </div>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setMatchDialogOpen(false)}>
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
