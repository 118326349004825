import React, { useContext, useState } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card'
import { InsightsList } from './companyinsights-component/insights-list';
import { DataContext } from 'contexts/DataContext';
import { Badge } from 'components/catalyst/badge';

const colorMapBackground = {
  "Order Inactivity": "orange",
  "Net New Customer": "amber",
  "New Customer Promo": "cyan",
  "First invoice anniversary": "purple",
  "First Invoice Anniversary": "purple",
  "Open AR Payments": "pink",
  "YoY Sales Drop": "red",
  "Reduced Invoice Activity": "orange",
  "Customer DRP dropped": "rose",
  "Customer DRP Dropped": "rose",
  "Eligible for new customer promo": "sky",
  "Eligible For New Customer Promo": "sky",
  "Customer DRP below expected": "yellow",
  "Customer DRP Below Expected": "yellow",
  "First SO Invoiced": "teal",
  "First Digital SO Invoiced": "indigo",
  "Limit Increased": "green",
  "Residential": "blue",
  "No Orders Found": "fuchsia",
  "No Repeat Purchases": "orange",
  "60 Days Inactivity": "red",
  "Low Sales": "amber",
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || 'gray'; // Fallback to gray if not found
};

const pillOrder = [
  'YoY Sales Drop',
  'Reduced Invoice Activity',
  'Open AR Payments',
  'Customer DRP Dropped',
  'Customer DRP Below Expected',
  'Limit Increased',
  'Commercial',
  'Residential',
  '60 Days Inactivity',
  'No Orders Found',
  'No Repeat Purchases',
  'Low Sales',
  'Eligible For New Customer Promo',
  'First Digital SO Invoiced',
  'First SO Invoiced',
  'First Invoice Anniversary',
  'Order Inactivity',
  'Net New Customer',
  'New Customer Promo'
];

// Function to sort pills based on the defined order
const sortPills = (pills) => {
  if (!pills || !Array.isArray(pills) || pills.length === 0) return [];
  
  // Create a copy to avoid mutating the original array
  return [...pills].sort((a, b) => {
    // Find the index of each pill in the order array
    const indexA = pillOrder.indexOf(a);
    const indexB = pillOrder.indexOf(b);
    
    // If a pill isn't found in the order, give it a high index so it appears last
    const valueA = indexA === -1 ? 999 : indexA;
    const valueB = indexB === -1 ? 999 : indexB;
    
    // Sort by index
    return valueA - valueB;
  });
};

export function Insights() {
  const { customerInsights, customerProfilePage } = useContext(DataContext);
  const [showSnoozed, setShowSnoozed] = useState(false);

  const toggleSnoozedInsights = () => {
    setShowSnoozed(!showSnoozed);
  };

  const hasSnoozedInsights = Array.isArray(customerInsights) && customerInsights.some(item => item.status === 'snoozed');

  const filteredInsights = Array.isArray(customerInsights)
    ? (showSnoozed
      ? customerInsights
      : customerInsights.filter(item => item.status !== 'snoozed'))
    : [];

  const noInsights = filteredInsights.length === 0;

  return (
    <Card className="mt-4 sm:mt-0">
      <CardHeader>
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-lg font-semibold">Insights</h2>
          {hasSnoozedInsights && (
            <div
              onClick={toggleSnoozedInsights}
              className="text-sm text-gray-500 cursor-pointer underline hover:text-blue-500 ml-4 whitespace-nowrap"
            >
              {showSnoozed ? 'Hide Snoozed Insights' : 'View Snoozed Insights'}
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent>
        {noInsights ? (
          <div className="flex flex-wrap gap-2">
            {sortPills(customerProfilePage?.insight_pills)?.map((insight, index) => (
              <Badge key={index} color={getBadgeColor(insight)} variant="outline">
                {insight}
              </Badge>
            ))}
          </div>
        ) : (
          <InsightsList items={filteredInsights} />
        )}
      </CardContent>
    </Card>
  );
}
