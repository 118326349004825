"use client";

import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { X, Plus, Minus } from "lucide-react";
import { toast } from "sonner";
import { AppContext } from "contexts/AppContext";
import { TouchpointContext } from "contexts/TouchpointContext";
import { ContactsContext } from "contexts/ContactsContext";
import { v4 as uuidv4 } from "uuid";

export default function SendEmailDialog({
  isOpen,
  onClose,
  emailContent,
  custcd,
  custname,
  onSendSuccess,
}) {
  const { logEvent, sendEmail, salesAgentCD, oktaId } = useContext(AppContext);
  const { addTouchpoint } = useContext(TouchpointContext);
  const { fetchContacts } = useContext(ContactsContext);

  const [contacts, setContacts] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [showCcBcc, setShowCcBcc] = useState(false);
  const [suggestions, setSuggestions] = useState({ to: [], cc: [], bcc: [] });
  const [cursorPosition, setCursorPosition] = useState({
    to: 0,
    cc: 0,
    bcc: 0,
  });
  const inputRefs = useRef({ to: null, cc: null, bcc: null });
  const suggestionsRef = useRef({ to: null, cc: null, bcc: null });

  useEffect(() => {
    const getContacts = async () => {
      try {
        if (custcd) {
          const fetchedContacts = await fetchContacts(custcd);
          setContacts(fetchedContacts);

          // Set primary contact email if available
          if (fetchedContacts && fetchedContacts.length > 0) {
            const primaryContact = fetchedContacts[0];
            if (primaryContact?.primary_contact_info) {
              const contactInfo = JSON.parse(
                primaryContact.primary_contact_info
              );
              if (contactInfo?.contact_email) {
                setTo(contactInfo.contact_email);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    getContacts();
  }, [custcd, fetchContacts]);

  const handleEmailChange = (type, value) => {
    const stateUpdater = type === "to" ? setTo : type === "cc" ? setCc : setBcc;
    stateUpdater(value);
    if (type === "to") {
      setShowSuggestions(true);
    }
  };

  const handleToChange = (e) => {
    setTo(e.target.value);
    setShowSuggestions(true);
  };

  const handleContactSelect = (contact) => {
    try {
      if (contact?.primary_contact_info) {
        const contactInfo = JSON.parse(contact.primary_contact_info);
        if (contactInfo?.contact_email) {
          setTo((prevTo) =>
            prevTo
              ? `${prevTo}, ${contactInfo.contact_email}`
              : contactInfo.contact_email
          );
          setShowSuggestions(false);
        }
      }
    } catch (error) {
      console.error("Error selecting contact:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".contacts-dropdown")) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        ...Array.from(e.target.files),
      ]);
    }
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const toggleCcBcc = () => {
    setShowCcBcc((prev) => !prev);
  };

  const parseEmails = (input) => {
    const emailString = input.replace(/;/g, ',');
    const regex = /([^,\s]+@[^,\s]+\.[^,\s]+)/g;
    return Array.from(emailString.matchAll(regex), (match) => match[1]);
  };

  const handleSend = async () => {
    const toEmails = parseEmails(to);
    const ccEmails = parseEmails(cc);
    const bccEmails = parseEmails(bcc);

    const missingFields = [];
    if (!to) missingFields.push("email address");
    if (!subject) missingFields.push("subject");
    if (!emailContent.innerHTML) missingFields.push("email content");

    if (missingFields.length > 0) {
      setErrorMessage(`Please add ${missingFields.join(", ")}`);
      return;
    }
    setErrorMessage("");

    const formattedAttachments = attachments.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          const binaryString = e.target.result;
          const base64String = btoa(binaryString);
          resolve({
            file_content: base64String,
            file_name: file.name,
            content_type: file.type,
          });
        };
        reader.readAsBinaryString(file);
      });
    });

    const formattedAttachmentsArray = await Promise.all(formattedAttachments);

    try {
      await sendEmail(
        toEmails,
        ccEmails,
        bccEmails,
        subject,
        emailContent.innerHTML,
        formattedAttachmentsArray,
        custcd
      );

      const currentDate = new Date();
      const formattedDate =
        currentDate.toISOString().slice(0, 19) +
        "." +
        currentDate.getMilliseconds().toString().padStart(3, "0") +
        "000";

      // Only create touchpoint if custcd exists
    if (custcd) {
      const metadata = {
        tp_type: "email",
        subject: subject,
        ccs: ccEmails.length > 0 ? ccEmails : null,
        bccs: bccEmails.length > 0 ? bccEmails : null,
        num_attachments: attachments.length > 0 ? attachments.length : null,
        senders: null,
        recipients: toEmails,
        content: emailContent.innerHTML,
        disposition: null,
        notes: null,
        followup: null,
      };

      const touchpointData = {
        touchpointsid: uuidv4(),
        custcd,
        custname,
        tp_type: "email",
        summary: "Email Sent",
        touchpoint_date: formattedDate,
        system_generated: false,
        visible: true,
        bookmarked: false,
        status: "completed",
        impact: 0,
        response_status: false,
        created_date: formattedDate,
        metadata: metadata,
      };

      await addTouchpoint(touchpointData);
    }

      toast.success("Email Sent Successfully", {
        description: `Sent to: ${toEmails.join(', ')}${
          ccEmails.length > 0 ? ` CC: ${ccEmails.join(', ')}` : ''
        }${
          bccEmails.length > 0 ? ` BCC: ${bccEmails.join(', ')}` : ''
        }`,
        duration: Infinity,
        closeButton: true,
        // action: {
        //   label: 'Dismiss',
        //   onClick: () => toast.dismiss()
        // }
      });

      // Reset dialog form fields directly - should happen regardless
      setTo("");
      setSubject("");
      setCc("");
      setBcc("");
      setAttachments([]);
      setErrorMessage("");
      setShowCcBcc(false);

      // Call the onSendSuccess callback to reset the parent form - only if it exists
      if (onSendSuccess) {
        onSendSuccess();
      }

      onClose();
    } catch (error) {
      toast.error("Error sending email");
    }
  };

  const renderEmailFields = (type, value, label) => (
    <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-2 sm:gap-4">
      <Label htmlFor={type} className="sm:text-right mt-3">
        {label}:
      </Label>
      <div className="col-span-1 sm:col-span-3 space-y-2">
        <div className="flex items-center gap-2">
          <div className="relative flex-grow contacts-dropdown">
            <Input
              id={type}
              value={value}
              onChange={
                type === "to"
                  ? handleToChange
                  : (e) => handleEmailChange(type, e.target.value)
              }
              onFocus={() => type === "to" && setShowSuggestions(true)}
              className="flex-grow"
              placeholder={`Enter email(s)`}
            />
            {type === "to" &&
              showSuggestions &&
              Array.isArray(contacts) &&
              contacts.length > 0 && (
                <div className="absolute z-10 w-full mt-1 rounded-md border shadow-md overflow-hidden bg-white dark:bg-gray-800">
                  <div className="py-1 px-1 bg-white dark:bg-gray-800">
                    {contacts.map((contact) => {
                      try {
                        if (
                          !contact?.contact_name ||
                          !contact?.primary_contact_info
                        ) {
                          return null;
                        }
                        const contactName = JSON.parse(contact.contact_name);
                        const contactInfo = JSON.parse(
                          contact.primary_contact_info
                        );
                        if (!contactName || !contactInfo?.contact_email) {
                          return null;
                        }

                        return (
                          <div
                            key={contact.contact_id}
                            onClick={() => handleContactSelect(contact)}
                            className="flex flex-col gap-1 px-2 py-1.5 rounded-sm cursor-pointer text-sm hover:bg-accent hover:text-accent-foreground"
                          >
                            <div className="font-medium flex items-center justify-between">
                              {`${contactName.contact_first_name} ${contactName.contact_last_name}`}
                              {contact === contacts[0] && (
                                <span className="text-xs text-muted-foreground ml-2">
                                  (Primary)
                                </span>
                              )}
                            </div>
                            <div className="text-xs text-muted-foreground">
                              {contactInfo.contact_email}
                            </div>
                          </div>
                        );
                      } catch (error) {
                        console.error("Error rendering contact:", error);
                        return null;
                      }
                    })}
                  </div>
                </div>
              )}
          </div>
          {type === "to" && (
            <Button
              type="button"
              variant="outline"
              size="icon"
              onClick={toggleCcBcc}
            >
              {showCcBcc ? (
                <Minus className="h-4 w-4" />
              ) : (
                <Plus className="h-4 w-4" />
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>Send Email</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {renderEmailFields("to", to, "To")}
          {showCcBcc && (
            <>
              {renderEmailFields("cc", cc, "CC")}
              {renderEmailFields("bcc", bcc, "BCC")}
            </>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-4 items-center gap-2 sm:gap-4">
            <Label htmlFor="subject" className="sm:text-right">
              Subject:
            </Label>
            <Input
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="col-span-1 sm:col-span-3"
              placeholder="Enter subject"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-2 sm:gap-4">
            <Label htmlFor="content" className="sm:text-right sm:mt-2">
              Content:
            </Label>
            <div className="sm:col-span-3 w-full">
              <div
                className="text-sm mt-2"
                dangerouslySetInnerHTML={{
                  __html: emailContent?.innerHTML || "",
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-2 sm:gap-4">
            <Label htmlFor="attachments" className="sm:text-right sm:mt-2">
              Attachments:
            </Label>
            <div className="col-span-1 sm:col-span-3">
              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => document.getElementById("attachments").click()}
                >
                  Choose Files
                </Button>
                <Input
                  id="attachments"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                />
                {attachments.length > 0 && (
                  <span>{attachments.length} file(s) selected</span>
                )}
              </div>
              {attachments.length > 0 && (
                <ul className="list-disc pl-5 space-y-1 mt-2">
                  {attachments.map((file, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between text-sm"
                    >
                      <span className="truncate mr-2">{file.name}</span>
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeAttachment(index)}
                        className="h-6 w-6 p-0 text-destructive hover:text-destructive/90"
                      >
                        <X className="h-4 w-4" />
                        <span className="sr-only">Remove {file.name}</span>
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="text-red-600 text-sm mt-4">{errorMessage}</div>
        )}
        <DialogFooter>
          <Button type="submit" onClick={handleSend}>
            Send
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
