import React, { useContext, useState, useEffect } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { CreateVoice } from "components/action-cards-component/create-voice";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { Button } from "components/ui/button";
import { SearchAccountSwitch } from "components/touchpoint/components/SearchAccountSwitch";
import { TouchpointContext } from "contexts/TouchpointContext";
import { DataContext } from "contexts/DataContext";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";

const AddTouchpoint = ({ custcd, custname }) => {
  const { addTouchpoint } = useContext(TouchpointContext);
  const { customerProfilePage } = useContext(DataContext);
  
  // Initialize state
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [touchpointType, setTouchpointType] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [status, setStatus] = useState("completed");
  const [content, setContent] = useState("");
  const [transcript, setTranscript] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");

   // Detect home page
   const isHomePage = location.pathname === "/" 
  
  // Clear customer on home page and set on other pages
  useEffect(() => {
    // On home page, always clear the selection
    if (isHomePage) {
      setSelectedCustomer(null);
      return;
    }
    
    // On other pages, use customerProfilePage data if available
    if (customerProfilePage?.custcd) {
      setSelectedCustomer({
        custcd: customerProfilePage.custcd,
        custname: customerProfilePage.custname || ""
      });
    }
  }, [isHomePage, location.pathname, customerProfilePage]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedCustomer || !touchpointType || !content) {
      setErrorMessage("Please select a customer, touchpoint type, and add content.");
      return;
    }

    const touchpointDate = new Date(date);
    const formattedDate =
      touchpointDate.toISOString().slice(0, 19) +
      "." +
      touchpointDate.getMilliseconds().toString().padStart(3, "0") +
      "000"; 

    const createdDate = new Date();
    const formattedCreatedDate =
      createdDate.toISOString().slice(0, 19) +
      "." +
      createdDate.getMilliseconds().toString().padStart(3, "0") +
      "000";

    // Create metadata based on touchpoint type
    const metadata =
      touchpointType === "email"
        ? {
            tp_type: "email",
            subject: null,
            ccs: null,
            bccs: null,
            num_attachments: null,
            senders: null,
            recipients: null,
            content: content,
            disposition: null,
            notes: null,
            followup: null,
          }
        : touchpointType === "call"
        ? {
            tp_type: "call",
            participants: null,
            duration: null,
            num_participants: null,
            has_recording: null,
            disposition: null,
            content: content,
            notes: null,
            followup: null,
          }
        : touchpointType === "text"
        ? {
            tp_type: "text",
            senders: null,
            recipients: null,
            content: content,
            disposition: null,
            notes: null,
            followup: null,
          }
        : touchpointType === "in-person"
        ? {
            tp_type: "in-person",
            duration: null,
            num_participants: null,
            participants: null,
            location: null,
            disposition: null,
            content: content,
            notes: null,
            followup: null,
          }
        : {};

    const getSummary = (type) => {
      switch (type) {
        case "email": return "Email Sent";
        case "call": return "Call Made";
        case "text": return "SMS Sent";
        case "in-person": return "Meeting Scheduled";
        default: return "";
      }
    };

    const touchpointData = {
      touchpointsid: uuidv4(),
      custcd: selectedCustomer.custcd,
      custname: selectedCustomer.custname,
      tp_type: touchpointType,
      summary: getSummary(touchpointType),
      touchpoint_date: formattedDate,
      system_generated: false,
      visible: true,
      bookmarked: false,
      status: status,
      impact: 0,
      response_status: false,
      created_date: formattedCreatedDate,
      metadata: metadata,
    };

    try {
      await addTouchpoint(touchpointData);
      toast.success("Touchpoint added successfully!");
      
      // Reset form but maintain selected customer
      setTouchpointType("");
      setContent("");
      setErrorMessage("");
    } catch (err) {
      console.error("Error adding touchpoint:", err);
      toast.error("Failed to add touchpoint. Please try again.");
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add New Touchpoint</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="customer">Customer *</Label>
            <SearchAccountSwitch
              selectedAccount={selectedCustomer}
              setSelectedAccount={setSelectedCustomer}
            />
            {selectedCustomer && (
              <p className="text-sm text-muted-foreground mt-1">
                Selected Customer: {selectedCustomer.custname}
              </p>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
            <div className="space-y-2 w-full">
              <Label htmlFor="type">Type *</Label>
              <Select onValueChange={setTouchpointType} value={touchpointType}>
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="email">Email</SelectItem>
                  <SelectItem value="call">Phone</SelectItem>
                  <SelectItem value="text">SMS</SelectItem>
                  <SelectItem value="in-person">In-Person</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2 w-full">
              <Label htmlFor="timestamp">Date *</Label>
              <Input
                id="timestamp"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="space-y-2 w-full">
              <Label htmlFor="status">Status *</Label>
              <Select
                value={status}
                onValueChange={setStatus}
                defaultValue="completed"
              >
                <SelectTrigger>
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="completed">Completed</SelectItem>
                  <SelectItem value="scheduled">Scheduled</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="content">Content *</Label>
            <Tabs defaultValue="voice" className="w-full">
              <TabsList>
                <TabsTrigger value="voice">Voice Input</TabsTrigger>
                <TabsTrigger value="text">Text Input</TabsTrigger>
              </TabsList>
              <TabsContent value="text">
                <Textarea
                  id="content"
                  placeholder={
                    touchpointType === "email"
                      ? "Email body"
                      : "Touchpoint details"
                  }
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="mt-2"
                />
              </TabsContent>
              <TabsContent value="voice">
                <CreateVoice
                  title={title}
                  setTitle={setTitle}
                  content={content}
                  setContent={setContent}
                  selectedAccount={selectedCustomer}
                  setSelectedAccount={setSelectedCustomer}
                  setType={setTouchpointType}
                  setTranscript={setTranscript}
                  setIsRecording={setIsRecording}
                  setErrorMessage={setErrorMessage}
                />
              </TabsContent>
            </Tabs>
          </div>
          
          {errorMessage && (
            <div className="text-red-600 text-sm mt-2">
              {errorMessage}
            </div>
          )}
          
          <Button type="submit" className="w-full">
            Add Touchpoint
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTouchpoint;