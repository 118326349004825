import * as React from "react";
import { useState } from "react";
import {
  Mail,
  Phone,
  Star,
  MapPin,
  Globe,
  Sparkles,
  Target,
  ExternalLink,
  Info,
  Contact,
  ChevronRight,
  Building,
  User,
} from "lucide-react";

import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
  CardTitle,
} from "../../../components/ui/card";
import { Badge } from "../../../components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { EmailPopup } from "./email-popup";
import { ContactDialog } from "./contact-dialog";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../components/ui/dialog";

// Import local icons
import thumbtackIcon from "./icons/thumbtack.png";
import gafIcon from "./icons/gaf.png";
import srsIcon from "./icons/srs.png";

const certificationColors = {
  "President's Club Award": "bg-purple-100 text-purple-800 border-purple-200",
  "President's Award": "bg-purple-100 text-purple-800 border-purple-200",
  "Master Elite®": "bg-blue-100 text-blue-800 border-blue-200",
  "Master Elite": "bg-blue-100 text-blue-800 border-blue-200",
  "Certified Installer": "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  "Green Roofing Specialist":
    "bg-emerald-100 text-emerald-800 border-emerald-200",
  "Safety Certified": "bg-red-100 text-red-800 border-red-200",
  "Innovative Design Award": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Energy Efficiency Expert": "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Community Choice Award": "bg-pink-100 text-pink-800 border-pink-200",
  "Reliability Certified": "bg-cyan-100 text-cyan-800 border-cyan-200",
  "Sustainability Award": "bg-teal-100 text-teal-800 border-teal-200",
  "Emergency Response Certified":
    "bg-orange-100 text-orange-800 border-orange-200",
  "Customer Service Excellence": "bg-rose-100 text-rose-800 border-rose-200",
  "Historical Preservation Expert":
    "bg-amber-100 text-amber-800 border-amber-200",
  Certified: "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  "Craftsmanship Award": "bg-lime-100 text-lime-800 border-lime-200",
  "Solar Roofing Specialist": "bg-violet-100 text-violet-800 border-violet-200",
  "Energy Star Partner": "bg-sky-100 text-sky-800 border-sky-200",
  "Commercial Expert": "bg-blue-100 text-blue-800 border-blue-200",
};

const groupColors = {
  "Reroof - Local/Retail Restoration":
    "bg-orange-100 text-orange-800 border-orange-200",
  "General Contractor": "bg-sky-100 text-sky-800 border-sky-200",
  "Other Exteriors": "bg-teal-100 text-teal-800 border-teal-200",
  "New Construction - Builder": "bg-lime-100 text-lime-800 border-lime-200",
  "Latino Owned": "bg-amber-100 text-amber-800 border-amber-200",
  "Low Slope - Tier 2": "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Reroof - Storm/Insurance Restoration":
    "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Multifamily - Other": "bg-violet-100 text-violet-800 border-violet-200",
  "Top Cash Rewards": "bg-emerald-100 text-emerald-800 border-emerald-200",
  National: "bg-blue-100 text-blue-800 border-blue-200",
  "New Construction - Turnkey": "bg-green-100 text-green-800 border-green-200",
  "Low Slope - Tier 1": "bg-cyan-100 text-cyan-800 border-cyan-200",
  "Two-Step": "bg-rose-100 text-rose-800 border-rose-200",
  "Multifamily - Steep Slope":
    "bg-purple-100 text-purple-800 border-purple-200",
  "PRVT EQUITY": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Other Interiors": "bg-pink-100 text-pink-800 border-pink-200",
  "Latino Rewards": "bg-red-100 text-red-800 border-red-200",
  "ECA-NA": "bg-amber-100 text-amber-800 border-amber-200",
  "LMTLS-NA": "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  FRANCHISE: "bg-blue-100 text-blue-800 border-blue-200",
};

// SourceIcon component using local images
const SourceIcon = ({ source }) => {
  // Map sources to their respective image assets
  let iconSrc = null;
  let tooltipText = source || "Unknown Source";

  switch (source) {
    case "Thumbtack":
      iconSrc = thumbtackIcon;
      tooltipText = "Thumbtack";
      break;
    case "GAF":
      iconSrc = gafIcon;
      tooltipText = "GAF";
      break;
    case "Churned Customer":
    case "SRS-CLIENT":
      iconSrc = srsIcon;
      tooltipText = "SRS Distribution";
      break;
    default:
      // Return default icon for unknown sources
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="w-6 h-6 rounded-full bg-white flex items-center justify-center shadow-sm border border-gray-100">
                <Building className="w-4 h-4 text-gray-500" />
              </div>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p className="text-xs">{tooltipText}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
  }

  // For sources with image assets
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center shadow-sm border border-gray-100 overflow-hidden">
            <img
              src={iconSrc}
              alt={`${tooltipText} icon`}
              className="w-6 h-6 object-contain" // Increased from w-5 h-5
            />
          </div>
        </TooltipTrigger>
        <TooltipContent side="top">
          <p className="text-xs">{tooltipText}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export function ContractorCard({
  name,
  description,
  certifications = [],
  address,
  rating,
  reviewCount,
  phone,
  website,
  post_link,
  analysis,
  onClick,
  showActions = true,
  status,
  email,
  row_uuid,
  notes,
  source,
  custcd,
}) {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  // Check if this is a churned customer
  const isChurnedCustomer =
    source === "Churned Customer" || source === "SRS-CLIENT";

  // Customer ID for customer page link
  const customerId = custcd || row_uuid;

  const statusColors = {
    // Assigned Leads - Purple
    Prospective: "bg-purple-50 text-purple-700 border-purple-300",
    Churned: "bg-purple-50 text-purple-700 border-purple-300",

    // Actioned Leads - Orange/Red
    "Acknowledging Lead": "bg-orange-50 text-orange-700 border-orange-300",
    "First Attempt": "bg-orange-50 text-orange-700 border-orange-300",
    "Second Attempt": "bg-orange-50 text-orange-700 border-orange-300",
    "Third Attempt": "bg-orange-50 text-orange-700 border-orange-300",

    // Qualified Leads - Blue/Cyan
    "Engaged Prospect": "bg-blue-50 text-blue-700 border-blue-300",
    "Needs Assessment": "bg-blue-50 text-blue-700 border-blue-300",
    "Proposal or Quote": "bg-blue-50 text-blue-700 border-blue-300",
    "Advancing the Sale": "bg-blue-50 text-blue-700 border-blue-300",

    // Converted Leads - Green
    "Credit Application": "bg-green-50 text-green-700 border-green-300",
    Converted: "bg-green-50 text-green-700 border-green-300",
    "Reactivated Customer": "bg-green-50 text-green-700 border-green-300",

    // Other - Gray
    "Not a Good Lead": "bg-gray-50 text-gray-700 border-gray-400",
    "Already a Customer": "bg-gray-50 text-gray-700 border-gray-400",

    // Lost Lead - Red
    "Lost Lead": "bg-red-50 text-red-700 border-red-300",
  };

  const handleEmailClick = (e) => {
    e.stopPropagation();
    setIsEmailPopupOpen(true);
  };

  // Use certifications directly or default to empty array
  const certificationList = certifications || [];

  // Updated function to check if website/link is valid
  const isValidLink = (link) => {
    // Return false for empty objects, empty strings, or "No website"
    if (
      !link ||
      link === "No website" ||
      link === "" ||
      link === "{}" ||
      link === "{}"
    ) {
      return false;
    }

    // Additional check for object notation (sometimes returned as string)
    if (
      typeof link === "string" &&
      (link.trim() === "{}" || link.includes('"{}"'))
    ) {
      return false;
    }

    return true;
  };

  return (
    <Card
      className="w-full hover:shadow-lg transition-all duration-300 cursor-pointer h-[450px] flex flex-col transform hover:-translate-y-1 hover:shadow-xl"
      onClick={onClick}
    >
      <CardHeader className="pb-3 flex-none">
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="text-lg font-bold text-gray-800 line-clamp-1">
              {name}
            </CardTitle>
          </div>
          <SourceIcon source={source} />
        </div>
      </CardHeader>
      <CardContent className="pb-0 space-y-3 flex-1 overflow-hidden">
        {certificationList.length > 0 ? (
          <div className="flex flex-wrap gap-1 min-h-[32px] items-start">
            {certificationList.slice(0, 3).map((cert) => (
              <Badge
                key={cert}
                variant="secondary"
                className={`text-[10px] leading-none px-1.5 py-[2px] font-medium whitespace-nowrap h-[18px] flex items-center ${
                  certificationColors[cert] ||
                  groupColors[cert] ||
                  "bg-gray-100 text-gray-800"
                }`}
              >
                {cert}
              </Badge>
            ))}
            {certificationList.length > 3 && (
              <span className="text-[10px] leading-none px-1.5 py-[2px] font-medium whitespace-nowrap h-[18px] flex items-center text-gray-600">
                +{certificationList.length - 3}
              </span>
            )}
          </div>
        ) : (
          <div className="min-h-[32px] flex items-center">
            <span className="text-xs text-gray-400">
              {isChurnedCustomer
                ? "No groups listed"
                : "No certifications listed"}
            </span>
          </div>
        )}
        <div className="text-sm text-gray-600 flex items-center gap-2">
          <Target className="w-4 h-4 mr-0.5 flex-shrink-0 text-gray-600" />
          <span className="font-semibold">Status:</span>
          <Badge
            variant="secondary"
            className={`text-xs leading-none px-2 py-1 font-medium whitespace-nowrap h-[22px] flex items-center border-2 ${
              statusColors[status] || statusColors["Prospective"]
            }`}
          >
            {status || "Prospective"}
          </Badge>
        </div>
        <div className="text-sm text-gray-600 flex items-center">
          <MapPin className="w-4 h-4 mr-1.5 flex-shrink-0" />
          <span className="line-clamp-1">{address || "-"}</span>
        </div>

        {/* Display different link types based on customer type */}
        {isChurnedCustomer ? (
          <>
            <div className="text-sm text-gray-600 flex items-center">
              <User className="w-4 h-4 mr-1.5 flex-shrink-0" />
              <span className="line-clamp-1">
                Customer ID: <span className="font-medium">{customerId}</span>
              </span>
            </div>
            <div className="text-sm text-gray-600 flex items-center">
              <Globe className="w-4 h-4 mr-1.5 flex-shrink-0" />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span
                      className={`${
                        isValidLink(website)
                          ? "text-blue-600 hover:underline cursor-pointer"
                          : "text-gray-400 cursor-default"
                      } line-clamp-1`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isValidLink(website)) {
                          window.open(website, "_blank", "noopener,noreferrer");
                        }
                      }}
                    >
                      Visit Website
                    </span>
                  </TooltipTrigger>
                  {!isValidLink(website) && (
                    <TooltipContent>
                      <p>No website available</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            </div>
          </>
        ) : (
          <>
            <div className="text-sm text-gray-600 flex items-center">
              <Globe className="w-4 h-4 mr-1.5 flex-shrink-0" />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span
                      className={`${
                        isValidLink(website)
                          ? "text-blue-600 hover:underline cursor-pointer"
                          : "text-gray-400 cursor-default"
                      } line-clamp-1`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isValidLink(website)) {
                          window.open(website, "_blank", "noopener,noreferrer");
                        }
                      }}
                    >
                      Visit Website
                    </span>
                  </TooltipTrigger>
                  {!isValidLink(website) && (
                    <TooltipContent>
                      <p>No website available</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="text-sm text-gray-600 flex items-center">
              <ExternalLink className="w-4 h-4 mr-1.5 flex-shrink-0" />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span
                      className={`${
                        isValidLink(post_link)
                          ? "text-blue-600 hover:underline cursor-pointer"
                          : "text-gray-400 cursor-default"
                      } line-clamp-1`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isValidLink(post_link)) {
                          window.open(
                            post_link,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                    >
                      Visit Listing
                    </span>
                  </TooltipTrigger>
                  {!isValidLink(post_link) && (
                    <TooltipContent>
                      <p>No listing available</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            </div>
          </>
        )}

        <div className="bg-white p-2.5 rounded-md border border-gray-200 shadow-sm">
          <div className="flex items-center mb-1">
            <Sparkles className="w-4 h-4 mr-1.5 text-blue-600" />
            <h4 className="text-base font-semibold text-gray-800">
              {source === "Manual Add" ? "Description" : "AI Insight"}
            </h4>
          </div>
          <p className="text-sm leading-relaxed text-gray-700 line-clamp-4 font-medium">
            {analysis}
          </p>
        </div>
      </CardContent>
      {showActions && (
        <CardFooter className="pt-0 mt-2 flex-none">
          <div className="w-full flex items-center justify-center text-sm text-gray-500 gap-1.5">
            <span>View details</span>
            <ChevronRight className="w-4 h-4" />
          </div>
        </CardFooter>
      )}
      {/* <EmailPopup
        isOpen={isEmailPopupOpen}
        onClose={() => setIsEmailPopupOpen(false)}
        contractorName={name}
        contractorEmail={email || `${name.replace(/\s+/g, "")}@example.com`}
      /> */}
    </Card>
  );
}
