import * as React from "react";
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { cn } from "utils/utils";
import { DataContext } from "contexts/DataContext";

export function SearchAccountSwitch({ isCollapsed, selectedAccount, setSelectedAccount, disabled }) {
  const [query, setQuery] = React.useState("");
  const { customerReportData } = React.useContext(DataContext);

  const accounts = customerReportData || []

  const filteredAccounts = React.useMemo(() => {
    if (query === "") {
      return accounts;
    } else {
      return accounts.filter(account =>
        account.custname?.toLowerCase().includes(query.toLowerCase())
      );
    }
  }, [query, accounts]);

  return (
    <Combobox as="div" value={selectedAccount} onChange={setSelectedAccount} className="w-full z-50" disabled={disabled}>
      <div className="relative">
        <Combobox.Input
          className={cn(
            "w-full border border-gray-300 rounded-md py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm",
            isCollapsed && "hidden",
            disabled && "bg-gray-100 cursor-not-allowed opacity-75"
          )}
          disabled={disabled}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(custcd) => accounts.find(account => account.custcd === custcd)?.custname || ""}
          placeholder="Search accounts..."
        />
        <Combobox.Button className={cn(
          "absolute inset-y-0 right-0 flex items-center pr-2",
          isCollapsed && "hidden"
        )}>
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
      </div>
      <Combobox.Options className="absolute z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        {filteredAccounts.length === 0 && query !== "" ? (
          <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
            No accounts found.
          </div>
        ) : (
          filteredAccounts.map(account => (
            <Combobox.Option
              key={account?.custcd}
              value={account?.custcd}
              className={({ active }) =>
                cn(
                  "cursor-default select-none relative py-2 pl-3 pr-9",
                  active ? "text-white bg-indigo-600" : "text-gray-900"
                )
              }
            >
              {({ selected, active }) => (
                <>
                  <div className="flex items-center">
                    <span className={cn("ml-3 block truncate", selected && "font-semibold")}>
                      {account?.custname}
                    </span>
                  </div>
                  {selected && (
                    <span className={cn("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-indigo-600")}>
                      ✓
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))
        )}
      </Combobox.Options>
    </Combobox>
  );
}
