import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue = typeof window !== 'undefined' ? localStorage.getItem(key) : null;
    
    if (storedValue === null) {
      return defaultValue;
    }
    
    // Try to parse as JSON, but if that fails, return the raw value
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      // If it's not valid JSON, just return the raw string
      return storedValue;
    }
  });

  useEffect(() => {
    const listener = (e) => {
      if (typeof window !== 'undefined' && e.storageArea === localStorage && e.key === key) {
        if (e.newValue) {
          try {
            setValue(JSON.parse(e.newValue));
          } catch (error) {
            // If parsing fails, use the raw value
            setValue(e.newValue);
          }
        } else {
          setValue(e.newValue);
        }
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;
      
      if (typeof window !== 'undefined') {
        if (result === null) {
          localStorage.removeItem(key);
        } else if (typeof result === 'string') {
          // Store strings directly without JSON.stringify
          localStorage.setItem(key, result);
        } else {
          // Store objects and other types as JSON
          localStorage.setItem(key, JSON.stringify(result));
        }
      }
      
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}