import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardDescription, CardFooter, CardHeader } from 'components/ui/card'
import { ContractorCard } from '../../pages/lead-generation/components/contractor-card'
import { ContractorModal } from '../../pages/lead-generation/components/contractor-modal'
import { LeadGenContext } from 'contexts/LeadGenContext'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel"

// Helper function to safely parse numeric values
const safeParseFloat = (value, defaultValue = 0) => {
  if (!value) return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

// Helper function to parse group names
const parseGroupNames = (groupStr) => {
  if (!groupStr) return [];
  return groupStr.split('|').map(name => name.trim()).filter(Boolean);
};

// Helper function to parse certifications
const parseCertifications = (certStr) => {
  if (!certStr) return [];
  
  // If already an array, return it directly
  if (Array.isArray(certStr)) {
    return certStr;
  }
  
  try {
    // Simple JSON parse for certification arrays
    return JSON.parse(certStr);
  } catch (error) {
    console.error("Error parsing certifications:", error);
    return [];
  }
};

// Source mapping for consistent source names
const sourceMapping = {
  "SRS-CLIENT": "Churned Customer",
};

// Helper function to transform lead data
const transformLeadData = (lead) => {
  // Check if it's a churned customer or regular lead
  const isChurnedCustomer = lead.lead_type === "churn" || lead.lead_type === "converted_srs" || lead.source === "SRS-CLIENT";
  const isTop3Lead = lead.lead_type === "top3";
  
  // Safely parse numeric values
  const rating = safeParseFloat(lead.rating, 0).toFixed(1);
  const reviews = safeParseFloat(lead.reviewCount || lead.reviews, 0);
  const score = safeParseFloat(lead.score, 0);
  
  // Format address based on lead type
  let address = lead.address || "";
  if (isChurnedCustomer && !address) {
    const addressParts = [];
    if (lead.streetaddr1) addressParts.push(lead.streetaddr1);
    if (lead.streetaddr2) addressParts.push(lead.streetaddr2);
    if (lead.city || lead.statecd || lead.zipcd) {
      const cityStateParts = [];
      if (lead.city) cityStateParts.push(lead.city);
      if (lead.statecd) cityStateParts.push(lead.statecd);
      if (lead.zipcd) cityStateParts.push(lead.zipcd);
      addressParts.push(cityStateParts.join(", "));
    }
    address = addressParts.join(", ");
  }
  
  // Use parseGroupNames for churned leads, otherwise use parseCertifications
  let certifications = isChurnedCustomer ? parseGroupNames(lead.group_names) : parseCertifications(lead.certifications);

  
  // Get the correct ID field
  const id = isChurnedCustomer ? (lead.custcd || lead.row_uuid) : (lead.row_uuid || lead.id);
  
  // Get the correct description/analysis
  const description = lead.description || "";
  const analysis = lead.analysis || lead.ai_insights || description;
  
  // Set default status first
  let status = lead.lead_status;
  
  // Override for special cases
  if (isChurnedCustomer && !lead.lead_status) {
    status = "Churned";
  } else if (isTop3Lead && !lead.lead_status) {
    status = "Prospective";
  }
  
  return {
    id: id,
    row_uuid: id,
    post_link: lead.post_link || "",
    certifications: certifications,
    name: lead.name || lead.custname || "",
    description: description,
    address: address,
    analysis: analysis,
    rating: rating,
    reviewCount: Math.round(reviews), // Round to nearest whole number
    phone: lead.phone || "",
    website: lead.website || lead.websitePreview || "",
    source: sourceMapping[lead.source] || lead.source || "",
    status: status,
    email: lead.email || "",
    score: score,
    notes: lead.notes || lead.status_notes || "",
    // Pass through the custcd for churned customers
    custcd: isChurnedCustomer ? (lead.custcd || id) : null,
    // Include feedback fields
    feedback: lead.feedback,
    feedback_notes: lead.feedback_notes || ""
  };
};

export function LeadGeneration() {
  const { leads } = useContext(LeadGenContext);
  const [selectedContractor, setSelectedContractor] = useState(null);

  // Transform all leads with null checks
  const transformedLeads = (leads || []).map(transformLeadData);

  return (
    <div className="sm:mt-4">
      <Card className="h-full sm:mt-0">
        <CardHeader>
          <h2 className="text-lg font-semibold">Recent Leads</h2>
          <CardDescription>Latest potential customers and opportunities</CardDescription>
        </CardHeader>
        <div className="px-6 pb-6">
          <Carousel
            opts={{
              align: "start",
              loop: true,
            }}
            className="w-full"
          >
            <CarouselContent className="-ml-2 md:-ml-4">
              {transformedLeads.map((lead) => (
                <CarouselItem key={lead.id} className="pl-2 md:pl-4 basis-full md:basis-1/2 lg:basis-1/3">
                  <ContractorCard
                    {...lead}
                    onClick={() => setSelectedContractor(lead.id)}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
          <div className="mt-4 text-center">
            <Link 
              to="/lead-generation"
              className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
            >
              View All Leads →
            </Link>
          </div>
        </div>
        <CardFooter></CardFooter>
      </Card>

      <ContractorModal 
        isOpen={!!selectedContractor}
        onClose={() => setSelectedContractor(null)}
        contractorId={selectedContractor}
        contractors={transformedLeads}
      />
    </div>
  );
}