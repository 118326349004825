import React, { useState, useContext, useEffect } from "react";
import { Card, CardHeader, CardContent } from "components/ui/card";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  RefreshCw,
  Trash2,
} from "lucide-react";
import { SearchAccountSwitch } from "components/layout/SearchAccountSwitch";
import { AppContext } from "contexts/AppContext";
import { ChatContext } from "contexts/ChatContext";
import axios from "axios";

export default function EmailPersonaEngine() {
  const { salesAgentCD, oktaId, token } = useContext(AppContext);
  const { BASE_CHATBOT_URL } = useContext(ChatContext);

  const [emails, setEmails] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const fetchSubmittedEmails = async () => {
    try {
      setLoading(true);
      console.log("Fetching submitted emails for oktaId:", oktaId);

      const response = await axios.get(
        `https://${BASE_CHATBOT_URL}/retrieve-documents?oktaid=${oktaId}&system_generated=false`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Fetch response:", response.data);

      if (response.data && Array.isArray(response.data)) {
        const emailData = response.data
          .map((doc, index) => ({
            id: index + 1,
            subject: doc.subject || "",
            body: doc.body || "",
            submitted: true,
            selectedAccount: doc.custcd || "",
            active: doc.status === "active",
            unique_id: doc.unique_id,
          }))
          .reverse();

        // Add an empty email at the end for new submissions
        emailData.push({
          id: emailData.length + 1,
          subject: "",
          body: "",
          submitted: false,
          selectedAccount: "",
          active: true,
          unique_id: null,
        });

        setEmails(emailData);
        // Set current index to the last item (empty card) after fetching
        setCurrentIndex(emailData.length - 1);
      }
    } catch (error) {
      console.error("Error fetching submitted emails:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubmittedEmails();
  }, [oktaId, token]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const currentEmail = emails[currentIndex];
      console.log("Submitting new email sample:", {
        index: currentIndex,
        subject: currentEmail.subject,
        bodyLength: currentEmail.body.length,
        selectedAccount: currentEmail.selectedAccount,
      });

      const payload = {
        oktaid: oktaId,
        date_created: new Date().toISOString(),
        salesagentcd: salesAgentCD,
        documents: [
          {
            subject: currentEmail.subject,
            body: currentEmail.body,
            custcd: currentEmail.selectedAccount || null,
            customer_name: "",
          },
        ],
      };

      const url = `https://${BASE_CHATBOT_URL}/add-documents`;
      const response = await axios.post(url, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Submit response:", {
        status: response.status,
        data: response.data,
        timestamp: new Date().toISOString(),
      });

      // Refetch emails and add a new empty card
      const refetchResponse = await axios.get(
        `https://${BASE_CHATBOT_URL}/retrieve-documents?oktaid=${oktaId}&system_generated=false`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (refetchResponse.data && Array.isArray(refetchResponse.data)) {
        const emailData = refetchResponse.data
          .map((doc, index) => ({
            id: index + 1,
            subject: doc.subject || "",
            body: doc.body || "",
            submitted: true,
            selectedAccount: doc.custcd || "",
            active: doc.status === "active",
            unique_id: doc.unique_id,
          }))
          .reverse();

        // // Add an empty email at the end for new submissions
        // emailData.push({
        //   id: emailData.length + 1,
        //   subject: '',
        //   body: '',
        //   submitted: false,
        //   selectedAccount: '',
        //   active: true,
        //   unique_id: null
        // });

        // setEmails(emailData);
        // // Set current index to the last item (empty card)
        // setCurrentIndex(emailData.length - 1);

        // Show success dialog
        setShowSuccessDialog(true);
      }
    } catch (error) {
      console.error("Error submitting email sample:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (emailId) => {
    const email = emails.find((e) => e.id === emailId);
    if (!email || !email.unique_id) {
      console.warn("Delete called with invalid email:", { emailId, email });
      return;
    }

    try {
      console.log("Deleting email:", {
        emailId,
        uniqueId: email.unique_id,
      });

      const payload = {
        unique_ids: [email.unique_id],
        oktaid: oktaId,
        salesagentcd: salesAgentCD,
        updated_date: new Date().toISOString().split("T")[0],
        status: "deleted",
      };

      const response = await axios.post(
        `https://${BASE_CHATBOT_URL}/set-document-status`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("Delete response:", {
        status: response.status,
        data: response.data,
        timestamp: new Date().toISOString(),
      });

      // Call the fetch method to update the emails
      // await fetchSubmittedEmails();

      // Show delete confirmation dialog
      setShowDeleteDialog(true);
    } catch (error) {
      console.error("Error deleting email:", error);
    }
  };

  const handleAccountSelect = (accountId) => {
    const newEmails = [...emails];
    newEmails[currentIndex].selectedAccount = accountId;
    setEmails(newEmails);
  };

  const navigateCarousel = (direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < emails.length) {
      setCurrentIndex(newIndex);
    }
  };

  if (loading && emails.length === 0) {
    return (
      <Card className="w-full">
        <CardContent className="flex items-center justify-center min-h-[400px]">
          <RefreshCw className="h-8 w-8 animate-spin text-gray-400" />
        </CardContent>
      </Card>
    );
  }

  const submittedCount = emails.filter((e) => e.submitted).length;
  const currentEmail = emails[currentIndex] || {
    id: 1,
    subject: "",
    body: "",
    submitted: false,
    selectedAccount: "",
    active: true,
    unique_id: null,
  };

  return (
    <>
      <Card className="w-full">
        <CardHeader>
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-lg font-semibold">
              Train Your AI Email Personality
            </h2>
            <div
              className={`px-3 py-1 rounded-full text-sm font-medium ${
                submittedCount === 0
                  ? "bg-red-100 text-red-700"
                  : submittedCount === 1
                  ? "bg-orange-100 text-orange-700"
                  : submittedCount === 2
                  ? "bg-yellow-100 text-yellow-700"
                  : "bg-green-100 text-green-700"
              }`}
            >
              {submittedCount}/3 Samples Submitted
            </div>
          </div>
          <p className="text-sm text-gray-600 mt-2">
          Submit your past emails to teach our AI how to write in your voice. You must provide <b>at least 3 samples</b> to get things going, but the more you share, the better the AI will be at matching your style.
          </p>
        </CardHeader>
        <CardContent>
          <div className="relative">
            <Card className="shadow-sm">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4 border-b">
                <div>
                  <h3 className="text-xl font-medium">
                    {currentEmail.submitted
                      ? `Sample ${currentIndex + 1}`
                      : "Submit a New Sample"}
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">
                    {currentEmail.submitted
                      ? "Submitted"
                      : "Paste your email below"}
                  </p>
                </div>
                {currentEmail.submitted && (
                  <div className="flex items-center gap-2">
                    <Check className="h-5 w-5 text-green-500" />
                    <span className="text-sm text-green-500">
                      Sample Submitted
                    </span>
                  </div>
                )}
              </CardHeader>
              <CardContent className="pt-6">
                <div className="space-y-6">
                  <div
                    className={
                      currentEmail.submitted && !currentEmail.selectedAccount
                        ? "opacity-50 pointer-events-none"
                        : ""
                    }
                  >
                    <Label className="block text-sm font-medium text-gray-700 mb-2">
                      Set Tone for Specific Customer (Optional)
                    </Label>
                    <p className="text-sm text-gray-500 mb-2">
                      If selected, this email sample will be prioritized when
                      generating emails for this customer.
                    </p>
                    <SearchAccountSwitch
                      selectedAccount={currentEmail.selectedAccount}
                      setSelectedAccount={handleAccountSelect}
                      disabled={
                        currentEmail.submitted
                      }
                    />
                  </div>
                  <div>
                    <Label className="block text-sm font-medium text-gray-700 mb-2">
                      Subject Line
                    </Label>
                    <Input
                      placeholder="Enter the email subject"
                      value={currentEmail.subject}
                      onChange={(e) => {
                        const newEmails = [...emails];
                        newEmails[currentIndex].subject = e.target.value;
                        setEmails(newEmails);
                      }}
                      disabled={currentEmail.submitted}
                      className={
                        currentEmail.submitted
                          ? "bg-gray-100 text-gray-900 font-medium"
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <Label className="block text-sm font-medium text-gray-700 mb-2">
                      Email Body
                    </Label>
                    <Textarea
                      placeholder="Paste the full email body here"
                      className={`min-h-[300px] ${
                        currentEmail.submitted
                          ? "bg-gray-100 text-gray-900 font-medium"
                          : ""
                      }`}
                      value={currentEmail.body}
                      onChange={(e) => {
                        const newEmails = [...emails];
                        newEmails[currentIndex].body = e.target.value;
                        setEmails(newEmails);
                      }}
                      disabled={currentEmail.submitted}
                    />
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => navigateCarousel(-1)}
                      disabled={currentIndex === 0}
                      className="rounded-full"
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>

                    {currentEmail.submitted ? (
                      <Button
                        variant="destructive"
                        // size="sm"
                        onClick={() => handleDelete(currentEmail.id)}
                        className="flex items-center gap-2 bg-black text-white hover:bg-gray-800"
                      >
                        {/* <Trash2 className="h-4 w-4" /> */}
                        Delete Sample
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        disabled={
                          !currentEmail.subject ||
                          !currentEmail.body ||
                          submitting
                        }
                        // className="px-8"
                      >
                        {submitting ? "Submitting..." : "Submit Sample"}
                      </Button>
                    )}

                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => navigateCarousel(1)}
                      disabled={currentIndex === emails.length - 1}
                      className="rounded-full"
                    >
                      <ChevronRight className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>

            <div className="flex justify-center gap-2 mt-4">
              {emails.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-2 h-2 rounded-full transition-colors ${
                    index === currentIndex
                      ? "bg-gray-800"
                      : emails[index].submitted
                      ? "bg-green-500"
                      : "bg-gray-300"
                  }`}
                  aria-label={`Go to sample ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Success Dialog for Email Submission */}
      <AlertDialog open={showSuccessDialog} onOpenChange={setShowSuccessDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Email Sample Submitted Successfully
            </AlertDialogTitle>
            <AlertDialogDescription>
              Your email sample has been added to your training set. This will
              help improve the AI's ability to match your communication style.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                setShowSuccessDialog(false);
                fetchSubmittedEmails();
              }}
            >
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Email Sample Deleted</AlertDialogTitle>
            <AlertDialogDescription>
              The email sample has been successfully removed from your training
              set.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                setShowDeleteDialog(false);
                fetchSubmittedEmails();
              }}
            >
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
