import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Send,
  MessageSquare,
  MoreHorizontal,
  ArrowLeft,
  Share2,
  Flag,
  Bookmark,
  CheckCircle,
  Award,
  Pencil,
  Trash2,
  X,
  Save,
} from "lucide-react";
import {
  Text,
  Title,
  Grid,
  Col,
  Button,
  Badge,
  TextInput,
  Flex,
  Textarea,
} from "@tremor/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Card, CardHeader, CardContent } from "components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
} from "components/ui/dialog";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid";
import { WaterCoolerContext } from "contexts/WaterCoolerContext";

// Add color mapping for topics
const topicColors = {
  Sales: "emerald",
  Product: "blue",
  "Customer Service": "amber",
  Technical: "violet",
  "Market Trends": "rose",
  "Supply Chain": "indigo",
};

export default function DetailedPost() {
  const navigate = useNavigate();
  const { postId } = useParams(); // Get the post ID from the URL
  const {
    currentPost,
    userData,
    loading,
    error,
    fetchPost,
    addResponse,
    castVote,
    toggleBookmark,
    setBestResponse,
    updatePost,
    deletePost,
    updateResponse,
    deleteResponse,
  } = useContext(WaterCoolerContext);

  const [comment, setComment] = useState("");
  const [votedPosts, setVotedPosts] = useState({});
  const [voteFlash, setVoteFlash] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [selectedBestResponse, setSelectedBestResponse] = useState(null);
  const [awardFlash, setAwardFlash] = useState(null);

  // Edit post states
  const [isEditingPost, setIsEditingPost] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editContent, setEditContent] = useState("");

  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState("");
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [replyingToId, setReplyingToId] = useState(null);
  const [replyContent, setReplyContent] = useState("");

  // Delete confirmation dialog state
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const editTextareaRef = useRef(null);

  const handleCommentChange = (e) => {
    const cursorPosition = e.target.selectionStart; // Save cursor position
    setEditCommentContent(e.target.value); // Update state

    // Restore cursor position after state update
    requestAnimationFrame(() => {
      if (editTextareaRef.current) {
        editTextareaRef.current.selectionStart = cursorPosition;
        editTextareaRef.current.selectionEnd = cursorPosition;
      }
    });
  };

  // Best Response Indicator Component
  const BestResponseIndicator = () => {
    return (
      <div className="bg-gradient-to-r from-amber-50 to-amber-100 px-4 py-2 border-b border-amber-200 flex items-center gap-2">
        <div className="flex items-center">
          <Award className="h-5 w-5 text-amber-500 fill-amber-500" />
          <CheckCircle className="h-4 w-4 text-green-600 -ml-1 -mb-2" />
        </div>
        <div>
          <div className="font-semibold text-amber-800">Best Response</div>
          <div className="text-xs text-amber-700">Selected by post author</div>
        </div>
      </div>
    );
  };

  // Fetch post data when component mounts or postId changes
  useEffect(() => {
    if (postId) {
      fetchPost(postId);
    }
  }, [postId]);

  useEffect(() => {
    if (editingCommentId === comment.data_id && editTextareaRef.current) {
      editTextareaRef.current.focus();
    }
  }, [editingCommentId]);

  // Initialize states when currentPost or userData changes
  // Modify the useEffect that sets selectedBestResponse to recursively search through all comments

  // Find this useEffect in your DetailedPost component:
  useEffect(() => {
    if (currentPost && userData) {
      const initialVotedPosts = {};

      // Check votes for the current post
      const postVote = userData.votes.find(
        (vote) =>
          vote.data_id === currentPost.data_id && vote.content_type === "1"
      );

      if (postVote) {
        initialVotedPosts[currentPost.data_id] =
          postVote.vote_type === 1
            ? "up"
            : postVote.vote_type === -1
            ? "down"
            : undefined;
      }

      // Recursive function to check votes for all comments including nested replies
      const processComments = (comments) => {
        if (!comments || !Array.isArray(comments)) return;

        comments.forEach((comment) => {
          const commentVote = userData.votes.find(
            (vote) =>
              vote.data_id === comment.data_id &&
              vote.content_type === 2
          );

          if (commentVote) {
            initialVotedPosts[comment.data_id] =
              commentVote.vote_type === 1
                ? "up"
                : commentVote.vote_type === -1
                ? "down"
                : undefined;
          }

          // Process nested replies recursively
          if (comment.replies && comment.replies.length > 0) {
            processComments(comment.replies);
          }
        });
      };

      // Check votes for all comments including nested ones
      if (currentPost.comments) {
        processComments(currentPost.comments);
      }

      setVotedPosts(initialVotedPosts);
      setIsBookmarked(currentPost.is_bookmarked || false);

      // Find the best response by looking for title === "best response"
      const bestResponseComment = currentPost.comments?.find(
        (comment) => comment.title === "best response"
      );

      if (bestResponseComment) {
        console.log(
          "Found best response with title:",
          bestResponseComment.data_id
        );
        setSelectedBestResponse(bestResponseComment.data_id);
      } else if (currentPost.best_response_id) {
        // Fallback to the existing method
        console.log("Using best_response_id:", currentPost.best_response_id);
        setSelectedBestResponse(currentPost.best_response_id);
      } else {
        setSelectedBestResponse(null);
      }

      // Initialize edit form values
      setEditTitle(currentPost.title);
      setEditContent(currentPost.content);
    }
  }, [currentPost, userData]);

  // Replace with this updated version that recursively checks for best responses:
  useEffect(() => {
    if (currentPost && userData) {
      const initialVotedPosts = {};

      // Check votes for the current post
      const postVote = userData.votes.find(
        (vote) =>
          vote.data_id === currentPost.data_id && vote.content_type === 1
      );

      if (postVote) {
        initialVotedPosts[currentPost.data_id] =
          postVote.vote_type === 1
            ? "up"
            : postVote.vote_type === -1
            ? "down"
            : undefined;
      }

      // Recursive function to check votes for all comments including nested replies
      const processComments = (comments) => {
        if (!comments || !Array.isArray(comments)) return;

        comments.forEach((comment) => {
          const commentVote = userData.votes.find(
            (vote) =>
              vote.data_id === comment.data_id &&
              vote.content_type === 2
          );

          if (commentVote) {
            initialVotedPosts[comment.data_id] =
              commentVote.vote_type === 1
                ? "up"
                : commentVote.vote_type === -1
                ? "down"
                : undefined;
          }

          // Process nested replies recursively
          if (comment.replies && comment.replies.length > 0) {
            processComments(comment.replies);
          }
        });
      };

      // Check votes for all comments including nested ones
      if (currentPost.comments) {
        processComments(currentPost.comments);
      }

      setVotedPosts(initialVotedPosts);
      setIsBookmarked(currentPost.is_bookmarked || false);

      // Recursive function to find the best response
      const findBestResponse = (comments) => {
        if (!comments || !Array.isArray(comments)) return null;

        for (const comment of comments) {
          if (comment.title === "best response") {
            return comment.data_id;
          }

          // Check nested replies recursively
          if (comment.replies && comment.replies.length > 0) {
            const nestedBestResponse = findBestResponse(comment.replies);
            if (nestedBestResponse) {
              return nestedBestResponse;
            }
          }
        }

        return null;
      };

      // Find the best response by looking for title === "best response" at any nesting level
      const bestResponseId = findBestResponse(currentPost.comments);

      if (bestResponseId) {
        console.log("Found best response with ID:", bestResponseId);
        setSelectedBestResponse(bestResponseId);
      } else if (currentPost.best_response_id) {
        // Fallback to the existing method
        console.log("Using best_response_id:", currentPost.best_response_id);
        setSelectedBestResponse(currentPost.best_response_id);
      } else {
        setSelectedBestResponse(null);
      }

      // Initialize edit form values
      setEditTitle(currentPost.title);
      setEditContent(currentPost.content);
    }
  }, [currentPost, userData]);

  // Handle post or comment vote
  const handleVote = async (dataId, direction) => {
    try {
      // Determine the current voting state
      const currentVote = votedPosts[dataId];
      let voteType = direction === "up" ? "upvote" : "downvote";

      // If already voted same way, send novote to remove vote
      if (votedPosts[dataId] === direction) {
        voteType = "novote";
      }

      // Optimistically update UI for votedPosts
      setVotedPosts((prev) => {
        if (prev[dataId] === direction) {
          // Removing vote
          const newState = { ...prev };
          delete newState[dataId];
          return newState;
        } else {
          return { ...prev, [dataId]: direction };
        }
      });

      // Add flash effect
      setVoteFlash({ direction, id: dataId });
      setTimeout(() => {
        setVoteFlash(null);
      }, 1000);

      // Find and update the comment or its nested replies recursively
      const updateCommentVotes = (comments, targetId) => {
        for (let i = 0; i < comments.length; i++) {
          const comment = comments[i];

          if (comment.data_id === targetId) {
            // Remove previous vote
            if (currentVote === "up") {
              comment.upvotes -= 1;
            } else if (currentVote === "down") {
              comment.downvotes -= 1;
            }

            // Apply new vote
            if (voteType === "upvote") {
              comment.upvotes += 1;
            } else if (voteType === "downvote") {
              comment.downvotes += 1;
            }

            return true; // Found and updated
          }

          // Check nested replies recursively
          if (comment.replies && comment.replies.length > 0) {
            const found = updateCommentVotes(comment.replies, targetId);
            if (found) return true;
          }
        }

        return false; // Not found
      };

      // Update vote counts in UI
      if (currentPost) {
        // If voting on the main post
        if (dataId === currentPost.data_id) {
          // Modify post vote counts
          if (currentVote === "up") {
            currentPost.upvotes -= 1;
          } else if (currentVote === "down") {
            currentPost.downvotes -= 1;
          }

          // Apply new vote
          if (voteType === "upvote") {
            currentPost.upvotes += 1;
          } else if (voteType === "downvote") {
            currentPost.downvotes += 1;
          }
        } else if (currentPost.comments) {
          // Search for comment in the entire comment tree
          updateCommentVotes(currentPost.comments, dataId);
        }
      }

      console.log(
        `Calling castVote with dataId: ${dataId}, voteType: ${voteType}`
      );

      // Call the castVote function from context
      const result = await castVote(dataId, voteType);
      console.log("Vote result:", result);
    } catch (err) {
      console.error("Error casting vote:", err);
      alert("Failed to cast vote. Please try again.");
    }
  };

  // Handle setting best response
  const handleSetBestResponse = async (responseId) => {
    try {
      // Only allow the post owner to set best response
      if (userData?.profile?.user_id !== currentPost?.creator_id) {
        alert("Only the post author can select the best response");
        return;
      }

      // Get the comment to check who wrote it
      const comment = currentPost.comments.find(
        (c) => c.data_id === responseId
      );

      // Check if this is the user's own comment
      if (comment && comment.user_id === userData?.profile?.user_id) {
        alert("You cannot select your own response as the best response");
        return;
      }

      // If already selected, unselect it (passing null to API)
      const idToSet = selectedBestResponse === responseId ? null : responseId;

      // Optimistically update UI
      setSelectedBestResponse(idToSet);

      // Add award flash effect
      if (idToSet !== null) {
        setAwardFlash(responseId);
        setTimeout(() => {
          setAwardFlash(null);
        }, 1500);
      }

      try {
        // Call the API function from context
        await setBestResponse(currentPost.data_id, idToSet);
      } catch (apiError) {
        console.error("API Error setting best response:", apiError);
        // Revert UI state on API error
        setSelectedBestResponse(currentPost?.best_response_id || null);

        // Extract the detailed error message if available
        const errorDetail = apiError.response?.data?.detail;
        if (errorDetail) {
          alert(`Failed to set best response: ${errorDetail}`);
        } else {
          alert("Failed to set best response. Please try again.");
        }
      }
    } catch (err) {
      console.error("Error handling best response:", err);
      alert("Something went wrong. Please try again.");
      // Revert UI state if there's an error
      setSelectedBestResponse(currentPost?.best_response_id || null);
    }
  };

  // Handle comment submission
  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (comment.trim() === "") {
      alert("Please enter a comment");
      return;
    }

    if (!userData?.profile?.user_id) {
      alert("You must be logged in to comment");
      return;
    }

    try {
      // Call the addResponse function from context
      await addResponse(postId, comment);

      // Clear input field
      setComment("");
    } catch (err) {
      console.error("Error posting comment:", err);
      alert("Failed to post comment. Please try again.");
    }
  };

  // Handle bookmark toggle
  const handleToggleBookmark = async () => {
    try {
      // Optimistically update UI
      setIsBookmarked((prev) => !prev);

      // Call the toggleBookmark function from context
      await toggleBookmark(postId);
    } catch (err) {
      console.error("Error toggling bookmark:", err);
      alert("Failed to save post. Please try again.");
      // Revert UI if there's an error
      setIsBookmarked((prev) => !prev);
    }
  };

  // Handle edit mode toggle
  const handleToggleEditMode = () => {
    if (!isEditingPost) {
      // Entering edit mode - set form values to current post
      setEditTitle(currentPost.title);
      setEditContent(currentPost.content);
    }
    setIsEditingPost(!isEditingPost);
  };

  // Handle post update
  const handleUpdatePost = async () => {
    if (editTitle.trim() === "") {
      alert("Post title cannot be empty");
      return;
    }

    if (editContent.trim() === "") {
      alert("Post content cannot be empty");
      return;
    }

    try {
      // Call the updatePost function from context
      await updatePost(currentPost.data_id, editTitle, editContent);

      // Exit edit mode
      setIsEditingPost(false);
    } catch (err) {
      console.error("Error updating post:", err);
      alert("Failed to update post. Please try again.");
    }
  };

  // Handle post deletion
  const handleDeletePost = async () => {
    try {
      // Call the deletePost function from context
      await deletePost(currentPost.data_id);

      // Close dialog
      setIsDeleteDialogOpen(false);

      // Refresh the post to see the "This post has been deleted" message
      fetchPost(postId);
    } catch (err) {
      console.error("Error deleting post:", err);
      alert("Failed to delete post. Please try again.");
      setIsDeleteDialogOpen(false);
    }
  };

  // Handle comment edit
  const handleEditComment = (comment) => {
    setEditingCommentId(comment.data_id);
    setEditCommentContent(comment.content);
    setIsEditingComment(true);
  };

  // Handle cancel comment edit
  const handleCancelEditComment = () => {
    setEditingCommentId(null);
    setEditCommentContent("");
    setIsEditingComment(false);
  };

  // Handle comment update
  const handleUpdateComment = async (commentId) => {
    if (editCommentContent.trim() === "") {
      alert("Comment cannot be empty");
      return;
    }

    try {
      await updateResponse(commentId, editCommentContent);

      // Reset edit state
      setEditingCommentId(null);
      setEditCommentContent("");
      setIsEditingComment(false);
    } catch (err) {
      console.error("Error updating comment:", err);
      alert("Failed to update comment. Please try again.");
    }
  };

  // Handle comment deletion
  const handleDeleteComment = async (commentId) => {
    if (!confirm("Are you sure you want to delete this comment?")) {
      return;
    }

    try {
      await deleteResponse(commentId);

      // The post will be refreshed automatically by the context after deletion
    } catch (err) {
      console.error("Error deleting comment:", err);
      alert("Failed to delete comment. Please try again.");
    }
  };

  // New function to handle starting a reply
  const handleStartReply = (commentId) => {
    setReplyingToId(commentId);
    setReplyContent("");
  };

  // New function to handle canceling a reply
  const handleCancelReply = () => {
    setReplyingToId(null);
    setReplyContent("");
  };

  // New function to handle reply submission
  const handleReplySubmit = async (commentId) => {
    if (replyContent.trim() === "") {
      alert("Please enter a reply");
      return;
    }

    if (!userData?.profile?.user_id) {
      alert("You must be logged in to reply");
      return;
    }

    try {
      // Call the addResponse function with the comment ID as parent
      await addResponse(commentId, replyContent);

      // Reset reply state
      setReplyingToId(null);
      setReplyContent("");
    } catch (err) {
      console.error("Error posting reply:", err);
      alert("Failed to post reply. Please try again.");
    }
  };

  const Comment = ({ comment, level = 0 }) => {
    // Calculate margin based on nesting level (keep existing getIndentClass function)
    const getIndentClass = () => {
      const mobileMaxLevel = 2;
      const mobileIndent = level > mobileMaxLevel ? mobileMaxLevel : level;
      const desktopMaxLevel = 4;
      const desktopIndent = level > desktopMaxLevel ? desktopMaxLevel : level;
      return `ml-${mobileIndent * 3} md:ml-${desktopIndent * 4} mt-3`;
    };

    return (
      <Card
        key={comment.data_id}
        className={`relative ${
          selectedBestResponse === comment.data_id
            ? "border-2 border-amber-400"
            : ""
        } ${level > 0 ? getIndentClass() : ""}`}
      >
        {selectedBestResponse === comment.data_id && <BestResponseIndicator />}
        <CardContent className="p-3 md:p-4">
          <div className="flex flex-col md:flex-row gap-3 md:gap-4">
            {/* Vote Column */}
            <div className="flex justify-start gap-2 md:w-auto w-full md:self-start">
              <Button
                variant="light"
                size="xs"
                icon={ArrowUpIcon}
                color={votedPosts[comment.data_id] === "up" ? "green" : "gray"}
                className={`transition-transform hover:scale-110 ${
                  voteFlash?.direction === "up" &&
                  voteFlash?.id === comment.data_id
                    ? "scale-125"
                    : ""
                }`}
                onClick={() => handleVote(comment.data_id, "up")}
              />
              <Text
                className="font-bold min-w-[1.5rem] text-center"
                color={
                  votedPosts[comment.data_id] === "up"
                    ? "green"
                    : votedPosts[comment.data_id] === "down"
                    ? "red"
                    : "gray"
                }
              >
                {comment.upvotes - comment.downvotes}
              </Text>
              <Button
                variant="light"
                size="xs"
                icon={ArrowDownIcon}
                color={votedPosts[comment.data_id] === "down" ? "red" : "gray"}
                className={`transition-transform hover:scale-110 ${
                  voteFlash?.direction === "down" &&
                  voteFlash?.id === comment.data_id
                    ? "scale-125"
                    : ""
                }`}
                onClick={() => handleVote(comment.data_id, "down")}
              />
            </div>

            {/* Comment Content */}
            <div className="flex-1 min-w-0">
              {/* Fixed header alignment - single row with flex-nowrap to keep items on same line */}
              <div className="flex items-center justify-between mb-3 flex-nowrap">
                <div className="flex w-full flex-row justify-between items-center mr-4 flex-grow">
                  <Text color="gray" className="text-sm font-medium truncate">
                    {userData?.profile?.user_id === comment.user_id
                      ? "You"
                      : comment.first_name && comment.last_name
                      ? `${comment.first_name} ${comment.last_name}`
                      : `User ${comment.user_id}`}
                  </Text>
                  <Text color="gray" className="mx-2">
                    ·
                  </Text>
                  <Text color="gray" className="text-sm">
                    {formatDate(comment.created_date)}
                  </Text>
                </div>

                {/* Action buttons with flex-shrink-0 to prevent wrapping */}
                <div className="flex items-center gap-2 flex-shrink-0">
                  {/* Award button for post owner */}
                  {isPostOwner &&
                    userData?.profile?.user_id !== comment.user_id && (
                      <Button
                        size="xs"
                        variant="light"
                        color={
                          selectedBestResponse === comment.data_id
                            ? "amber"
                            : "gray"
                        }
                        onClick={() => handleSetBestResponse(comment.data_id)}
                        className={`transition-all duration-200 flex-shrink-0 ${
                          selectedBestResponse === comment.data_id
                            ? "shadow-md scale-105"
                            : ""
                        } ${
                          awardFlash === comment.data_id ? "animate-pulse" : ""
                        }`}
                        title={
                          selectedBestResponse === comment.data_id
                            ? "Unmark as Best Response"
                            : "Mark as Best Response"
                        }
                      >
                        <Award
                          className={`h-4 w-4 ${
                            selectedBestResponse === comment.data_id
                              ? "text-amber-500 fill-amber-500"
                              : ""
                          }`}
                        />
                      </Button>
                    )}

                  {/* Edit/Delete dropdown for user's own comments */}
                  {userData?.profile?.user_id === comment.user_id && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="light"
                          size="xs"
                          className="flex-shrink-0"
                        >
                          <MoreHorizontal className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem
                          onClick={() => handleEditComment(comment)}
                        >
                          <Pencil className="mr-2 h-4 w-4" />
                          Edit Comment
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleDeleteComment(comment.data_id)}
                        >
                          <Trash2 className="mr-2 h-4 w-4" />
                          Delete Comment
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </div>

              {/* Rest of the comment content remains mostly the same */}
              {editingCommentId === comment.data_id ? (
                <div className="space-y-4">
                  <div>
                    <Text className="mb-2 font-medium">Edit Comment</Text>
                    <Textarea
                      ref={editTextareaRef}
                      value={editCommentContent}
                      onChange={handleCommentChange}
                      placeholder="Edit your comment"
                      rows={3}
                      className="w-full"
                      autoFocus
                    />
                  </div>

                  <Flex justifyContent="end" className="gap-2">
                    <Button
                      variant="light"
                      color="gray"
                      onClick={handleCancelEditComment}
                      icon={X}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="blue"
                      onClick={() => handleUpdateComment(comment.data_id)}
                      icon={Save}
                    >
                      Save
                    </Button>
                  </Flex>
                </div>
              ) : (
                <>
                  <Text className="text-gray-600 break-words mb-3">
                    {comment.content}
                  </Text>

                  <Flex
                    justifyContent="center"
                    className="mt-3 mb-2 sm:justify-start"
                  >
                    <Button
                      variant="light"
                      size="xs"
                      icon={MessageSquare}
                      onClick={() => handleStartReply(comment.data_id)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      Reply
                    </Button>
                  </Flex>

                  {replyingToId === comment.data_id && (
                    <div className="mt-3 mb-3 pl-3 md:pl-4 border-l-2 border-gray-200">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleReplySubmit(comment.data_id);
                        }}
                      >
                        <Flex className="flex-col sm:flex-row gap-3">
                          <TextInput
                            placeholder={`Reply to ${
                              comment.first_name || `User ${comment.user_id}`
                            }...`}
                            value={replyContent}
                            onChange={(e) => setReplyContent(e.target.value)}
                            className="flex-1 min-w-0"
                            autoFocus
                          />
                          <div className="flex gap-2">
                            <Button type="submit" size="xs" icon={Send}>
                              Reply
                            </Button>
                            <Button
                              type="button"
                              size="xs"
                              variant="light"
                              icon={X}
                              onClick={handleCancelReply}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Flex>
                      </form>
                    </div>
                  )}
                </>
              )}

              {/* Render replies */}
              {comment.replies && comment.replies.length > 0 && (
                <div className="mt-4 space-y-3">
                  <div className="flex items-center gap-2 text-xs text-gray-500 my-2 px-1">
                    <div className="h-px bg-gray-200 flex-grow"></div>
                    <span>
                      Continuing thread ({comment.replies.length}{" "}
                      {comment.replies.length === 1 ? "reply" : "replies"})
                    </span>
                    <div className="h-px bg-gray-200 flex-grow"></div>
                  </div>

                  {comment.replies.map((reply) => (
                    <Comment
                      key={reply.data_id}
                      comment={reply}
                      level={level + 1}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  // Post header component with matching styling that should be used in the post section
  const PostHeader = () => (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center mr-2">
        <Text color="gray" className="text-sm">
          {userData?.profile?.user_id === currentPost.creator_id
            ? "You"
            : currentPost.creator_first_name && currentPost.creator_last_name
            ? `${currentPost.creator_first_name} ${currentPost.creator_last_name}`
            : `User ${currentPost.creator_id}`}
        </Text>
        <Text color="gray" className="mx-2">
          ·
        </Text>
        <Text color="gray" className="text-sm">
          {formatDate(currentPost.created_date)}
        </Text>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="light"
            icon={MoreHorizontal}
            size="sm"
            className="flex-shrink-0"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {/* Dropdown content */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );

  // Helper to format date
  const formatDate = (isoDate) => {
    if (!isoDate) return "";

    const date = new Date(isoDate);
    const now = new Date();
    const diffMs = now - date;
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return `${diffDays}d ago`;
    } else if (diffHours > 0) {
      return `${diffHours}h ago`;
    } else if (diffMins > 0) {
      return `${diffMins}m ago`;
    } else {
      return "Just now";
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="p-2 sm:p-4 md:p-6">
        <Card className="relative">
          <CardContent className="p-4 text-center">
            <Title>Loading post...</Title>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="p-2 sm:p-4 md:p-6">
        <Card className="relative">
          <CardContent className="p-4 text-center">
            <Title className="text-red-500">{error}</Title>
            <Button
              variant="light"
              className="mt-2"
              onClick={() => fetchPost(postId)}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Render post not found
  if (!currentPost) {
    return (
      <div className="p-2 sm:p-4 md:p-6">
        <Card className="relative">
          <CardContent className="p-4 text-center">
            <Title>Post not found</Title>
            <Link to="/water-cooler">
              <Button className="mt-2">Back to Water Cooler</Button>
            </Link>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Check if current user is the post owner
  const isPostOwner = userData?.profile?.user_id === currentPost.creator_id;

  return (
    <div className="p-2 sm:p-4 md:p-6">
      <Grid numItems={1} numItemsSm={1} numItemsMd={12} className="gap-6">
        {/* Left Sidebar - Full width on mobile, side column on desktop */}
        <Col numColSpan={1} numColSpanMd={3} className="space-y-6">
          {/* Back Button and Stats Card */}
          <Card className="relative">
            <CardContent className="p-4">
              <Link
                to="/water-cooler"
                className="inline-flex items-center text-gray-500 hover:text-gray-900 mb-4"
              >
                <ArrowLeft className="h-4 w-4 mr-1" />
                Back to Water Cooler
              </Link>

              <div className="space-y-1.5">
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  className="border-b pb-1"
                >
                  <Text className="text-gray-500 text-sm">Votes</Text>
                  <Text className="font-medium text-gray-700">
                    {currentPost.upvotes - currentPost.downvotes}
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  className="border-b pb-1"
                >
                  <Text className="text-gray-500 text-sm">Comments</Text>
                  <Text className="font-medium text-gray-700">
                    {currentPost.comments?.length || 0}
                  </Text>
                </Flex>
              </div>
            </CardContent>
          </Card>
        </Col>

        {/* Main Content - Full width on mobile, 9 columns on desktop */}
        <Col numColSpan={1} numColSpanMd={9}>
          <Card className="relative">
            <CardContent className="p-4">
              {/* Use flexbox layout instead of Grid */}
              <div className="flex flex-col md:flex-row gap-4">
                {/* Vote Column - Horizontal and left-justified on mobile, side column on desktop */}
                <div className="flex justify-start gap-1 md:w-auto w-full md:self-start">
                  <Button
                    variant="light"
                    size="xs"
                    icon={ArrowUpIcon}
                    color={
                      votedPosts[currentPost.data_id] === "up"
                        ? "green"
                        : "gray"
                    }
                    className={`transition-transform ${
                      voteFlash?.direction === "up" &&
                      voteFlash?.id === currentPost.data_id
                        ? "scale-125"
                        : ""
                    }`}
                    onClick={() => handleVote(currentPost.data_id, "up")}
                  />
                  <Text
                    className="font-bold min-w-[1.5rem] text-center"
                    color={
                      votedPosts[currentPost.data_id] === "up"
                        ? "green"
                        : votedPosts[currentPost.data_id] === "down"
                        ? "red"
                        : "gray"
                    }
                  >
                    {currentPost.upvotes - currentPost.downvotes}
                  </Text>
                  <Button
                    variant="light"
                    size="xs"
                    icon={ArrowDownIcon}
                    color={
                      votedPosts[currentPost.data_id] === "down"
                        ? "red"
                        : "gray"
                    }
                    className={`transition-transform ${
                      voteFlash?.direction === "down" &&
                      voteFlash?.id === currentPost.data_id
                        ? "scale-125"
                        : ""
                    }`}
                    onClick={() => handleVote(currentPost.data_id, "down")}
                  />
                </div>

                {/* Content Column */}
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-4">
                    <Flex alignItems="center" className="gap-2 mr-4">
                      <Text color="gray" className="text-sm">
                        {userData?.profile?.user_id === currentPost.creator_id
                          ? "You"
                          : currentPost.creator_first_name &&
                            currentPost.creator_last_name
                          ? `${currentPost.creator_first_name} ${currentPost.creator_last_name}`
                          : `User ${currentPost.creator_id}`}
                      </Text>
                      <Text color="gray">·</Text>
                      <Text color="gray" className="text-sm">
                        {formatDate(currentPost.created_date)}
                      </Text>
                      {/* {isPostOwner && (
              <Badge color="blue" size="sm">
                Author
              </Badge>
            )} */}
                    </Flex>

                    {/* More options dropdown with Edit and Delete */}
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="light"
                          icon={MoreHorizontal}
                          size="sm"
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={handleToggleBookmark}>
                          <Bookmark
                            className={`mr-2 h-4 w-4 ${
                              isBookmarked ? "text-blue-500 fill-blue-500" : ""
                            }`}
                          />
                          {isBookmarked ? "Unsave Post" : "Save Post"}
                        </DropdownMenuItem>

                        {/* Only show Edit and Delete for post owner */}
                        {isPostOwner && (
                          <>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={handleToggleEditMode}>
                              <Pencil className="mr-2 h-4 w-4" />
                              Edit Post
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => setIsDeleteDialogOpen(true)}
                            >
                              <Trash2 className="mr-2 h-4 w-4" />
                              Delete Post
                            </DropdownMenuItem>
                          </>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>

                  {/* Edit Mode */}
                  {isEditingPost ? (
                    <div className="space-y-4">
                      <div>
                        <Text className="mb-1 font-medium">Title</Text>
                        <TextInput
                          value={editTitle}
                          onChange={(e) => setEditTitle(e.target.value)}
                          placeholder="Post title"
                          className="w-full"
                        />
                      </div>

                      <div>
                        <Text className="mb-1 font-medium">Content</Text>
                        <Textarea
                          ref={editTextareaRef}
                          value={editContent}
                          onChange={(e) => setEditContent(e.target.value)}
                          placeholder="Post content"
                          rows={8}
                          className="w-full"
                        />
                      </div>

                      <Flex justifyContent="end" className="gap-2">
                        <Button
                          variant="light"
                          color="gray"
                          onClick={handleToggleEditMode}
                          icon={X}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="blue"
                          onClick={handleUpdatePost}
                          icon={Pencil}
                        >
                          Update Post
                        </Button>
                      </Flex>
                    </div>
                  ) : (
                    <>
                      <Title className="text-xl md:text-2xl mb-3">
                        {currentPost.title}
                      </Title>

                      {/* Topic Badge */}
                      {currentPost.topic && (
                        <div className="overflow-x-auto mb-4">
                          <Flex className="gap-1 min-w-max justify-start">
                            <Badge
                              color={topicColors[currentPost.topic] || "gray"}
                              size="sm"
                              className="cursor-pointer hover:opacity-80 transition-colors whitespace-nowrap"
                              onClick={() =>
                                navigate(
                                  `/water-cooler?topic=${currentPost.topic}`
                                )
                              }
                            >
                              {currentPost.topic}
                            </Badge>
                          </Flex>
                        </div>
                      )}

                      <div className="prose max-w-none mb-6">
                        {currentPost.content
                          .split("\n\n")
                          .map((paragraph, index) => (
                            <Text key={index} className="mb-4">
                              {paragraph}
                            </Text>
                          ))}
                      </div>
                    </>
                  )}

                  {/* Comment Input - only show if not in edit mode */}
                  {!isEditingPost && (
                    <form className="mt-6" onSubmit={handleCommentSubmit}>
                      <Flex className="gap-4">
                        <TextInput
                          placeholder="Write a comment..."
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          className="flex-1"
                        />
                        <Button type="submit" icon={Send} className="shrink-0">
                          Comment
                        </Button>
                      </Flex>
                    </form>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Comments Section - only show if not in edit mode */}
          {!isEditingPost && (
            <Card className="mt-6 relative">
              <CardContent className="p-4">
                <Title className="mb-4">
                  Comments ({currentPost.comments?.length || 0})
                </Title>
                <div className="space-y-4">
                  {currentPost.comments && currentPost.comments.length > 0 ? (
                    currentPost.comments.map((comment) => (
                      <Comment key={comment.data_id} comment={comment} />
                    ))
                  ) : (
                    <Card className="relative">
                      <CardContent className="p-4 text-center text-gray-500">
                        No comments yet. Be the first to comment!
                      </CardContent>
                    </Card>
                  )}
                </div>
              </CardContent>
            </Card>
          )}
        </Col>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl">Delete Post</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <Text>
              Are you sure you want to delete this post? This action cannot be
              undone. The post will be marked as deleted, but comments will
              remain visible.
            </Text>
          </div>
          <DialogFooter>
            <Button
              variant="light"
              onClick={() => setIsDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button color="red" onClick={handleDeletePost}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
