import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { toast } from "sonner";
import { AppContext } from "contexts/AppContext";
import { ContactsContext } from "contexts/ContactsContext";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Bold, Italic, Underline } from "lucide-react";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";

const ActionCardsEmail = forwardRef(({ outreachPayload, card }, ref) => {
  const messageContentRef = useRef("");
  const { sendEmail, oktaId } = useContext(AppContext);
  const { fetchContacts } = useContext(ContactsContext);

  const [contacts, setContacts] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const parseOutreachPayload = (payload) => {
    const message = payload.trim();
    return { message };
  };

  useEffect(() => {
    if (outreachPayload) {
      const { message } = parseOutreachPayload(card.outreach_payload);
      const formattedMessage = message.replace(/\n/g, "<br />");
      setEmailContent(formattedMessage);
    }
  }, [outreachPayload, card]);

  useEffect(() => {
    const fetchCustomerContacts = async () => {
      try {
        if (card.custcd) {
          const fetchedContacts = await fetchContacts(card.custcd);
          setContacts(fetchedContacts);
          
          // Set primary contact email if available
          if (fetchedContacts && fetchedContacts.length > 0) {
            const primaryContact = fetchedContacts[0];
            if (primaryContact?.primary_contact_info) {
              const contactInfo = JSON.parse(primaryContact.primary_contact_info);
              if (contactInfo?.contact_email) {
                setTo(contactInfo.contact_email);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchCustomerContacts();
  }, [card.custcd, fetchContacts]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.contacts-dropdown')) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToChange = (e) => {
    setTo(e.target.value);
    setShowSuggestions(true);
  };

  const handleContactSelect = (contact) => {
    try {
      if (contact?.primary_contact_info) {
        const contactInfo = JSON.parse(contact.primary_contact_info);
        if (contactInfo?.contact_email) {
          setTo(prevTo =>
            prevTo
              ? `${prevTo}, ${contactInfo.contact_email}` 
              : contactInfo.contact_email
          );
          setShowSuggestions(false);
        }
      }
    } catch (error) {
      console.error("Error selecting contact:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const parseEmails = (input) => {
    const emailString = input.replace(/;/g, ',');
    const regex = /([^,\s]+@[^,\s]+\.[^,\s]+)/g;
    return Array.from(emailString.matchAll(regex), (match) => match[1]);
  };

  const handleSubmit = async () => {
    const toEmails = parseEmails(to);
    const ccEmails = parseEmails(cc);
    const bccEmails = parseEmails(bcc);

    messageContentRef.current = document.getElementById("message").innerHTML;

    const missingFields = [];
    if (!to) missingFields.push("email address");
    if (!emailSubject) missingFields.push("subject");
    if (!emailContent) missingFields.push("email content");

    if (missingFields.length > 0) {
      setErrorMessage(`Please add ${missingFields.join(", ")}`);
      return;
    }
    setErrorMessage("");

    const formattedAttachments = attachments.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          const binaryString = e.target.result;
          const base64String = btoa(binaryString);
          resolve({
            file_content: base64String,
            file_name: file.name,
            content_type: file.type,
          });
        };
        reader.readAsBinaryString(file);
      });
    });

    const formattedAttachmentsArray = await Promise.all(formattedAttachments);

    try {
      await sendEmail(
        toEmails,
        ccEmails,
        bccEmails,
        emailSubject,
        messageContentRef.current,
        formattedAttachmentsArray,
        card.custcd
      );
      toast.success("Email Sent Successfully", {
        description: `Sent to: ${toEmails.join(', ')}${
          ccEmails.length > 0 ? `\nCC: ${ccEmails.join(', ')}` : ''
        }${
          bccEmails.length > 0 ? `\nBCC: ${bccEmails.join(', ')}` : ''
        }`,
        duration: Infinity,
        closeButton: true,
        // action: {
        //   label: 'Dismiss',
        //   onClick: () => toast.dismiss()
        // }
      });
      return true;
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email. Please make sure email address(es) are correct.");
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    getToFieldValue: () => to,
    getMessageContent: () => messageContentRef.current,
    getSubject: () => emailSubject,
    handleSubmit,
  }));

  return (
    <div className="grid gap-4">
      <div className="flex items-center gap-2 relative">
        <label htmlFor="sendTo" className="ml-9 block text-sm font-medium">
          To:
        </label>
        <div className="relative w-full contacts-dropdown">
          <Input
            id="sendTo"
            type="email"
            placeholder="Enter recipient's email"
            value={to}
            onChange={handleToChange}
            onFocus={() => setShowSuggestions(true)}
            className="mt-1 block w-full rounded-md border border-gray-300 p-2 text-sm"
          />
          {showSuggestions && Array.isArray(contacts) && contacts.length > 0 && (
            <div className="absolute z-10 w-full mt-1 rounded-md border shadow-md overflow-hidden bg-white dark:bg-gray-800">
              <div className="py-1 px-1 bg-white dark:bg-gray-800">
                {contacts.map((contact) => {
                  try {
                    if (!contact?.contact_name || !contact?.primary_contact_info) {
                      return null;
                    }
                    const contactName = JSON.parse(contact.contact_name);
                    const contactInfo = JSON.parse(contact.primary_contact_info);
                    if (!contactName || !contactInfo?.contact_email) {
                      return null;
                    }
                    
                    return (
                      <div
                        key={contact.contact_id}
                        onClick={() => handleContactSelect(contact)}
                        className="flex flex-col gap-1 px-2 py-1.5 rounded-sm cursor-pointer text-sm hover:bg-accent hover:text-accent-foreground"
                      >
                        <div className="font-medium flex items-center justify-between">
                          {`${contactName.contact_first_name} ${contactName.contact_last_name}`}
                          {contact === contacts[0] && (
                            <span className="text-xs text-muted-foreground ml-2">(Primary)</span>
                          )}
                        </div>
                        <div className="text-xs text-muted-foreground">
                          {contactInfo.contact_email}
                        </div>
                      </div>
                    );
                  } catch (error) {
                    console.error("Error rendering contact:", error);
                    return null;
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        <label htmlFor="subjectLine" className="block text-sm font-medium">
          Subject:
        </label>
        <input
          id="subjectLine"
          type="text"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
          placeholder="Enter subject"
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 text-sm"
        />
      </div>

      <div>
        <ToggleGroup size="sm" type="multiple" aria-label="Text formatting">
          <ToggleGroupItem
            value="bold"
            aria-label="Toggle bold"
            onClick={() => document.execCommand("bold")}
          >
            <Bold className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="italic"
            aria-label="Toggle italic"
            onClick={() => document.execCommand("italic")}
          >
            <Italic className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="underline"
            aria-label="Toggle underline"
            onClick={() => document.execCommand("underline")}
          >
            <Underline className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>

        <div
          id="message"
          contentEditable
          dangerouslySetInnerHTML={{
            __html: emailContent,
          }}
          onChange={(e) => setEmailContent(e.target.innerHTML)}
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 text-sm max-h-[200px] overflow-y-auto"
        />
      </div>

      <div className="flex gap-2">
        <label htmlFor="attachments" className="sm:text-right sm:mt-2 text-md">
          Attachments:
        </label>
        <div className="col-span-1 sm:col-span-3">
          <div className="flex items-center gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => document.getElementById("attachments").click()}
            >
              Choose Files
            </Button>
            <input
              id="attachments"
              type="file"
              multiple
              onChange={handleFileChange}
              className="hidden"
            />
            {attachments.length > 0 && (
              <span>{attachments.length} file(s) selected</span>
            )}
          </div>
          {attachments.length > 0 && (
            <ul className="list-disc pl-5 space-y-1 mt-2">
              {attachments.map((file, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between text-sm"
                >
                  <span className="truncate mr-2">{file.name}</span>
                  <button type="button" onClick={() => removeAttachment(index)}>
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
    </div>
  );
});

export default ActionCardsEmail;