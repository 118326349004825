"use client";

import { useState, useContext, useEffect } from "react";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Badge } from "components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import {
  ArrowLeft,
  MessageSquare,
  ArrowUpCircle,
  ArrowDownCircle,
  Pencil,
  Upload,
} from "lucide-react";
import { Link } from "react-router-dom";
import { WaterCoolerContext } from "contexts/WaterCoolerContext";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Flex } from "@tremor/react";

function EditProfileDialog({ profile, onSave, open, onOpenChange }) {
  const [editedProfile, setEditedProfile] = useState({
    name: `${profile.first_name || ""} ${profile.last_name || ""}`.trim(),
    username: profile.username || "",
    bio: profile.bio || "No bio provided.",
    avatar: "/placeholder.svg", // Default avatar
  });
  const [previewUrl, setPreviewUrl] = useState(editedProfile.avatar);

  useEffect(() => {
    // Update state when profile changes
    setEditedProfile({
      name: `${profile.first_name || ""} ${profile.last_name || ""}`.trim(),
      username: profile.username || "",
      bio: profile.bio || "No bio provided.",
      avatar: "/placeholder.svg",
    });
  }, [profile]);

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      // Here you would typically upload the file to your server
      // and get back a URL to store in the profile
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave(editedProfile);
    onOpenChange(false);
  };

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Edit Profile</DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label>Profile Picture</Label>
          <div className="flex items-center space-x-4">
            <Avatar className="h-20 w-20">
              <AvatarImage src={previewUrl} alt={editedProfile.name} />
              <AvatarFallback>{editedProfile.name[0]}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col space-y-2">
              <Label htmlFor="avatar" className="cursor-pointer">
                <div className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground hover:bg-primary/90">
                  <Upload className="mr-2 h-4 w-4" />
                  Upload New Picture
                </div>
              </Label>
              <Input
                id="avatar"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageUpload}
              />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            value={editedProfile.name}
            onChange={(e) =>
              setEditedProfile({ ...editedProfile, name: e.target.value })
            }
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="username">Username</Label>
          <div className="flex">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-input bg-muted text-muted-foreground text-sm">
              @
            </span>
            <Input
              id="username"
              className="rounded-l-none"
              value={editedProfile.username}
              onChange={(e) =>
                setEditedProfile({ ...editedProfile, username: e.target.value })
              }
              disabled={true} // Username is typically not changeable in many systems
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="bio">Bio</Label>
          <Textarea
            id="bio"
            rows={4}
            value={editedProfile.bio}
            onChange={(e) =>
              setEditedProfile({ ...editedProfile, bio: e.target.value })
            }
            placeholder="Tell us about yourself..."
          />
        </div>

        <div className="flex justify-end space-x-4">
          <Button type="submit">Save Changes</Button>
        </div>
      </form>
    </DialogContent>
  );
}

// Helper to format date
const formatDate = (isoDate) => {
  if (!isoDate) return "";

  const date = new Date(isoDate);
  const now = new Date();
  const diffMs = now - date;
  const diffSecs = Math.floor(diffMs / 1000);
  const diffMins = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    return `${diffDays}d ago`;
  } else if (diffHours > 0) {
    return `${diffHours}h ago`;
  } else if (diffMins > 0) {
    return `${diffMins}m ago`;
  } else {
    return "Just now";
  }
};

// Helper to create a formatted join date string
const formatJoinDate = (isoDate) => {
  if (!isoDate) return "Join date unknown";

  const date = new Date(isoDate);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `Joined ${months[date.getMonth()]} ${date.getFullYear()}`;
};

// Pagination component
function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <Card className="relative mt-4 md:mt-6 overflow-x-auto">
      <CardContent className="p-4">
        <Flex justifyContent="center" className="gap-2 min-w-max">
          <Button
            variant="secondary"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            size="sm"
            className="md:size-md"
          >
            <ChevronLeft className="h-4 w-4 mr-1" />
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? "primary" : "secondary"}
              onClick={() => onPageChange(page)}
              size="sm"
              className="md:size-md"
            >
              {page}
            </Button>
          ))}
          <Button
            variant="secondary"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            size="sm"
            className="md:size-md"
          >
            Next
            <ChevronRight className="h-4 w-4 ml-1" />
          </Button>
        </Flex>
      </CardContent>
    </Card>
  );
}

// Modified WCProfilePage component with filtering for is_active=false

export default function WCProfilePage() {
  const [activeTab, setActiveTab] = useState("posts");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { userData, fetchUserData } = useContext(WaterCoolerContext);
  const [postsPage, setPostsPage] = useState(1);
  const [commentsPage, setCommentsPage] = useState(1);
  const [votesPage, setVotesPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  // Get filtered data - only include items where is_active is not false
  const getFilteredData = () => {
    if (!userData) return { filteredPosts: [], filteredResponses: [], filteredVotes: [] };
    
    const filteredPosts = userData.posts.filter(post => post.is_active !== false);
    const filteredResponses = userData.responses.filter(response => response.is_active !== false);
    const filteredVotes = userData.votes ? userData.votes.filter(vote => vote.is_active !== false) : [];
    
    return { filteredPosts, filteredResponses, filteredVotes };
  };

  // Page change handlers
  const handlePostsPageChange = (newPage) => {
    setPostsPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleCommentsPageChange = (newPage) => {
    setCommentsPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleVotesPageChange = (newPage) => {
    setVotesPage(newPage);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Fetch user data when component mounts
      fetchUserData();
  }, []);

  // Reset pagination when switching tabs
  useEffect(() => {
    setPostsPage(1);
    setCommentsPage(1);
    setVotesPage(1);
  }, [activeTab]);

  const handleSaveProfile = (updatedProfile) => {
    // Parse the name into first and last name
    const [firstName, ...lastNameParts] = updatedProfile.name.split(" ");
    const lastName = lastNameParts.join(" ");

    // Here you would typically send the updated profile to your server
    console.log("Updated profile:", {
      ...updatedProfile,
      firstName,
      lastName,
    });

    // For now, we'll just show what would be updated
    alert(
      `Profile would be updated with: ${firstName} ${lastName} and bio: ${updatedProfile.bio}`
    );
  };

  // Return loading state if userData isn't loaded yet
  if (!userData) {
    return (
      <div className="container max-w-2xl mx-auto px-4 py-8 text-center">
        <p>Loading profile data...</p>
      </div>
    );
  }

  const { profile, stats } = userData;
  const { filteredPosts, filteredResponses, filteredVotes } = getFilteredData();

  // Calculate the total likes (upvotes) received across all filtered posts and responses
  const totalUpvotes =
    filteredPosts.reduce((sum, post) => sum + post.upvotes, 0) +
    filteredResponses.reduce((sum, response) => sum + response.upvotes, 0);

  // Pagination calculations
  const paginatePosts = filteredPosts.slice(
    (postsPage - 1) * ITEMS_PER_PAGE,
    postsPage * ITEMS_PER_PAGE
  );
  const paginateResponses = filteredResponses.slice(
    (commentsPage - 1) * ITEMS_PER_PAGE,
    commentsPage * ITEMS_PER_PAGE
  );
  const paginateVotes = filteredVotes.slice(
    (votesPage - 1) * ITEMS_PER_PAGE,
    votesPage * ITEMS_PER_PAGE
  );

  const totalPostsPages = Math.max(1, Math.ceil(filteredPosts.length / ITEMS_PER_PAGE));
  const totalCommentsPages = Math.max(
    1,
    Math.ceil(filteredResponses.length / ITEMS_PER_PAGE)
  );
  const totalVotesPages = Math.max(
    1,
    Math.ceil(filteredVotes.length / ITEMS_PER_PAGE)
  );

  // Updated stats for the filtered data
  const filteredStats = {
    total_posts: filteredPosts.length,
    total_responses: filteredResponses.length, 
    total_votes: filteredVotes.length
  };

  return (
    <div className="container max-w-2xl mx-auto px-4 py-8">
      <Link
        to="/water-cooler"
        className="inline-flex items-center text-sm text-muted-foreground hover:text-primary mb-6"
      >
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Water Cooler
      </Link>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex items-center space-x-4">
            <Avatar className="h-20 w-20">
              <AvatarImage
                src="/placeholder.svg"
                alt={`${profile.first_name} ${profile.last_name}`}
              />
              <AvatarFallback>
                {profile.first_name
                  ? profile.first_name[0]
                  : profile.username[0]}
              </AvatarFallback>
            </Avatar>
            <div>
              <CardTitle className="text-2xl font-bold">
                {profile.first_name && profile.last_name
                  ? `${profile.first_name} ${profile.last_name}`
                  : profile.username}
              </CardTitle>
              <p className="text-sm text-muted-foreground">
                @{profile.username}
              </p>
              <p className="text-xs text-muted-foreground">
                {profile.role &&
                  `${profile.role.charAt(0).toUpperCase()}${profile.role.slice(
                    1
                  )}`}
              </p>
            </div>
          </div>
          {/* Edit Profile button commented out as in original */}
        </CardHeader>
        <CardContent>
          <p className="text-xs text-muted-foreground mb-4">
            {formatJoinDate(profile.join_date)}
          </p>
          <div className="flex space-x-4 text-sm text-muted-foreground mb-6">
            <span>{filteredStats.total_posts} posts</span>
            <span>{filteredStats.total_responses} comments</span>
            <span>{filteredStats.total_votes} votes</span>
          </div>

          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="posts">Posts</TabsTrigger>
              <TabsTrigger value="comments">Comments</TabsTrigger>
              <TabsTrigger value="votes">Votes</TabsTrigger>
            </TabsList>
            
            <TabsContent value="posts" className="space-y-4 mt-4">
              {filteredPosts.length === 0 ? (
                <p className="text-sm text-muted-foreground text-center py-4">
                  No active posts yet.
                </p>
              ) : (
                <>
                  {paginatePosts.map((post) => (
                    <Card key={post.data_id}>
                      <CardContent className="p-4">
                        <p className="text-sm mb-2">{post.title}</p>

                        <div className="flex items-center justify-between text-sm text-muted-foreground">
                          <span>{formatDate(post.created_date)}</span>
                          <div className="flex items-center space-x-2">
                            <div className="flex items-center px-2">
                              <ArrowUpCircle className="h-4 w-4 mr-1" />
                              <span>{post.upvotes - post.downvotes}</span>
                            </div>
                            <div className="flex items-center px-2">
                              <MessageSquare className="h-4 w-4 mr-1" />
                              <span>{post.comment_count || 0}</span>
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/post/${post.data_id}`}
                          className="text-xs text-blue-500 hover:underline mt-2 inline-block"
                        >
                          View post
                        </Link>
                      </CardContent>
                    </Card>
                  ))}
                  
                  {/* Posts Pagination */}
                  {filteredPosts.length > ITEMS_PER_PAGE && (
                    <Pagination
                      currentPage={postsPage}
                      totalPages={totalPostsPages}
                      onPageChange={handlePostsPageChange}
                    />
                  )}
                </>
              )}
            </TabsContent>
            
            <TabsContent value="comments" className="space-y-4 mt-4">
              {filteredResponses.length === 0 ? (
                <p className="text-sm text-muted-foreground text-center py-4">
                  No active comments yet.
                </p>
              ) : (
                <>
                  {paginateResponses.map((response) => (
                    <Card key={response.data_id}>
                      <CardContent className="p-4">
                        <p className="text-sm mb-3">{response.content}</p>
                        <div className="flex items-center justify-between text-sm text-muted-foreground">
                          <span>{formatDate(response.created_date)}</span>
                          <div className="flex items-center space-x-2">
                            <div className="flex items-center px-2">
                              <ArrowUpCircle className="h-4 w-4 mr-1" />
                              <span>
                                {response.upvotes - response.downvotes}
                              </span>
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/post/${response.post_id}`}
                          className="text-xs text-blue-500 hover:underline mt-2 inline-block"
                        >
                          View comment
                        </Link>
                      </CardContent>
                    </Card>
                  ))}
                  
                  {/* Comments Pagination */}
                  {filteredResponses.length > ITEMS_PER_PAGE && (
                    <Pagination
                      currentPage={commentsPage}
                      totalPages={totalCommentsPages}
                      onPageChange={handleCommentsPageChange}
                    />
                  )}
                </>
              )}
            </TabsContent>
            
            <TabsContent value="votes" className="mt-4">
              <div className="space-y-4">
                {filteredVotes.length > 0 ? (
                  <>
                    {paginateVotes.map((vote) => (
                      <Card key={`vote-${vote.data_id}`}>
                        <CardContent className="p-4">
                          <p className="text-sm mb-2">
                            {vote.title ? `${vote.title}` : ""}{" "}
                            {vote.content ? `${vote.content}` : ""}
                          </p>
                          <div className="flex items-center justify-between text-sm text-muted-foreground">
                            <span>{formatDate(vote.voted_at)}</span>
                            <div className="flex items-center space-x-2">
                              <div className="flex items-center px-2">
                                {vote.vote_type === 1 ? (
                                  <ArrowUpCircle className="h-4 w-4 mr-1" />
                                ) : (
                                  <ArrowDownCircle className="h-4 w-4 mr-1" />
                                )}
                                <span>
                                  {vote.vote_type === 1
                                    ? "Upvoted"
                                    : "Downvoted"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Link
                            to={`/post/${
                              vote.post_id
                            }`}
                            className="text-xs text-blue-500 hover:underline mt-2 inline-block"
                          >
                            View{" "}
                            {vote.content_type === 2
                              ? "comment"
                              : "post"}
                          </Link>
                        </CardContent>
                      </Card>
                    ))}
                    
                    {/* Votes Pagination */}
                    {filteredVotes.length > ITEMS_PER_PAGE && (
                      <Pagination
                        currentPage={votesPage}
                        totalPages={totalVotesPages}
                        onPageChange={handleVotesPageChange}
                      />
                    )}
                  </>
                ) : (
                  <p className="text-sm text-muted-foreground text-center py-4">
                    No active votes.
                  </p>
                )}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}