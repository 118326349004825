export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiI1bXhYSTN1bEhjN2VTT3VDMS15TEM1ai1INVU1YmtJTVhNMm9SR05SeU1zIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzQzNjc5ODI3LCJleHAiOjE3NDM2ODM0MjcsImp0aSI6IklELmVtTjJqMWtkRjJ0cVE0SHdYZ0RTcm5TeTFITlZaYmk5V3g2OEdyYVY1QXciLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJGb3hhdWpiY1lEMGpzSmREWHZjZEV4cEZ0QXZCWTZBcmdOOGwwZUJuOTJ0Zmo1SGFvc3BxUWRxVUtBM3JTeG9yIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTc0MzY3OTgyNCwiYXRfaGFzaCI6IlBMUmVlajZmbHlpX3NhTXhXX1JZS1EifQ.BlSDtwbGYEDwfNM_YXTpZFwVMusCLfoeKKZcAHFITorMhZkrPpHDGrwESkIFPtBRQ91NAOIVHzXKhPW1XGNDKfGzDMmUBZiApN6mdWeptfXenLo3muQ9v22jU9eblspfbB_81cA8d7Lpha6hnzrEJUP7n-HFXbBLTkVuCIuzE-SCoPCHYGe4Ihp-kmVpSpa8uF_0AgSdi3RI3qsaQ-PxjRr-OU_f4ulZla_Xn_SFHjh4aw7iWhNFeUa63jJrb-tSQ_AteCTfseZ98tgJ3iyP_OeiY298TojEDgvrStBoBIMa-GJZOOZIptNn6MoHcU2JkPXQpeDnP2xus2G36qBomA"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
