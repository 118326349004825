import React, { useState, useContext, useRef } from "react";
import { ActionCardsContext } from "contexts/ActionCardsContext";
import { TouchpointContext } from "contexts/TouchpointContext";
import { NotesContext } from "contexts/NotesContext";
import { AppContext } from "contexts/AppContext";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";

import {
  Calendar,
  Mail,
  Phone,
  MessageCircle,
  X,
  MessageSquareQuote,
  Sparkles,
  ThumbsDown,
  ThumbsUp,
  MapPin,
  Check,
  DollarSign,
  FileText,
} from "lucide-react";

import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { ScrollArea } from "components/ui/scroll-area";
import { Textarea } from "components/ui/textarea";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";
import CalendarEvent from "../customer-template/CalendarEvent";
import ActionCardsEmail from "components/action-cards-component/action-cards-email";
import { Link } from "react-router-dom";
import FollowupCard from "components/action-cards-component/followup-card";
import { toast } from "sonner";

export const getActionItemsCount = () => {
  const { actionCards } = useContext(ActionCardsContext);
  const filteredCards = actionCards.filter(
    (card) => card.status !== "ignored" && card.status !== "logged"
  );
  return filteredCards.length;
};

const colorMapBackground = {
  "Order Inactivity": "!bg-orange-300 !text-orange-900 !border-orange-400",
  "Net New Customer": "!bg-amber-100 !text-amber-800 !border-amber-200",
  "New Customer Promo": "!bg-cyan-100 !text-cyan-800 !border-cyan-200",
  "First invoice anniversary":
    "!bg-purple-100 !text-purple-800 !border-purple-200",
  "First Invoice Anniversary":
    "!bg-purple-100 !text-purple-800 !border-purple-200",
  "Open AR Payments": "!bg-pink-100 !text-pink-800 !border-pink-200",
  "YoY Sales Drop": "!bg-red-100 !text-red-800 !border-red-200",
  "Reduced Invoice Activity":
    "!bg-orange-100 !text-orange-800 !border-orange-200",
  "Customer DRP dropped": "!bg-rose-100 !text-rose-800 !border-rose-200",
  "Customer DRP Dropped": "!bg-rose-100 !text-rose-800 !border-rose-200",
  "Eligible for new customer promo":
    "!bg-sky-100 !text-sky-800 !border-sky-200",
  "Eligible For New Customer Promo":
    "!bg-sky-100 !text-sky-800 !border-sky-200",
  "Customer DRP below expected":
    "!bg-yellow-100 !text-yellow-800 !border-yellow-200",
  "Customer DRP Below Expected":
    "!bg-yellow-100 !text-yellow-800 !border-yellow-200",
  "First SO Invoiced": "!bg-teal-100 !text-teal-800 !border-teal-200",
  "First Digital SO Invoiced":
    "!bg-indigo-100 !text-indigo-800 !border-indigo-200",
  "Limit Increased": "!bg-green-100 !text-green-800 !border-green-200",
  "Residential": "!bg-blue-100 !text-blue-800 !border-blue-200",
  "No Orders Found": "!bg-fuchsia-100 !text-fuchsia-800 !border-fuchsia-200",
  "No Repeat Purchases": "!bg-orange-300 !text-orange-900 !border-orange-400",
  "60 Days Inactivity": "!bg-red-200 !text-red-900 !border-red-300",
  "Low Sales": "!bg-amber-300 !text-amber-900 !border-amber-400",
};

const getBadgeColor = (label) => {
  return (
    colorMapBackground[label] || "!bg-gray-100 !text-gray-800 !border-gray-200"
  );
};

const pillOrder = [
  "YoY Sales Drop",
  "Reduced Invoice Activity",
  "Open AR Payments",
  "Customer DRP Dropped",
  "Customer DRP dropped",
  "Customer DRP Below Expected",
  "Customer DRP below expected",
  "Limit Increased",
  "Commercial",
  "Residential",
  "60 Days Inactivity",
  "No Orders Found",
  "No Repeat Purchases",
  "Low Sales",
  "Eligible For New Customer Promo",
  "Eligible for new customer promo",
  "First Digital SO Invoiced",
  "First SO Invoiced",
  "First Invoice Anniversary",
  "First invoice anniversary",
  "Order Inactivity",
  "Net New Customer",
  "New Customer Promo",
];

// Function to sort pills based on the defined order
const sortPills = (pills) => {
  if (!pills || !Array.isArray(pills) || pills.length === 0) return [];

  // Create a copy to avoid mutating the original array
  return [...pills].sort((a, b) => {
    // Find the index of each pill in the order array
    const indexA = pillOrder.indexOf(a);
    const indexB = pillOrder.indexOf(b);

    // If a pill isn't found in the order, give it a high index so it appears last
    const valueA = indexA === -1 ? 999 : indexA;
    const valueB = indexB === -1 ? 999 : indexB;

    // Sort by index
    return valueA - valueB;
  });
};

export default function ActionCards() {
  const { actionCards, updateActionCardStatus, submitFeedback } =
    useContext(ActionCardsContext);
  const { addTouchpoint } = useContext(TouchpointContext);
  const { createNote } = useContext(NotesContext);
  const { oktaId } = useContext(AppContext);
  const emailRef = useRef(null);

  const handleStatusUpdate = (card, status) => {
    updateActionCardStatus(card.action_id, status, card.custcd)
      .then(() => {
        console.log(
          `Action Card ${card.action_id} updated to status: ${status}`
        );
      })
      .catch((err) => {
        console.error(
          `Failed to update status for Action Card ${card.action_id}:`,
          err
        );
      });
  };

  const handleCreateNote = async (card) => {
    try {
      const note = {
        notesid: uuidv4(),
        custcd: card.custcd,
        salesagentcd: card.salesagentcd,
        header: `${card.outreach_method === "event" ? "Meeting" : "Call"
          } Talking Points - ${card.customer_name}`,
        content: card.outreach_payload,
        type: "text",
        is_priority: false,
        is_bookmark: false,
        reminder: false,
        tags: [],
        updated_date: new Date().toISOString(),
        creation_date: new Date().toISOString(),
        oktaid: oktaId,
      };

      await createNote(note);
      toast.success("Meeting talking points saved as note");
    } catch (error) {
      console.error("Error creating note:", error);
      toast.error("Failed to save talking points as note");
    }
  };

  const handleCreateTouchpoint = async (card, ref = null) => {
    console.log("handleCreateTouchpoint invoked with card:", card);
    const touchpointDate = new Date();
    const formattedDate =
      touchpointDate.toISOString().slice(0, 19) +
      "." +
      touchpointDate.getMilliseconds().toString().padStart(3, "0") +
      "000";

    const createdDate = new Date();
    const formattedCreatedDate =
      createdDate.toISOString().slice(0, 19) +
      "." +
      createdDate.getMilliseconds().toString().padStart(3, "0") +
      "000";

    const metadata = (() => {
      switch (card.outreach_method) {
        case "email":
          const formattedContent = (
            emailRef.current?.getMessageContent() || card.outreach_payload
          )
            .replace(/<br>/g, "\n") // Replace HTML br tags with newlines
            .replace(/\*\*/g, "") // Remove bold markers
            .replace(/\n{3,}/g, "\n\n") // Clean up extra newlines
            .trim(); // Remove extra whitespace at start/end

          // Get recipient email(s) and convert to array
          const recipientEmails = emailRef.current?.getToFieldValue() || "";
          // Split by comma and trim whitespace from each email
          const recipientsArray = recipientEmails
            .split(",")
            .map((email) => email.trim())
            .filter((email) => email);

          return {
            tp_type: "email",
            content: formattedContent,
            subject: emailRef.current?.getSubject(),
            ccs: null,
            bccs: null,
            num_attachments: null,
            senders: null,
            recipients: recipientsArray,
            disposition: null,
            notes: null,
            followup: null,
          };
        case "phone":
          return {
            tp_type: "call",
            content:
              document.getElementById("phoneMessage")?.value ||
              card.outreach_payload,
            participants: null,
            duration: null,
            num_participants: null,
            has_recording: null,
            disposition: null,
            notes: null,
            followup: null,
          };
        case "sms":
          return {
            tp_type: "text",
            content:
              document.getElementById("smsMessage")?.value ||
              card.outreach_payload,
            senders: null,
            recipients: null,
            disposition: null,
            notes: null,
            followup: null,
          };
        case "event":
          let eventContent = "";
          if (ref?.title) {
            eventContent += `Title: ${ref.title}\n\n`;
          }
          if (ref?.meetingDetails) {
            eventContent += ref.meetingDetails;
          }
          return {
            tp_type: "in-person",
            content: eventContent.trim() || null,
            duration: null,
            num_participants: ref?.getAttendees()?.length || null,
            participants: ref?.getAttendees() || null,
            location: null,
            disposition: null,
            notes: null,
            followup: null,
          };
        default:
          return {};
      }
    })();

    const getSummary = (type) => {
      switch (type) {
        case "email":
          return "Email Sent";
        case "call":
          return "Call Made";
        case "text":
          return "SMS Sent";
        case "in-person":
          return "Meeting Scheduled";
        default:
          return "";
      }
    };

    const touchpointData = {
      touchpointsid: card.action_id,
      custcd: card.custcd,
      custname: card.customer_name,
      tp_type: metadata.tp_type,
      summary: getSummary(metadata.tp_type),
      touchpoint_date: formattedDate,
      system_generated: true,
      visible: true,
      bookmarked: false,
      status: metadata.tp_type === "in-person" ? "scheduled" : "completed",
      impact: 0,
      response_status: false,
      created_date: formattedCreatedDate,
      metadata: metadata,
    };

    try {
      await addTouchpoint(touchpointData);
      // toast.success("Touchpoint added successfully!");
    } catch (err) {
      console.error("Error adding touchpoint:", err);
      toast.error("Failed to add touchpoint. Please try again.");
    }
  };

  const handleFeedback = async (card, rating) => {
    try {
      await submitFeedback(card.action_id, rating);
      console.log(
        `Feedback submitted for action card ${card.action_id}: ${rating}`
      );
      toast("Your feedback has been submitted.");
    } catch (error) {
      console.error(
        `Error submitting feedback for action card ${card.action_id}:`,
        error
      );
    }
  };

  const ActionCard = ({ card }) => {
    const calendarEventRef = useRef(null);
    const [localFeedback, setLocalFeedback] = useState(card.feedback);
    const [feedbackText, setFeedbackText] = useState("");
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [isDismissFeedbackOpen, setIsDismissFeedbackOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleThumbClick = async (rating) => {
      try {
        setLocalFeedback(rating);
        await submitFeedback(card.action_id, rating);
        setIsFeedbackOpen(true);
      } catch (error) {
        console.error(`Error submitting rating:`, error);
        toast.error("Failed to submit rating");
      }
    };

    const handleTextFeedback = async () => {
      try {
        await submitFeedback(card.action_id, localFeedback, feedbackText);
        setIsFeedbackOpen(false);
        setFeedbackText("");
        toast.success("Feedback submitted");
      } catch (error) {
        console.error(`Error submitting feedback:`, error);
        toast.error("Failed to submit feedback");
      }
    };

    return (
      <Card className="flex flex-col h-full hover:shadow-lg transition-shadow duration-300 cursor-pointer">
        <CardHeader>
          <div className="flex justify-between items-start">
            <div>
              <CardTitle className="text-lg font-bold text-gray-800 dark:text-white line-clamp-1">
                <Link
                  to={`/customer/${card.custcd}`}
                  className="hover:underline transition-all duration-200"
                >
                  {card.customer_name}
                </Link>
              </CardTitle>
              <div className="flex items-center gap-1 mt-3 flex-wrap">
                {sortPills(card.flags)
                  .slice(0, 3)
                  .map((flag) => (
                    <Badge
                      key={flag}
                      variant="secondary"
                      className={`${getBadgeColor(
                        flag
                      )} text-[10px] leading-none px-1.5 py-[2px] font-medium whitespace-nowrap h-[18px] flex items-center border-2 border-transparent`}
                    >
                      {flag}
                    </Badge>
                  ))}
                {card.flags.length > 3 && (
                  <Badge className="text-[10px] leading-none px-1.5 py-[2px] font-medium whitespace-nowrap h-[18px] flex items-center bg-white text-gray-800">
                    +{card.flags.length - 3}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent className="pb-0 space-y-3 flex-1 overflow-hidden">
          {card.impact?.length > 0 && (
            <div className="text-sm text-gray-600 flex items-center">
              <DollarSign className="w-4 h-4 mr-1.5 flex-shrink-0" />
              <span className="font-medium">Projected Impact:</span>
              <Badge
                variant="secondary"
                className={`ml-1 text-xs leading-none px-2 py-1 font-medium whitespace-nowrap h-[22px] flex items-center border-2 ${card.impact.reduce((sum, item) => sum + item.impact, 0) === 0
                  ? "bg-gray-100 text-gray-800 border-gray-300"
                  : "bg-emerald-50 text-emerald-700 border-emerald-400"
                  }`}
              >
                {card.impact.reduce((sum, item) => sum + item.impact, 0) === 0
                  ? "n/a"
                  : `$${Math.round(
                    card.impact.reduce((sum, item) => sum + item.impact, 0)
                  ).toLocaleString()}`}
              </Badge>
            </div>
          )}
          {card.address && (
            <div
              className="text-sm text-gray-600 flex items-center cursor-pointer hover:text-blue-600 transition-colors duration-200"
              onClick={() => {
                navigator.clipboard
                  .writeText(card.address)
                  .then(() => {
                    toast.success("Address copied to clipboard");
                  })
                  .catch((err) => {
                    console.error("Failed to copy address:", err);
                    toast.error("Failed to copy address");
                  });
              }}
              title="Click to copy address"
            >
              <MapPin className="w-4 h-4 mr-1.5 flex-shrink-0" />
              <span className="line-clamp-1">{card.address}</span>
            </div>
          )}
          {card.status === "actioned" ? (
            <div className="flex-1 flex items-center justify-center min-h-[120px]">
              <div className="flex items-center justify-center space-x-4">
                {/* Icon Container */}
                <div className="h-12 w-12 rounded-full bg-green-50 flex items-center justify-center flex-shrink-0">
                  <Check className="h-6 w-6 text-green-600" />
                </div>
                {/* Text */}
                <span className="text-l font-medium text-gray-900">
                  {card.outreach_method === "phone"
                    ? "Acknowledged"
                    : card.outreach_method === "email"
                      ? "Email Sent"
                      : card.outreach_method === "event"
                        ? "Meeting Scheduled"
                        : card.outreach_method === "sms"
                          ? "Acknowledged"
                          : card.summary}
                </span>
              </div>
            </div>
          ) : (
            <div className="bg-white dark:bg-gray-900 p-2.5 rounded-md border border-gray-200 shadow-sm">
              <div className="flex items-center mb-1">
                <Sparkles className="w-4 h-4 mr-1.5 text-blue-600" />
                <h4 className="text-base font-semibold text-gray-800 dark:text-white">
                  AI Insight
                </h4>
              </div>
              <p className="text-sm leading-relaxed text-gray-700 dark:text-white line-clamp-4 font-medium">
                <ReactMarkdown
                  children={
                    String(card.action_output.substring(0, 275)) + "..."
                  }
                />
              </p>
            </div>
          )}
        </CardContent>
        <CardFooter className="flex flex-col pt-0">
          <Dialog>
            <DialogTrigger asChild>
              <Button
                size="sm"
                className="bg-blue-600 text-white hover:bg-blue-700 w-full mt-3 flex items-center justify-center"
                onClick={() => {
                  if (card.status === "active") {
                    handleStatusUpdate(card, "reviewed");
                  }
                }}
              >
                {card.status === "actioned" ? (
                  <>
                    <MessageSquareQuote className="mr-2 h-4 w-4" />
                    Record Follow-Up
                  </>
                ) : card.outreach_method === "phone" ? (
                  <>
                    <Phone className="mr-2 h-4 w-4" />
                    Review Call Actions
                  </>
                ) : card.outreach_method === "email" ? (
                  <>
                    <Mail className="mr-2 h-4 w-4" />
                    Review Email Actions
                  </>
                ) : card.outreach_method === "event" ? (
                  <>
                    <Calendar className="mr-2 h-4 w-4" />
                    Review Meeting Actions
                  </>
                ) : card.outreach_method === "sms" ? (
                  <>
                    <MessageCircle className="mr-2 h-4 w-4" />
                    Review SMS Actions
                  </>
                ) : null}
              </Button>
            </DialogTrigger>
            <DialogContent
              className="w-full sm:max-w-[600px] max-h-[100vh] overflow-y-auto p-6"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              {card.status === "actioned" ? (
                <FollowupCard card={card} getBadgeColor={getBadgeColor} />
              ) : (
                <>
                  <DialogHeader>
                    <DialogTitle>
                      <Link
                        to={`/customer/${card.custcd}`}
                        className="hover:underline transition-all duration-200"
                      >
                        {card.customer_name}
                      </Link>
                    </DialogTitle>
                    <DialogTitle>
                      <div className="flex flex-col gap-2 mt-1">
                        {sortPills(card.flags).map((flag) => {
                          const impactItem = card.impact?.find(
                            (i) => i.flag === flag
                          );
                          return (
                            <div
                              key={flag}
                              className="flex items-center justify-between"
                            >
                              <Badge
                                variant="secondary"
                                className={`text-[10px] leading-none px-1.5 py-[2px] font-medium whitespace-nowrap h-[18px] flex items-center border-2 ${getBadgeColor(
                                  flag
                                )}`}
                              >
                                {flag}
                              </Badge>
                              {impactItem && (
                                <span className="text-sm text-gray-500">
                                  {impactItem.impact === 0
                                    ? "n/a"
                                    : `$${Math.round(
                                      impactItem.impact
                                    ).toLocaleString()}`}
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </DialogTitle>
                    <DialogDescription className="pt-2">
                      <div className="bg-white dark:bg-gray-900 p-2.5 mt-3 rounded-md border border-gray-200 shadow-sm">
                        <div className="flex items-center mb-1">
                          <Sparkles className="w-4 h-4 mr-1.5 text-blue-600" />
                          <h4 className="text-base font-semibold text-gray-800 dark:text-white">
                            AI Insight
                          </h4>
                        </div>
                        <div className="text-gray-800 dark:text-white">
                          <ReactMarkdown
                            components={{
                              ul: ({ node, ...props }) => (
                                <ul className="list-disc pl-4" {...props} />
                              ),
                              li: ({ node, ...props }) => <li {...props} />,
                              strong: ({ node, ...props }) => (
                                <strong className="font-bold" {...props} />
                              ),
                            }}
                          >
                            {String(card.action_output)}
                          </ReactMarkdown>
                        </div>
                      </div>
                    </DialogDescription>
                  </DialogHeader>
                  {card.outreach_method === "phone" && (
                    <div className="grid gap-4 pt-2">
                      <div className="p-3 rounded-md border border-gray-200">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="text-base font-semibold text-gray-800">
                            Talking Points
                          </h4>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleCreateNote(card)}
                            className="flex items-center gap-2"
                          >
                            <FileText className="w-4 h-4" />
                            Save as Note
                          </Button>
                        </div>
                        <div className="p-3 rounded text-sm text-gray-700 min-h-[200px] whitespace-pre-wrap">
                          {card.outreach_payload}
                        </div>
                      </div>
                    </div>
                  )}
                  {card.outreach_method === "sms" && (
                    <div className="grid gap-4 pt-2">
                      <Textarea
                        rows={6}
                        defaultValue={card.outreach_payload}
                        id="smsMessage"
                      />
                    </div>
                  )}
                  {card.outreach_method === "event" && (
                    <div className="grid gap-4 pt-2">
                      <div className="p-3 rounded-md border border-gray-200 max-h-40 overflow-y-auto">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="text-base font-semibold text-gray-800">
                            Talking Points
                          </h4>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleCreateNote(card)}
                            className="flex items-center gap-2"
                          >
                            <FileText className="w-4 h-4" />
                            Save as Note
                          </Button>
                        </div>

                        <div className="text-sm text-gray-700">
                          {card.outreach_payload
                            .split("- ")
                            .map((point, index) =>
                              point.trim() ? (
                                <div key={index} className="mb-1">
                                  {index > 0 && "- "}
                                  {point.trim()}
                                </div>
                              ) : null
                            )}
                        </div>
                      </div>
                      <CalendarEvent
                        ref={calendarEventRef}
                        action_id={card.action_id}
                        customerName={card.customer_name}
                        customerId={card.custcd}
                      />
                    </div>
                  )}
                  {card.outreach_method === "email" && (
                    <div className="grid gap-4 pt-2">
                      <ActionCardsEmail
                        ref={emailRef}
                        outreachPayload={card.outreach_payload}
                        card={card}
                      />
                    </div>
                  )}
                  <div className="flex items-center justify-between py-2">
                    <span>Was this a helpful action item?</span>
                    <div className="flex gap-2">
                      <Popover
                        open={isFeedbackOpen}
                        onOpenChange={setIsFeedbackOpen}
                      >
                        <PopoverTrigger asChild>
                          <div className="flex gap-2">
                            <button
                              aria-label="Thumb Up"
                              className="text-gray-500 hover:text-gray-700 active:text-gray-700 focus:outline-none"
                              onClick={() => handleThumbClick(true)}
                            >
                              <ThumbsUp
                                className="w-5 h-5 mr-1 transition-colors duration-200"
                                fill={
                                  localFeedback === true
                                    ? "currentColor"
                                    : "none"
                                }
                              />
                            </button>

                            <button
                              aria-label="Thumb Down"
                              className="text-gray-500 hover:text-gray-700 active:text-gray-700 focus:outline-none"
                              onClick={() => handleThumbClick(false)}
                            >
                              <ThumbsDown
                                className="w-5 h-5 mr-1 transition-colors duration-200"
                                fill={
                                  localFeedback === false
                                    ? "currentColor"
                                    : "none"
                                }
                              />
                            </button>
                          </div>
                        </PopoverTrigger>
                        <PopoverContent className="w-80">
                          <div className="space-y-4">
                            <div>
                              <Label className="text-sm font-medium">
                                Additional feedback?
                              </Label>
                              <Textarea
                                placeholder="Your feedback helps us improve."
                                value={feedbackText}
                                onChange={(e) =>
                                  setFeedbackText(e.target.value)
                                }
                                className="mt-2"
                              />
                            </div>
                            <div className="flex justify-end gap-2">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setIsFeedbackOpen(false);
                                  setFeedbackText("");
                                }}
                              >
                                Close
                              </Button>
                              {feedbackText.trim() && (
                                <Button size="sm" onClick={handleTextFeedback}>
                                  Submit
                                </Button>
                              )}
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>

                  <DialogFooter className="flex flex-row gap-2 w-full">
                    <Popover
                      open={isDismissFeedbackOpen}
                      onOpenChange={setIsDismissFeedbackOpen}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className="w-full text-rose-500"
                        >
                          {/* <X className="mr-2 h-4 w-4" /> */}
                          Dismiss Card
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-80">
                        <div className="space-y-4">
                          <div>
                            <Label className="text-sm font-medium">
                              Why are you dismissing this card?
                            </Label>
                            <Textarea
                              placeholder="Your feedback helps us improve."
                              value={feedbackText}
                              onChange={(e) => setFeedbackText(e.target.value)}
                              className="mt-2"
                            />
                          </div>
                          <div className="bg-amber-50 border border-amber-200 rounded p-2 text-xs text-amber-800">
                            <strong>Note:</strong> Clicking "Dismiss" will
                            remove this card from your carousel this week.
                          </div>
                          <div className="flex justify-end gap-2">
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={() => {
                                setIsDismissFeedbackOpen(false);
                                setFeedbackText("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="sm"
                              onClick={() => {
                                if (feedbackText.trim()) {
                                  // Only submit feedback if there's text entered
                                  setLocalFeedback(false);
                                  submitFeedback(
                                    card.action_id,
                                    false,
                                    feedbackText
                                  )
                                    .then(() => {
                                      handleStatusUpdate(card, "ignored");
                                      setFeedbackText("");
                                      toast.success(
                                        "Card dismissed with feedback"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Error submitting feedback:",
                                        error
                                      );
                                      toast.error("Failed to submit feedback");
                                    });
                                } else {
                                  // Just update the status if no feedback is entered
                                  handleStatusUpdate(card, "ignored");
                                  setFeedbackText("");
                                  toast.success("Card dismissed");
                                }
                              }}
                            >
                              Dismiss
                            </Button>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>

                    <Button
                      type="submit"
                      className="w-full"
                      disabled={isSubmitting}
                      onClick={() => {
                        setIsSubmitting(true);

                        if (card.outreach_method === "event") {
                          // Ensure title and date are not empty
                          const title = calendarEventRef.current?.title; // Access the title directly
                          const selectedDate =
                            calendarEventRef.current?.selectedDate; // Access the selected date directly

                          if (!title || !selectedDate) {
                            const missingFields = [];
                            if (!title) missingFields.push("title");
                            if (!selectedDate) missingFields.push("date");

                            toast.error(
                              `Please add ${missingFields.join(" and ")}.`
                            );
                            setIsSubmitting(false);
                            return; // Prevent further action if fields are missing
                          }

                          // If validation passes, submit the calendar event
                          calendarEventRef.current
                            ?.handleSubmit()
                            .then((isSuccess) => {
                              if (isSuccess) {
                                // Only update status and create touchpoint if the event is successfully created
                                handleStatusUpdate(card, "actioned");
                                handleCreateTouchpoint(
                                  card,
                                  calendarEventRef.current
                                );
                              } else {
                                toast.error("Error creating calendar event");
                              }
                              setIsSubmitting(false);
                            });
                          return;
                        }
                        if (card.outreach_method === "email") {
                          // Get values from the email component
                          const emailToField =
                            emailRef.current?.getToFieldValue();
                          const currentSubject = emailRef.current?.getSubject();

                          // Check for missing required fields
                          const missingFields = [];
                          if (!emailToField || emailToField.trim() === "")
                            missingFields.push("recipient email");
                          if (!currentSubject || currentSubject.trim() === "")
                            missingFields.push("subject");

                          // Show error toast if any required fields are missing
                          if (missingFields.length > 0) {
                            toast.error(
                              `Please add ${missingFields.join(" and ")}.`
                            );
                            setIsSubmitting(false);
                            return; // Prevent further action if fields are missing
                          }

                          // Show confirmation dialog
                          const isConfirmed = window.confirm(
                            "Are you sure you want to send this message?"
                          );

                          if (isConfirmed) {
                            // Use async/await to ensure we properly handle the promise
                            emailRef.current
                              .handleSubmit()
                              .then((isSuccess) => {
                                console.log(
                                  "Email submission result:",
                                  isSuccess
                                );

                                // Only update status and create touchpoint if email was successfully sent
                                if (isSuccess === true) {
                                  handleStatusUpdate(card, "actioned");
                                  handleCreateTouchpoint(card);
                                } else {
                                  console.log(
                                    "Email failed to send, not updating status or creating touchpoint"
                                  );
                                }
                                setIsSubmitting(false);
                              })
                              .catch((error) => {
                                console.error(
                                  "Error in email submission process:",
                                  error
                                );
                                toast.error(
                                  "Failed to complete the email sending process"
                                );
                                setIsSubmitting(false);
                              });
                          } else {
                            // User clicked Cancel on the confirmation dialog
                            toast.info("Email sending cancelled");
                            setIsSubmitting(false);
                          }
                          return; // Return early in both confirm and cancel cases
                        }
                        if (
                          card.outreach_method !== "email" &&
                          card.outreach_method !== "event"
                        ) {
                          // For other methods, we need to make the code async to properly track completion
                          Promise.all([
                            handleStatusUpdate(card, "actioned"),
                            handleCreateTouchpoint(card),
                          ])
                            .then(() => {
                              // Re-enable the button after all operations complete
                              setIsSubmitting(false);
                            })
                            .catch((error) => {
                              console.error("Error processing action:", error);
                              // Re-enable the button on error
                              setIsSubmitting(false);
                            });
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <div className="flex items-center justify-center gap-2">
                          <svg
                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Processing...
                        </div>
                      ) : card.outreach_method === "phone" ? (
                        "Mark Completed"
                      ) : card.outreach_method === "email" ? (
                        "Send Email"
                      ) : card.outreach_method === "sms" ? (
                        "Mark Completed"
                      ) : (
                        "Create Event"
                      )}
                    </Button>
                  </DialogFooter>
                </>
              )}
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>
    );
  };
  return (
    <div className="w-full mx-auto px-4 relative overflow-visible">
      <Carousel
        opts={{
          align: "start",
          loop: true,
          containScroll: "trimSnaps",
        }}
        className="w-full"
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          {actionCards.map(
            (card, index) =>
              card.status !== "ignored" &&
              card.status !== "logged" && (
                <CarouselItem
                  key={card.action_id}
                  className="pl-2 md:pl-4 basis-full md:basis-1/2 lg:basis-1/3"
                >
                  <ActionCard card={card} />
                </CarouselItem>
              )
          )}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div className="mt-4 text-center">
        <Link
          to="/customers"
          className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
        >
          View All Customers →
        </Link>
      </div>
    </div>
  );
}
