import React, { useContext, useState } from 'react';
import { PlusIcon, Trash2, FileText, Sparkles } from 'lucide-react';
import { Text } from 'components/catalyst/text';
import { Button } from 'components/ui/button';
import { Card, CardContent } from 'components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { ScrollArea, ScrollBar } from 'components/ui/scroll-area';
import ResponsiveModalNotes from 'components/notes-component/components/responsive-modal-notes';
import { NotesList } from 'components/notes-component/notes-list';
import { NoteCreate } from 'components/notes-component/note-create';
import { NotesContext } from 'contexts/NotesContext';
import { DataContext } from 'contexts/DataContext';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

export function Notes() {
  const location = useLocation();
  const { customerMemory, deleteCustomerMemory, fetchCustomerMemory } = useContext(DataContext);
  const { notes } = useContext(NotesContext);

  const customerPathMatch = location.pathname.match(/^\/customer\/(.+)/);
  const customerId = customerPathMatch ? customerPathMatch[1] : null;

  const safeNotes = Array.isArray(notes) ? notes : [];

  const filteredByAccount = safeNotes.filter(note =>
    customerId ? note.custcd === customerId : true
  );

  const filteredActive = filteredByAccount.filter(note => note.status === 'created' || note.status === 'updated');

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteMemory = async (idx) => {
    await deleteCustomerMemory(idx);
  };

  // Format date - only showing the date without time
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      return 'Invalid date';
    }
  };

  // Fetch memory when the tab changes
  const handleTabChange = (value) => {
    if (value === 'memory' && customerId) {
      fetchCustomerMemory();
    }
  };

  return (
    <div className="mt-4">
      <Card className="w-full">
        <CardContent className="p-0">
          <Tabs defaultValue="notes" onValueChange={handleTabChange} className="w-full">
            <div className="border-b">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <ScrollArea className="w-full" orientation="horizontal">
                  <div className="flex w-full p-2">
                    <TabsList className="h-auto inline-flex bg-muted rounded-full p-1 gap-1 w-full">
                      <TabsTrigger
                        value="notes"
                        className="flex-1 rounded-full px-4 py-2 text-base font-semibold text-[#020617] transition-all data-[state=active]:bg-background data-[state=active]:text-[#020617] data-[state=active]:shadow"
                      >
                        <FileText className="h-4 w-4 mr-2" />
                        Notes
                      </TabsTrigger>
                      <TabsTrigger
                        value="memory"
                        className="flex-1 rounded-full px-4 py-2 text-base font-semibold text-[#020617] transition-all data-[state=active]:bg-background data-[state=active]:text-[#020617] data-[state=active]:shadow"
                      >
                        <Sparkles className="h-4 w-4 mr-2" />
                        Learnings
                      </TabsTrigger>
                    </TabsList>
                  </div>
                  <ScrollBar orientation="horizontal" className="h-2.5 bg-muted" />
                </ScrollArea>
              </div>
            </div>
            <div className="p-2 sm:p-4">
              <TabsContent value="notes" className="m-0">
                {filteredActive.length === 0 ? (
                  <Text className="text-center py-4">There are no notes.</Text>
                ) : (
                  <NotesList items={filteredActive} scrollHeight={326} />
                )}
                <div className="mt-4">
                  <ResponsiveModalNotes
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    handleClose={handleClose}
                    trigger={
                      <Button variant="outline" className="w-full" onClick={() => setIsOpen(true)}>
                        <PlusIcon className="h-4 w-4 mr-2" />
                        Create New Note
                      </Button>
                    }
                    title="Create a New Note"
                    description={<NoteCreate customerId={customerId} handleClose={handleClose} />}
                  />
                </div>
              </TabsContent>
              <TabsContent value="memory" className="m-0">
                {customerMemory && customerMemory.length > 0 ? (
                  <div className="h-[400px] overflow-y-auto px-2 py-2">
                    <div className="border rounded-md">
                      {customerMemory.map((memory, index) => (
                        <div key={memory.idx} className="relative">
                          <div className="flex justify-between items-center p-4">
                            <div className="flex-1 mr-3">
                              <div className="text-xs text-gray-500 mb-1.5">
                                {formatDate(memory.compute_date)}
                              </div>
                              <p className="text-sm text-gray-800">{memory.content}</p>
                            </div>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="shrink-0 h-8 w-8 text-gray-500 hover:text-gray-700 self-center"
                              onClick={() => handleDeleteMemory(memory.idx)}
                              aria-label="Delete memory"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                          {index < customerMemory.length - 1 && (
                            <div className="absolute bottom-0 left-0 right-0 h-px bg-gray-200" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-[326px] text-center p-4">
                    <p className="text-muted-foreground">No memory entries for this customer yet.</p>
                  </div>
                )}
              </TabsContent>
            </div>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}