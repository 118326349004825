import { AppContext } from "contexts/AppContext";
import React, { useState, useContext, useEffect } from "react";

const SalesFunnel = ({ leads, selectedStatuses = [], onToggleStatus }) => {
  const { isMobile } = useContext(AppContext);
  
  // Define status categories
  const assignedStatuses = ["Prospective", "Churned", "Manual Add"];
  const actionedStatuses = [
    "Acknowledging Lead",
    "First Attempt",
    "Second Attempt",
    "Third Attempt",
  ];
  const qualifiedStatuses = [
    "Engaged Prospect",
    "Needs Assessment",
    "Proposal or Quote",
    "Advancing the Sale",
  ];
  const convertedStatuses = [
    "Credit Application", 
    "Converted", 
    "Reactivated Customer"
  ];

  // Grouped leads by their current status only (not cumulative)
  const pureAssignedLeads = leads.filter(lead => assignedStatuses.includes(lead.status));
  const pureActionedLeads = leads.filter(lead => actionedStatuses.includes(lead.status));
  const pureQualifiedLeads = leads.filter(lead => qualifiedStatuses.includes(lead.status));
  const pureConvertedLeads = leads.filter(lead => convertedStatuses.includes(lead.status));

  // Calculate total leads and initial percentages
  const totalLeads = leads.length;

  // For conversion rate calculations, we use the total leads that go through each funnel stage
  const totalInitialLeads = totalLeads;
  const totalActionedLeads = pureActionedLeads.length + pureQualifiedLeads.length + pureConvertedLeads.length;
  const totalQualifiedLeads = pureQualifiedLeads.length + pureConvertedLeads.length;
  const totalConvertedLeads = pureConvertedLeads.length;
  
  // Calculate funnel metrics using flow-through rates
  const actionRate = totalInitialLeads > 0 
    ? Math.round((totalActionedLeads / totalInitialLeads) * 100) 
    : 0;
    
  const qualificationRate = totalActionedLeads > 0 
    ? Math.round((totalQualifiedLeads / totalActionedLeads) * 100) 
    : 0;
    
  const closingRate = totalQualifiedLeads > 0 
    ? Math.round((totalConvertedLeads / totalQualifiedLeads) * 100) 
    : 0;

  // Tooltip content for metric descriptions
  const tooltips = {
    actioned: "Percentage of total leads that reach the actioned stage or beyond.",
    qualification:
      "Percentage of actioned leads that advance to qualified status or beyond.",
    closing: "Percentage of qualified leads that convert to customers.",
  };

  // State for metric tooltips visibility
  const [activeTooltip, setActiveTooltip] = useState(null);

  // State for showing/hiding statuses under each funnel level
  const [expandedSections, setExpandedSections] = useState({
    assigned: false,
    actioned: false,
    qualified: false,
    converted: false,
  });

  // State for managing the current screen size
  const [screenSize, setScreenSize] = useState({
    isMobileScreen: false,
    isTabletScreen: false,
  });

  // Effect to handle window resize and determine screen size
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isMobileScreen: window.innerWidth < 640,
        isTabletScreen: window.innerWidth >= 640 && window.innerWidth < 1024,
      });
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Toggle function for expanding/collapsing sections
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="mb-8">
      {/* Header section with percentages */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-gray-50 rounded-lg text-center relative">
          <div className="text-sm text-gray-500 mb-1 flex items-center justify-center">
            <span>Actioned Rate</span>
            <div className="relative inline-block ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400 cursor-help"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onMouseEnter={() => setActiveTooltip("actioned")}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {activeTooltip === "actioned" && (
                <div className="absolute z-10 w-48 p-2 text-xs text-left bg-white border border-gray-200 rounded shadow-lg -left-20 top-6">
                  {tooltips.actioned}
                </div>
              )}
            </div>
          </div>
          <div className="font-semibold text-xl mb-2">
            {actionRate}%
          </div>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg text-center relative">
          <div className="text-sm text-gray-500 mb-1 flex items-center justify-center">
            <span>Qualification Rate</span>
            <div className="relative inline-block ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400 cursor-help"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onMouseEnter={() => setActiveTooltip("qualification")}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {activeTooltip === "qualification" && (
                <div className="absolute z-10 w-48 p-2 text-xs text-left bg-white border border-gray-200 rounded shadow-lg -left-20 top-6">
                  {tooltips.qualification}
                </div>
              )}
            </div>
          </div>
          <div className="font-semibold text-xl mb-2">
            {qualificationRate}%
          </div>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg text-center relative">
          <div className="text-sm text-gray-500 mb-1 flex items-center justify-center">
            <span>Closing Rate</span>
            <div className="relative inline-block ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400 cursor-help"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onMouseEnter={() => setActiveTooltip("closing")}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {activeTooltip === "closing" && (
                <div className="absolute z-10 w-48 p-2 text-xs text-left bg-white border border-gray-200 rounded shadow-lg -left-20 top-6">
                  {tooltips.closing}
                </div>
              )}
            </div>
          </div>
          <div className="font-semibold text-xl mb-2">
            {closingRate}%
          </div>
        </div>
      </div>

      {/* Funnel Visualization */}
      <div className="relative">
        {/* Responsive Funnel Layout */}
        <div className={`justify-center flex flex-wrap ${screenSize.isMobileScreen ? 'flex-col' : screenSize.isTabletScreen ? 'grid grid-cols-2 gap-4' : 'flex-row'}`} style={{ marginBottom: 0 }}>
          {/* Assigned Stage - Arrow Shape */}
          <div className={`relative ${screenSize.isMobileScreen ? 'w-full mb-3' : screenSize.isTabletScreen ? 'w-full mb-3' : 'w-1/4'}`} 
               style={{ zIndex: 40, marginLeft: screenSize.isMobileScreen || screenSize.isTabletScreen ? 0 : 0 }}>
            <button 
              onClick={() => toggleSection("assigned")}
              className="w-full h-16 bg-gradient-to-r from-purple-600 to-purple-400 text-white relative flex items-center justify-center px-4"
              style={{ 
                clipPath: screenSize.isMobileScreen || screenSize.isTabletScreen 
                  ? 'none' 
                  : 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)',
                borderRadius: screenSize.isMobileScreen || screenSize.isTabletScreen ? '0.5rem' : 0
              }}
            >
              <div className="font-bold text-base mr-6">
                Unworked Leads 
              </div>
              <div className="absolute right-8 bg-white text-purple-600 rounded-full w-6 h-6 flex items-center justify-center font-bold text-xs">
                {pureAssignedLeads.length}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={expandedSections.assigned ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"}
                />
              </svg>
            </button>
            {expandedSections.assigned && (
              <div className={`absolute top-full left-0 ${screenSize.isMobileScreen ? 'right-0' : 'right-2'} z-50 mt-1 bg-gradient-to-b from-purple-100 to-white border border-gray-200 rounded-b p-3 shadow-sm`}>
                <div className="text-sm flex flex-col">
                  <div className="flex items-center mb-3 border-b pb-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-purple-600 rounded focus:ring-0 mr-2"
                      checked={assignedStatuses.every(status => selectedStatuses.includes(status))}
                      onChange={() => {
                        const allSelected = assignedStatuses.every(status => selectedStatuses.includes(status));
                        // Toggle each status individually
                        assignedStatuses.forEach(status => {
                          const shouldBeSelected = !allSelected;
                          const isCurrentlySelected = selectedStatuses.includes(status);
                          
                          // Only toggle if the current state doesn't match the desired state
                          if (shouldBeSelected !== isCurrentlySelected) {
                            onToggleStatus(status);
                          }
                        });
                      }}
                    />
                    <span className="font-bold">Select All</span>
                  </div>
                  {assignedStatuses.map(status => (
                    <div key={status} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-purple-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes(status)}
                        onChange={() => onToggleStatus(status)}
                      />
                      <span className="font-medium">{status} ({leads.filter(lead => lead.status === status).length})</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Actioned Stage - Arrow Shape */}
          <div className={`relative ${screenSize.isMobileScreen ? 'w-full mb-3' : screenSize.isTabletScreen ? 'w-full mb-3' : 'w-1/4'}`} 
               style={{ 
                 zIndex: 30, 
                 marginLeft: screenSize.isMobileScreen || screenSize.isTabletScreen ? 0 : '-5px'
               }}>
            <button 
              onClick={() => toggleSection("actioned")}
              className="w-full h-16 bg-gradient-to-r from-orange-600 to-orange-400 text-white relative flex items-center justify-center px-4"
              style={{ 
                clipPath: screenSize.isMobileScreen || screenSize.isTabletScreen 
                  ? 'none' 
                  : 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%)',
                borderRadius: screenSize.isMobileScreen || screenSize.isTabletScreen ? '0.5rem' : 0
              }}
            >
              <div className="font-bold text-base mr-6">
                Actioned Leads 
              </div>
              <div className="absolute right-8 bg-white text-orange-600 rounded-full w-6 h-6 flex items-center justify-center font-bold text-xs">
                {pureActionedLeads.length}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={expandedSections.actioned ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"}
                />
              </svg>
            </button>
            {expandedSections.actioned && (
              <div className="absolute top-full left-0 right-2 z-50 mt-1 bg-gradient-to-b from-orange-100 to-white border border-gray-200 rounded-b p-3 shadow-sm">
                <div className="text-sm flex flex-col">
                  <div className="flex items-center mb-3 border-b pb-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-orange-600 rounded focus:ring-0 mr-2"
                      checked={actionedStatuses.every(status => selectedStatuses.includes(status))}
                      onChange={() => {
                        const allSelected = actionedStatuses.every(status => selectedStatuses.includes(status));
                        // Toggle each status individually
                        actionedStatuses.forEach(status => {
                          const shouldBeSelected = !allSelected;
                          const isCurrentlySelected = selectedStatuses.includes(status);
                          
                          // Only toggle if the current state doesn't match the desired state
                          if (shouldBeSelected !== isCurrentlySelected) {
                            onToggleStatus(status);
                          }
                        });
                      }}
                    />
                    <span className="font-bold">Select All</span>
                  </div>
                  {actionedStatuses.map(status => (
                    <div key={status} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-orange-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes(status)}
                        onChange={() => onToggleStatus(status)}
                      />
                      <span className="font-medium">{status} ({leads.filter(lead => lead.status === status).length})</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Qualified Stage - Arrow Shape */}
          <div className={`relative ${screenSize.isMobileScreen ? 'w-full mb-3' : screenSize.isTabletScreen ? 'w-full mb-3' : 'w-1/4'}`} 
               style={{ 
                 zIndex: 20, 
                 marginLeft: screenSize.isMobileScreen || screenSize.isTabletScreen ? 0 : '-5px'
               }}>
            <button 
              onClick={() => toggleSection("qualified")}
              className="w-full h-16 bg-gradient-to-r from-blue-600 to-blue-400 text-white relative flex items-center justify-center px-4"
              style={{ 
                clipPath: screenSize.isMobileScreen || screenSize.isTabletScreen 
                  ? 'none' 
                  : 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%)',
                borderRadius: screenSize.isMobileScreen || screenSize.isTabletScreen ? '0.5rem' : 0
              }}
            >
              <div className="font-bold text-base mr-6">
                Qualified Leads 
              </div>
              <div className="absolute right-8 bg-white text-blue-600 rounded-full w-6 h-6 flex items-center justify-center font-bold text-xs">
                {pureQualifiedLeads.length}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={expandedSections.qualified ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"}
                />
              </svg>
            </button>
            {expandedSections.qualified && (
              <div className="absolute top-full left-0 right-2 z-50 mt-1 bg-gradient-to-b from-blue-100 to-white border border-gray-200 rounded-b p-3 shadow-sm">
                <div className="text-sm flex flex-col">
                  <div className="flex items-center mb-3 border-b pb-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 rounded focus:ring-0 mr-2"
                      checked={qualifiedStatuses.every(status => selectedStatuses.includes(status))}
                      onChange={() => {
                        const allSelected = qualifiedStatuses.every(status => selectedStatuses.includes(status));
                        // Toggle each status individually
                        qualifiedStatuses.forEach(status => {
                          const shouldBeSelected = !allSelected;
                          const isCurrentlySelected = selectedStatuses.includes(status);
                          
                          // Only toggle if the current state doesn't match the desired state
                          if (shouldBeSelected !== isCurrentlySelected) {
                            onToggleStatus(status);
                          }
                        });
                      }}
                    />
                    <span className="font-bold">Select All</span>
                  </div>
                  {qualifiedStatuses.map(status => (
                    <div key={status} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes(status)}
                        onChange={() => onToggleStatus(status)}
                      />
                      <span className="font-medium">{status} ({leads.filter(lead => lead.status === status).length})</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Converted Stage - Arrow Shape */}
          <div className={`relative ${screenSize.isMobileScreen ? 'w-full' : screenSize.isTabletScreen ? 'w-full' : 'w-1/4'}`} 
               style={{ 
                 zIndex: 10, 
                 marginLeft: screenSize.isMobileScreen || screenSize.isTabletScreen ? 0 : '-5px'
               }}>
            <button 
              onClick={() => toggleSection("converted")}
              className="w-full h-16 bg-gradient-to-r from-green-600 to-green-400 text-white relative flex items-center justify-center px-4"
              style={{ 
                clipPath: screenSize.isMobileScreen || screenSize.isTabletScreen 
                  ? 'none' 
                  : 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 50%)',
                borderRadius: screenSize.isMobileScreen || screenSize.isTabletScreen ? '0.5rem' : 0
              }}
            >
              <div className="font-bold text-base mr-6">
                Converted Leads 
              </div>
              <div className="absolute right-8 bg-white text-green-600 rounded-full w-6 h-6 flex items-center justify-center font-bold text-xs">
                {pureConvertedLeads.length}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 absolute right-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={expandedSections.converted ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"}
                />
              </svg>
            </button>
            {expandedSections.converted && (
              <div className="absolute top-full left-0 right-0 z-50 mt-1 bg-gradient-to-b from-green-100 to-white border border-gray-200 rounded-b p-3 shadow-sm">
                <div className="text-sm flex flex-col">
                  <div className="flex items-center mb-3 border-b pb-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-green-600 rounded focus:ring-0 mr-2"
                      checked={convertedStatuses.every(status => selectedStatuses.includes(status))}
                      onChange={() => {
                        const allSelected = convertedStatuses.every(status => selectedStatuses.includes(status));
                        // Toggle each status individually
                        convertedStatuses.forEach(status => {
                          const shouldBeSelected = !allSelected;
                          const isCurrentlySelected = selectedStatuses.includes(status);
                          
                          // Only toggle if the current state doesn't match the desired state
                          if (shouldBeSelected !== isCurrentlySelected) {
                            onToggleStatus(status);
                          }
                        });
                      }}
                    />
                    <span className="font-bold">Select All</span>
                  </div>
                  {convertedStatuses.map(status => (
                    <div key={status} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-green-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes(status)}
                        onChange={() => onToggleStatus(status)}
                      />
                      <span className="font-medium">{status} ({leads.filter(lead => lead.status === status).length})</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesFunnel;